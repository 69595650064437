import { DEFAULT_BOX_SIZE_OPTIONS } from '@/app/editor/blocks/constants';

import * as Slider from '@radix-ui/react-slider';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';

import { BoxSizeIcon } from './Icon';

import type { BoxSizeOption } from '@/app/editor/blocks/types';
import type { WrappedFieldInputProps } from 'redux-form';
import type { Direction } from 'types/generic';

export interface Props {
    input: WrappedFieldInputProps;
    icon: Direction;
    options?: BoxSizeOption[];
    submit?: () => void;
}

const iconMap = {
    top: () => <BoxSizeIcon />,
    right: () => <BoxSizeIcon className="rotate-90" />,
    bottom: () => <BoxSizeIcon className="rotate-180" />,
    left: () => <BoxSizeIcon className="-rotate-90" />,
};

const BoxSize = ({ icon, input, options = DEFAULT_BOX_SIZE_OPTIONS, submit }: Props) => {
    const Icon = iconMap[icon];

    const handleChange = (value: number[]) => {
        const { onChange } = input;

        onChange(options[value[0]]);

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <FieldContainer>
            <div className="flex h-12 items-center justify-center rounded-lg bg-gray-100 px-4 text-gray-400 transition hover:text-gray-500">
                <Icon />
                <Slider.Root
                    max={options.length - 1}
                    className="relative ml-4 flex h-5 w-full items-center"
                    onValueCommit={handleChange}
                    defaultValue={[options.indexOf(input.value)]}
                >
                    <Slider.Track className="relative h-0.5 grow rounded-sm bg-gray-300">
                        <Slider.Range className="absolute h-full rounded-full bg-gray-300" />
                    </Slider.Track>

                    <Slider.Thumb
                        className="block size-3 cursor-pointer rounded-full bg-gray-400 hover:bg-gray-600 focus:outline-none focus:ring-0"
                        aria-label="Box size"
                    />
                </Slider.Root>
            </div>
        </FieldContainer>
    );
};

export default BoxSize;
