export const VisaIcon = () => {
    return (
        <svg
            width="32"
            height="20"
            viewBox="0 0 32 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.0952 5.72443C18.1738 5.72443 16.4567 6.69571 16.4567 8.49023C16.4567 10.5482 19.502 10.6903 19.502 11.7242C19.502 12.1595 18.9905 12.5492 18.1169 12.5492C16.8769 12.5492 15.9502 12.0047 15.9502 12.0047L15.5537 13.8156C15.5537 13.8156 16.6213 14.2755 18.0386 14.2755C20.1393 14.2755 21.7923 13.2566 21.7923 11.4313C21.7923 9.25675 18.7344 9.11883 18.7344 8.15923C18.7344 7.81819 19.1543 7.44455 20.0254 7.44455C21.0084 7.44455 21.8103 7.84055 21.8103 7.84055L22.1984 6.09155C22.1984 6.09155 21.3258 5.72443 20.0952 5.72443ZM2.51601 5.85643L2.46948 6.12043C2.46948 6.12043 3.27783 6.26471 4.00588 6.55255C4.94332 6.88255 5.01006 7.07471 5.16797 7.67143L6.88834 14.1394H9.19452L12.7474 5.85643H10.4465L8.16358 11.4881L7.23201 6.71443C7.14656 6.16811 6.71382 5.85643 6.18414 5.85643H2.51601ZM13.6726 5.85643L11.8676 14.1394H14.0617L15.8604 5.85643H13.6726ZM25.9098 5.85643C25.3808 5.85643 25.1004 6.13271 24.8947 6.61543L21.6803 14.1394H23.9811L24.4263 12.8854H27.2295L27.5002 14.1394H29.5304L27.7592 5.85643H25.9098ZM26.2091 8.09423L26.8911 11.2024H25.0639L26.2091 8.09423Z"
                fill="#1434CB"
            />
        </svg>
    );
};
