import {
    AcademicCapIcon,
    BookOpenIcon,
    ChatBubbleLeftRightIcon,
    Cog6ToothIcon,
    GlobeAltIcon,
    PlayCircleIcon,
    UsersIcon,
} from '@heroicons/react/24/outline';

import { CommandMenuSection } from '@/app/cmdk/types';

import type { GroupCommand } from '@/app/cmdk/types';

export const NAME = 'cmdk';

export const COMMAND_LINKS = {
    'account-settings': '/settings',
    domains: '/domains',
    funnel: '/funnel/:id:',
    'new-funnel': '/new',
    'help-center': 'https://intercom.help/perspective-funnels/en/',
    community: 'https://www.facebook.com/groups/725098161241011',
    tutorials: 'https://www.youtube.com/@perspective.funnels',
    'path-to-success': '/achievements',
};

export const GENERAL_COMMANDS: GroupCommand[] = [
    {
        id: '[account-settings]',
        translationKey: 'cmdk-account-settings',
        icon: Cog6ToothIcon,
        isInternal: true,
    },
    {
        id: '[domains]',
        translationKey: 'cmdk-domains',
        icon: GlobeAltIcon,
        isInternal: true,
        isAdmin: true,
    },
];

export const HELP_COMMANDS: GroupCommand[] = [
    {
        id: '[help-center]',
        translationKey: 'cmdk-help-center',
        icon: BookOpenIcon,
        isExternal: true,
        isAdmin: true,
    },
    {
        id: '[community]',
        translationKey: 'cmdk-community',
        icon: UsersIcon,
        isExternal: true,
        isAdmin: true,
    },
    {
        id: '[tutorials]',
        translationKey: 'cmdk-tutorials',
        icon: PlayCircleIcon,
        isExternal: true,
        isAdmin: true,
    },
    {
        id: '[path-to-success]',
        translationKey: 'cmdk-path-to-success',
        icon: AcademicCapIcon,
        isInternal: true,
        isAdmin: true,
    },
    {
        id: '[support]',
        translationKey: 'cmdk-support',
        icon: ChatBubbleLeftRightIcon,
        isAdmin: true,
    },
];

export const COMMAND_MENU_DEFAULT_SECTIONS = [
    CommandMenuSection.recentFunnels,
    CommandMenuSection.general,
    CommandMenuSection.help,
];

export const COMMAND_MENU_WORKSPACE_SECTIONS = [
    CommandMenuSection.workspaceFunnels,
    CommandMenuSection.general,
    CommandMenuSection.help,
];
