import { NAME } from '@/app/crm/constants';

import { createSlice } from '@reduxjs/toolkit';
import difference from 'lodash/difference';

import { EMPTY_ARRAY } from '@/utils/empty';

import { fetchContacts } from './contacts';
import { FixedColumnId } from '../types';

import type { Pagination, Selection, Sorting, SortDirection } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface State {
    activeCellSettingsDropdown: string | null;
    sorting: Sorting;
    paginatedContactIds: string[];
    pagination: Pagination;
    selection: Selection;
    tableColumnOrder: string[];
    draggedColumn: string | null;
    dragOverColumn: string | null;
    isCellWrapEnabled: boolean;
}

export const initialState: State = {
    activeCellSettingsDropdown: null,
    sorting: {
        isInitialSorting: true,
        sortField: FixedColumnId.converted_at,
        sortOrder: -1,
    },
    paginatedContactIds: EMPTY_ARRAY,
    pagination: {
        count: 0,
        page: 0,
        limit: 25,
        next: '',
    },
    selection: {
        isAllSelected: false,
        selectedIds: EMPTY_ARRAY,
    },
    tableColumnOrder: EMPTY_ARRAY,
    draggedColumn: null,
    dragOverColumn: null,
    isCellWrapEnabled: false,
};

const tableSlice = createSlice({
    name: `${NAME}/table`,
    initialState,
    reducers: {
        setActiveCellSettingsDropdown(state, action: PayloadAction<string | null>) {
            state.activeCellSettingsDropdown = action.payload;
        },
        setSorting(state, action: PayloadAction<Sorting>) {
            state.sorting = {
                isInitialSorting: false,
                ...action.payload,
            };
        },
        addPaginatedContactIds(state, action: PayloadAction<string[]>) {
            const contactIdsWithoutDuplicates = difference(
                action.payload,
                state.paginatedContactIds,
            );

            state.paginatedContactIds = [
                ...state.paginatedContactIds,
                ...contactIdsWithoutDuplicates,
            ];
        },
        removePaginatedContactIds(
            state,
            action: PayloadAction<{ selectedIds: string[]; shouldKeepInstead?: boolean }>,
        ) {
            const paginatedContacts = state.paginatedContactIds;

            state.paginatedContactIds = action.payload.shouldKeepInstead
                ? paginatedContacts.filter((contactId) =>
                      action.payload.selectedIds.includes(contactId),
                  )
                : difference(paginatedContacts, action.payload.selectedIds);
        },
        setPaginatedContactIds(state, action: PayloadAction<string[]>) {
            state.paginatedContactIds = action.payload;
        },
        setPagination(state, action: PayloadAction<Pagination>) {
            state.pagination = {
                ...state.pagination,
                ...action.payload,
            };
        },
        setSelection(state, action: PayloadAction<Selection>) {
            state.selection = action.payload;
        },
        setTableColumnOrder(state, action: PayloadAction<string[]>) {
            state.tableColumnOrder = action.payload;
        },
        setDraggedColumn(state, action: PayloadAction<string | null>) {
            state.draggedColumn = action.payload;
        },
        setDragOverColumn(state, action: PayloadAction<string | null>) {
            state.dragOverColumn = action.payload;
        },
        setIsCellWrapEnabled(state, action: PayloadAction<boolean>) {
            state.isCellWrapEnabled = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const {
    setActiveCellSettingsDropdown,
    setSorting,
    addPaginatedContactIds,
    removePaginatedContactIds,
    setPaginatedContactIds,
    setPagination,
    setSelection,
    setTableColumnOrder,
    setDraggedColumn,
    setDragOverColumn,
    setIsCellWrapEnabled,
    reset,
} = tableSlice.actions;

// === Selectors ======
export const getActiveCellSettingsDropdown = (state: AppState) =>
    state[NAME].tableReducer.activeCellSettingsDropdown;
export const getSorting = (state: AppState) => state[NAME].tableReducer.sorting;
export const getPagination = (state: AppState) => state[NAME].tableReducer.pagination;
export const getPaginatedContactIds = (state: AppState) =>
    state[NAME].tableReducer.paginatedContactIds;
export const getSelection = (state: AppState) => state[NAME].tableReducer.selection;
export const getTableColumnOrder = (state: AppState) => state[NAME].tableReducer.tableColumnOrder;
export const getDraggedColumn = (state: AppState) => state[NAME].tableReducer.draggedColumn;
export const getIsCellWrapEnabled = (state: AppState) => state[NAME].tableReducer.isCellWrapEnabled;

// === Helpers ======

export const setTablePagination =
    (contactIds: string[], pagination: Pagination, shouldAdd = false): AppThunk =>
    (dispatch) => {
        if (shouldAdd) {
            dispatch(addPaginatedContactIds(contactIds));
        } else {
            dispatch(setPaginatedContactIds(contactIds));
        }

        dispatch(setPagination(pagination));
    };

// === Thunks ======
export const handleUpdateTableSorting =
    (campaignId: string, fieldName: string, sortDirection: SortDirection): AppThunk =>
    async (dispatch) => {
        dispatch(
            setSorting({
                sortField: fieldName,
                sortOrder: sortDirection,
            }),
        );
        dispatch(setActiveCellSettingsDropdown(null));
        dispatch(fetchContacts(campaignId));
    };

export const handleDeleteTableContact =
    (contactId: string): AppThunk =>
    async (dispatch, getState) => {
        const pagination = getPagination(getState());

        dispatch(removePaginatedContactIds({ selectedIds: [contactId] }));

        dispatch(
            setPagination({
                ...pagination,
                count: pagination.count - 1,
            }),
        );
    };

export default tableSlice.reducer;
