import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';

import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import TextInput from './TextInput';
import { NAME } from '../../../constants';

import type { CustomInputBlockOptions } from '../../../types';

export interface Props {
    showIcon: boolean;
    placeholder: string;
    icon: string;
    blockId: string;
    customOptions: CustomInputBlockOptions;
    isActiveBlock: boolean;
}

const Dropdown = ({
    icon,
    showIcon,
    placeholder,
    blockId,
    customOptions,
    isActiveBlock,
}: Props) => {
    const { t } = useTranslation(NAME);
    const dropdownBorderRadiusClass = useBorderRadius('dropdown');
    const dropdownItemBorderRadiusClass = useBorderRadius('dropdownItem');
    const buttonBorderRadiusClass = useBorderRadius('button');
    const isTwoColumn = customOptions?.twoColumn ?? false;
    const language = customOptions?.language;

    const clearBtnLabel = t('clear', { lng: language });
    const confirmBtnLabel = t('save', { lng: language });

    const dropdownWrapperStyle = cn(
        'ease pointer-events-none absolute left-0 right-0 top-full z-[1000] h-0 origin-top transform transition-all duration-[200ms]',
        {
            'scale-y-0 opacity-0': !isActiveBlock,
            'scale-y-100 opacity-100': isActiveBlock,
        },
    );

    const dropdownStyle = cn(
        dropdownBorderRadiusClass,
        'pointer-events-auto ml-auto mt-2 flex grid h-fit w-[329px] max-w-full cursor-default gap-2 border bg-white p-2 shadow-md',
        {
            'grid-cols-2': isTwoColumn,
            'grid-cols-1': !isTwoColumn,
        },
    );

    const chevronStyle = cn(
        'size-6 shrink-0 transform-gpu cursor-pointer text-gray-500 transition-transform ease-out',
        {
            'rotate-0': !isActiveBlock,
            'rotate-180': isActiveBlock,
        },
    );

    const controlsWrapperStyle = cn('col-start-1 mt-2 grid grid-cols-2 gap-2', {
        'col-span-2': isTwoColumn,
    });

    return (
        <div className="relative">
            <div className="relative">
                <TextInput
                    icon={icon}
                    showIcon={showIcon}
                    placeholder={placeholder}
                    blockId={blockId}
                    isActiveBlock={isActiveBlock}
                    inputClassName="pr-14"
                />
                <div className="absolute inset-y-0 right-0 flex size-14 items-center justify-center">
                    <ChevronDownIcon className={chevronStyle} />
                </div>
            </div>
            <div className={dropdownWrapperStyle}>
                <div className={dropdownStyle}>
                    {customOptions?.choices?.map((choice, index) => {
                        if (choice.trim().length === 0) {
                            return;
                        }

                        return (
                            <div
                                className={cn(
                                    'w-full border px-3 py-[7px] text-center text-sm font-medium leading-6 text-gray-500',
                                    dropdownItemBorderRadiusClass,
                                )}
                                key={`${choice}-${index}`}
                            >
                                {choice}
                            </div>
                        );
                    })}
                    {customOptions?.multiSelect && (
                        <div className={controlsWrapperStyle}>
                            <div
                                className={cn(
                                    'flex h-10 w-full items-center justify-center bg-gray-100 px-3 py-2 text-center text-sm font-medium text-gray-500',
                                    buttonBorderRadiusClass,
                                )}
                            >
                                {clearBtnLabel}
                            </div>
                            <div
                                className={cn(
                                    'flex h-10 w-full items-center justify-center bg-blue-500 px-3 py-2 text-center text-sm font-medium text-white',
                                    buttonBorderRadiusClass,
                                )}
                            >
                                {confirmBtnLabel}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
