import { NAME } from '@/app/crm/constants';

import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

interface Props {
    value: string;
}

export const BoolInput = ({ value }: Props) => {
    const { t } = useTranslation(NAME);

    const selectedOption = value
        ? { value: t('yes'), icon: HandThumbUpIcon }
        : { value: t('no'), icon: HandThumbDownIcon };

    const Icon = selectedOption.icon;

    return (
        <div className="relative ml-2 flex h-9 items-center">
            <div className="flex select-none items-center rounded-md border bg-gray-50 px-1.5 py-0.5 text-sm text-gray-700 transition-colors">
                <Icon className="size-4" />
                <span className="pl-2">{selectedOption.value}</span>
            </div>
        </div>
    );
};
