import { NAME } from '@/app/crm/constants';

import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_ARRAY } from '@/utils/empty';

import { getCampaignCRMFiltersOpenedFromLS } from '../helpers/localStorage';
import { isValidCRMFilter } from '../utils/filters';

import type { CRMFilter } from '../types';
import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    isFiltersBarDisplayed: boolean | null;
    crmFilters: CRMFilter[];
    previewCRMFilters: CRMFilter[];
    lastSavedFilterIdAdded: string | null;
    isLoading: boolean;
}

const initialState: State = {
    isFiltersBarDisplayed: null,
    lastSavedFilterIdAdded: null,
    crmFilters: EMPTY_ARRAY,
    previewCRMFilters: EMPTY_ARRAY,
    isLoading: false,
};

const filtersSlice = createSlice({
    name: `${NAME}/filters`,
    initialState,
    reducers: {
        setLastSavedFilterIdAdded(state, actions: PayloadAction<string | null>) {
            return {
                ...state,
                lastSavedFilterIdAdded: actions.payload,
            };
        },
        setFiltersBarDisplayed(state, action: PayloadAction<boolean>) {
            state.isFiltersBarDisplayed = action.payload;
        },
        setAllCRMFilters(state, action: PayloadAction<CRMFilter[]>) {
            const validCRMFilters = action.payload.filter(isValidCRMFilter);

            state.crmFilters = validCRMFilters;
            state.previewCRMFilters = validCRMFilters;
        },
        resetAllCRMFilters(state) {
            state.previewCRMFilters = [];
            state.crmFilters = [];
        },
        applyCRMFilters(state) {
            const validCRMFilters = state.previewCRMFilters.filter(isValidCRMFilter);

            state.previewCRMFilters = validCRMFilters;
            state.crmFilters = validCRMFilters;
        },
        addPreviewCRMFilter(state, action: PayloadAction<CRMFilter>) {
            state.previewCRMFilters = [...state.previewCRMFilters, action.payload];
        },
        updatePreviewCRMFilter(state, action: PayloadAction<CRMFilter>) {
            const newFilters = state.previewCRMFilters.map((filter) => {
                return filter.fieldName === action.payload.fieldName ? action.payload : filter;
            });
            state.previewCRMFilters = newFilters;
        },
        removePreviewCRMFilter(state, action: PayloadAction<string>) {
            state.previewCRMFilters = state.previewCRMFilters.filter(
                (filter) => filter.fieldName !== action.payload,
            );
        },
        toggleFiltersBar(state) {
            state.isFiltersBarDisplayed = !state.isFiltersBarDisplayed;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const {
    setLastSavedFilterIdAdded,
    setFiltersBarDisplayed,
    toggleFiltersBar,
    setAllCRMFilters,
    addPreviewCRMFilter,
    updatePreviewCRMFilter,
    removePreviewCRMFilter,
    resetAllCRMFilters,
    applyCRMFilters,
    setIsLoading,
    reset,
} = filtersSlice.actions;

// === Selectors ======
export const getLastSavedFilterIdAdded = (state: AppState) =>
    state[NAME].filtersReducer.lastSavedFilterIdAdded;
export const getIsFiltersBarDisplayed = (campaignId: string) => (state: AppState) => {
    const storeValue = state[NAME].filtersReducer.isFiltersBarDisplayed;

    // Note that we want the default value to be the store value
    return storeValue === null ? getCampaignCRMFiltersOpenedFromLS(campaignId) : storeValue;
};
export const getCRMFilters = (state: AppState) => state[NAME].filtersReducer.crmFilters;
export const getPreviewCRMFilters = (state: AppState) =>
    state[NAME].filtersReducer.previewCRMFilters;
export const getIsLoading = (state: AppState) => state[NAME].filtersReducer.isLoading;

export default filtersSlice.reducer;
