import { NAME } from '@/app/crm/constants';

import { TrashIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { MouseEvent } from 'react';

const DOWNLOAD_LOCK_DURATION = 20000; // We lock download for 20 seconds

interface Props {
    onDownload: (event: MouseEvent) => void;
    onDelete: (event: MouseEvent) => void;
    recentlyUploaded: boolean;
}

export const FileInfoDefaultActions = ({ recentlyUploaded, onDownload, onDelete }: Props) => {
    const { t } = useTranslation(NAME);
    const [lockDownload, setLockDownload] = useState(false);
    const isLockInProgress = useRef(false);

    // After uploading a file it needs some time to be processed by Firestack. We lock the download
    // button for 20 seconds so the user does not get an error when trying to download the file during
    // processing stage
    useEffect(() => {
        if (recentlyUploaded && !isLockInProgress.current) {
            isLockInProgress.current = true;
            setLockDownload(true);

            setTimeout(() => {
                setLockDownload(false);
            }, DOWNLOAD_LOCK_DURATION);
        }
    }, [recentlyUploaded]);

    return (
        <>
            <Tooltip content={t('file-processing-lock-message')} disabled={!lockDownload}>
                <button
                    className={cn(
                        'flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-500 transition-colors hover:bg-gray-200',
                        {
                            'cursor-not-allowed text-gray-400 hover:bg-transparent': lockDownload,
                        },
                    )}
                    onClick={lockDownload ? undefined : onDownload}
                >
                    <ArrowDownTrayIcon className="size-4" />
                </button>
            </Tooltip>

            <button
                className="flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-500 transition-colors hover:bg-gray-200"
                onClick={onDelete}
            >
                <TrashIcon className="size-4" />
            </button>
        </>
    );
};
