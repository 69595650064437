import { SESSION_ID } from '@/core/constants';

import { captureException, captureMessage, withScope } from '@sentry/nextjs';

import { infoLog } from '@/utils/debugLogs';
import { isProductionEnv } from '@/utils/environments';

import type { SeverityLevel } from '@sentry/nextjs';

export const reportError = ({
    error,
    source,
    debug,
}: {
    error: Error | string;
    source?: string;
    debug?: Record<string, unknown>;
}) => {
    if (isProductionEnv()) {
        withScope((scope) => {
            scope.setTag('sessionId', SESSION_ID);

            if (source) {
                scope.setTag('source', source);
            }

            if (debug) {
                scope.setExtra('debug', debug);
            }

            captureException(error);
        });
    } else {
        infoLog(`Report to Sentry (Source: ${source}):`, error);

        if (debug) {
            infoLog('Error reported with debug object:', debug);
        }
    }
};

export const reportMessage = ({
    message,
    source,
    severityLevel,
    debug,
}: {
    message: string;
    source?: string;
    severityLevel?: SeverityLevel;
    debug?: Record<string, unknown>;
}) => {
    if (isProductionEnv()) {
        withScope((scope) => {
            scope.setTag('sessionId', SESSION_ID);

            if (source) {
                scope.setTag('source', source);
            }

            if (debug) {
                scope.setExtra('debug', debug);
            }

            captureMessage(message, severityLevel ?? 'warning');
        });
    } else {
        infoLog(
            `Report to Sentry as message (Source: ${source}):`,
            message + debug ? ` Debug: ${JSON.stringify(debug)}` : '',
        );
    }
};
