import { FIXED_FOLDER_IDS } from '@/app/mediaLibrary/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useCallback } from 'react';
import { change } from 'redux-form';

import { getCampaignWorkspaceId } from '@/app/campaigns/helpers';
import { useCampaign } from '@/app/campaigns/hooks/queries/useCampaign';
import { CropButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import { useUploadMedia } from '@/app/mediaLibrary/hooks/mutations/useUploadMedia';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { FileUploadButton } from './FileUploadButton';
import { ImageLibraryButton } from './ImageLibraryButton';

import '@lemoncode/react-image-focal-point/style.css';

import type { MappedMedia } from '@/app/mediaLibrary/types';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    blockId: string;
    input: WrappedFieldInputProps;
    submit: () => void;
}

export const BgImageSrc = ({ blockId, input, submit }: Props) => {
    const { value, onChange } = input;
    const dispatch = useAppDispatch();

    const campaignId = getCampaignIdFromRouter();
    const { data: campaign } = useCampaign(campaignId);
    const workspaceId = getCampaignWorkspaceId(campaign);

    const { mutate: uploadMedia, isPending } = useUploadMedia();

    const handleFileUpload = useCallback(
        (file: File) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.upload, {});

            uploadMedia({
                folderId: FIXED_FOLDER_IDS.all,
                files: [file],
                workspaceId,
                type: 'image',
                successCb: (uploadedImages) => {
                    const src = uploadedImages[0].attributes.url;

                    // Add imgix params
                    const imgixSrc = `${src}?auto=format,compress&w=720&dpr=2`;

                    onChange(imgixSrc);
                    setTimeout(submit);
                },
            });
        },
        [onChange, submit, uploadMedia, workspaceId],
    );

    const handleCropSave = useCallback(
        (src: string) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.crop, {});

            dispatch(change(blockId, 'attributes.content.box.bgImage.src', src));
            setTimeout(submit);
        },
        [dispatch, blockId, submit],
    );

    const handleImageSelect = useCallback(
        (media: MappedMedia) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.select, {});

            // Check for existing imgix params and add if necessary
            const url = media.url;
            const hasImgixParams = url?.includes('?');
            const imgixSrc = hasImgixParams ? url : `${url}?auto=format,compress&w=720&dpr=2`;

            onChange(imgixSrc);
            setTimeout(submit);
        },
        [onChange, submit],
    );

    return (
        <div className="mt-2 grid grid-cols-3 gap-2">
            <FileUploadButton onChange={handleFileUpload} loading={isPending} />
            <ImageLibraryButton currentSrc={value} onChange={handleImageSelect} />
            <CropButton src={value} onSave={handleCropSave} size="custom" />
        </div>
    );
};
