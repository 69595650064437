import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { getActiveIndexBySliderId, setActiveIndex } from '@/app/editor/blocks/models/slider';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import { compressSlideImage } from './compressSlideImage';

import type { MouseEvent } from 'react';

interface Props {
    blockId: string;
    imageUrl: string;
    number: number;
    id: string;
    onDelete?: (indexToDelete: number) => void;
    containImage?: boolean;
}

export const SortableImage = ({ blockId, imageUrl, number, id, onDelete, containImage }: Props) => {
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();

    const { attributes, listeners, setNodeRef, transition, transform, isDragging } = useSortable({
        id,
    });
    const imageIndex = parseInt(id.split('___')[0]);
    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const isActive = activeImageIndex === imageIndex;

    const src = compressSlideImage(imageUrl);
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const cardClasses = cn(
        'group/slide relative overflow-hidden rounded-md bg-gray-100 text-[0] aspect-4/3',
        {
            'border border-dashed': isDragging,
            'outline outline-2 outline-blue-500': isActive && !isDragging,
            'p-2': containImage,
        },
    );

    const handleClick = () => {
        dispatch(setActiveIndex({ sliderId: blockId, index: imageIndex }));
    };

    const handleDelete = (event: MouseEvent) => {
        event.stopPropagation();

        onDelete(imageIndex);
    };

    return (
        <div ref={setNodeRef} style={style} className={cardClasses} {...attributes} {...listeners}>
            {onDelete && (
                <Tooltip content={t('remove')} placement="left">
                    <button
                        onClick={handleDelete}
                        className="absolute right-2 top-2 z-20 flex size-6 scale-50 items-center justify-center rounded-full bg-gray-800/80 opacity-0 drop-shadow transition-all group-hover/slide:scale-100 group-hover/slide:opacity-100"
                    >
                        <XMarkIcon className="size-4 text-white" />
                    </button>
                </Tooltip>
            )}
            {number && (
                <p
                    className="absolute bottom-2 left-3 z-10 text-lg font-semibold text-white drop-shadow"
                    onClick={handleClick}
                >
                    {number}
                </p>
            )}
            {!isDragging && (
                <div className="relative size-full">
                    <Image
                        src={src}
                        alt="Image"
                        fill
                        className={containImage ? 'object-contain' : 'object-cover'}
                        onClick={handleClick}
                    />
                </div>
            )}
        </div>
    );
};

export default SortableImage;
