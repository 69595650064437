import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import qs from 'query-string';

import type { SyntheticEvent } from 'react';

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeQueryParamsFromUrl = (asPath: string, paramsToRemove: string | string[]) => {
    let slug = asPath.split('?')[0];
    const query = asPath.split('?')[1];

    if (query) {
        const updatedQuery = omit(qs.parse(query), paramsToRemove);
        slug += isEmpty(updatedQuery) ? '' : `?${qs.stringify(updatedQuery)}`;
    }

    return slug;
};

export const stopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
};

export const selectText = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.currentTarget.select();
};

export const getNow = () => new Date();

export const getElementIsScrolled = (element: HTMLDivElement) => {
    return !!element?.scrollTop && element.scrollTop > 0;
};
