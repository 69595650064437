import { BlockComponentType } from '@/app/editor/blocks/types';

import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDropExecutionConfiguration,
} from '@/app/editor/engine/core/types';
import type { NodeSequenceRowConfiguration } from '@/app/editor/engine/types';

export const NAME = 'engine';

/**
 * Any of these blocks will only be able to target blocks of their own type.
 */
export const COMPONENT_TYPES_DRAGGABLE_TO_SAME_TYPE = [
    BlockComponentType.LIST_ITEM,
    BlockComponentType.QUESTION_MEDIA_ANSWER,
    BlockComponentType.QUESTION_TEXT_ANSWER,
    BlockComponentType.ACCORDION_ITEM,
    BlockComponentType.INPUT,
] as BlockComponentType[];

/**
 * These blocks will only accept blocks dropped to their left or right.
 */
export const COMPONENT_TYPES_THAT_ONLY_ACCEPT_HORIZONTAL_DROPS = [
    BlockComponentType.QUESTION_MEDIA_ANSWER,
    BlockComponentType.GRID_COLUMN,
] as BlockComponentType[];

/**
 * These blocks will only accept blocks dropped above or below them.
 */
export const COMPONENT_TYPES_THAT_ONLY_ACCEPT_VERTICAL_DROPS = [
    BlockComponentType.GRID_ROW,
    BlockComponentType.ACCORDION_ITEM,
    BlockComponentType.INPUT,
] as BlockComponentType[];

/**
 * These blocks will accept vertical drops when in a mobile orientation and
 * horizontal drops when in a desktop orientation.
 *
 * Note that when inside a column, the orientation is always vertical if the
 * layout has more than one column.
 */
export const COMPONENT_TYPES_THAT_ACCEPT_ORIENTATION_BASED_DROPS = [
    BlockComponentType.LIST_ITEM,
    BlockComponentType.QUESTION_TEXT_ANSWER,
] as BlockComponentType[];

/**
 * Dragging any block of these types besides a top level block of these types
 * will create a new layout with two columns, one for the dragged block and one
 * for the existing block.
 */
export const BASIC_COMPONENT_TYPES = [
    BlockComponentType.ACCORDION,
    BlockComponentType.BUTTON,
    BlockComponentType.DIVIDER,
    BlockComponentType.EMBED,
    BlockComponentType.FORM,
    BlockComponentType.ICON,
    BlockComponentType.ILLUSTRATION,
    BlockComponentType.LIST,
    BlockComponentType.LOGOS,
    BlockComponentType.MEDIA,
    BlockComponentType.PAYMENT_FORM,
    BlockComponentType.QUESTION_FORM,
    BlockComponentType.QUESTION_MEDIA,
    BlockComponentType.QUESTION_MULTIPLE_CHOICE,
    BlockComponentType.QUESTION_TEXT,
    BlockComponentType.REVIEWS,
    BlockComponentType.SLIDER_IMAGE,
    BlockComponentType.SLIDER_TESTIMONIAL,
    BlockComponentType.TEXT,
    BlockComponentType.WEBINAR,
] as BlockComponentType[];

/**
 * Components which appear in rows can be configured here.
 * A wide layout is possible if the view is desktop and the component is not in
 * a column (unless it's a layout with one single column).
 */
export const SEQUENCE_COMPONENT_TYPES_CONFIGURATION = {
    [BlockComponentType.LIST_ITEM]: {
        wideLayoutRowLength: 2,
        narrowLayoutRowLength: 1,
    },
    [BlockComponentType.QUESTION_TEXT_ANSWER]: {
        wideLayoutRowLength: 2,
        narrowLayoutRowLength: 1,
    },
    [BlockComponentType.QUESTION_MEDIA_ANSWER]: {
        wideLayoutRowLength: 4,
        narrowLayoutRowLength: 2,
    },
} satisfies Partial<Record<BlockComponentType, NodeSequenceRowConfiguration>>;

/**
 * A drop handler which will do nothing.
 */
export const DRAG_AND_DROP_NO_OP_HANDLER = {
    handler: () => {},
} satisfies EditorEngineDropExecutionConfiguration<EditorEngineDefaultTypeInput>;

/**
 * The name of the column template which can be used to create an empty column
 * in the editor.
 */
export const COLUMN_TEMPLATE_NAME = '1b-column';

/**
 * When editing, undoing, or redoing, the editor might try to scroll the page so
 * that a block will be in view. After each of these operations, the editor will
 * wait for this amount of milliseconds before scrolling the block into view.
 */
export const SCROLL_COMPONENT_INTO_VIEW_TIMEOUT = 200;

/**
 * Tracking IDs are contained in these fields, depending on the block type.
 */
export const TRACKING_ID_FIELD_NAMES = ['fieldName', 'fixedFieldName', 'clickId'] as const;
