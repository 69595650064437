import { cn } from '@/utils/cn';

import ButtonLoadingSpinner from '../ButtonLoadingSpinner';

import type { HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLButtonElement> {
    variant?: 'default' | 'danger' | 'primary' | 'secondary' | 'clear';
    size?: 'default' | 'small' | 'thin';
    loading?: boolean;
    disabled?: boolean;
    type?: 'submit' | 'button';
}

export const Button = ({
    variant = 'default',
    size = 'default',
    className = '',
    children,
    loading,
    disabled,
    type,
    ...props
}: Props) => {
    const classes = cn(
        'bump flex w-full items-center justify-center rounded-lg font-medium transition-all',
        {
            'md:w-max': !className.includes('md:w-full'),
            'px-6 py-3': size === 'default',
            'px-4 py-2.5 text-sm': size === 'small',
            'px-4 py-1.5 text-sm': size === 'thin',
            'bg-red-600 text-white hover:bg-red-700': variant === 'danger',
            'hover:bg-red-700': variant === 'danger' && !disabled,
            'bg-blue-500 text-white hover:bg-blue-600': variant === 'primary' && !disabled,
            'bg-gray-100 text-gray-500 hover:bg-gray-200': variant === 'secondary',
            'border bg-white text-gray-700 hover:bg-gray-100': variant === 'default',
            'hover:bg-gray-100': variant === 'default' && !disabled,
            'bg-transparent text-blue-500 hover:bg-gray-100 hover:text-blue-600':
                variant === 'clear',
            'cursor-not-allowed bg-blue-100 text-blue-500': disabled,
        },
        className,
    );
    const buttonLoaderClasses = cn('transition-all ease-out', {
        'mr-2 w-6 opacity-100': loading,
        '!w-0 opacity-0': !loading,
    });

    return (
        <button {...props} className={classes} type={type} disabled={disabled}>
            {!!loading && <ButtonLoadingSpinner className={buttonLoaderClasses} />}
            {children}
        </button>
    );
};
