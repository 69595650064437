import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';

import { RequestState } from '@/app/workspaces/types';
import { apiGet, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_STRING } from '@/utils/empty';

import { VISIBLE_CUSTOMERS_STEP, NAME, CUSTOMER_TABLE_DEFAULT_ORDER } from '../constants';

import type { CustomerTableOrder } from '../types';
import type { PartnershipAttributes, CustomerAttributes } from '../types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    partnership: PartnershipAttributes | undefined;
    partnerKey: string;
    sharingLink: string;
    finishedFetching: boolean;
    customers: CustomerAttributes[];
    search: string;
    customerTableOrder: CustomerTableOrder;
    visibleCustomersCount: number;
    requestState: Partial<Record<'partnership' | 'customers', RequestState>>;
}

const initialState: State = {
    partnership: undefined,
    partnerKey: EMPTY_STRING,
    sharingLink: EMPTY_STRING,
    finishedFetching: false,
    customers: EMPTY_ARRAY,
    search: EMPTY_STRING,
    customerTableOrder: CUSTOMER_TABLE_DEFAULT_ORDER,
    visibleCustomersCount: VISIBLE_CUSTOMERS_STEP,
    requestState: {
        partnership: RequestState.Idle,
        customers: RequestState.Idle,
    },
};

export const partnershipsSlice = createSlice({
    name: `${NAME}/partnership`,
    initialState,
    reducers: {
        setPartnership(state, action: PayloadAction<PartnershipAttributes>) {
            return {
                ...state,
                partnership: action.payload,
            };
        },
        setPartnerKey(state, action: PayloadAction<string>) {
            return {
                ...state,
                partnerKey: action.payload,
            };
        },
        setSharingLink(state, action: PayloadAction<string>) {
            return {
                ...state,
                sharingLink: action.payload,
            };
        },
        setCustomers(state, action: PayloadAction<CustomerAttributes[]>) {
            return {
                ...state,
                customers: action.payload,
            };
        },
        setSearch(state, action: PayloadAction<string>) {
            return {
                ...state,
                search: action.payload,
            };
        },
        setCustomerTableOrder(state, action: PayloadAction<CustomerTableOrder>) {
            return {
                ...state,
                customerTableOrder: action.payload,
            };
        },
        setVisibleCustomersCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                visibleCustomersCount: action.payload,
            };
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setPartnerKey,
    setPartnership,
    setSharingLink,
    setCustomers,
    setSearch,
    setCustomerTableOrder,
    setRequestState,
    setVisibleCustomersCount,
    reset,
} = partnershipsSlice.actions;

// === Selectors ======

export const getPartnership = (state: AppState): PartnershipAttributes | undefined =>
    state[NAME]?.partnershipReducer?.partnership;

export const getPartnerKey = (state: AppState): string =>
    state[NAME]?.partnershipReducer?.partnerKey;

export const getSharingLink = (state: AppState): string =>
    state[NAME]?.partnershipReducer?.sharingLink;

export const getAllCustomers = (state: AppState): CustomerAttributes[] =>
    state[NAME]?.partnershipReducer?.customers;

export const getSearch = (state: AppState): string => state[NAME]?.partnershipReducer?.search;

export const getCustomerTableOrder = (state: AppState): CustomerTableOrder =>
    state[NAME]?.partnershipReducer?.customerTableOrder;

export const getVisibleCustomersCount = (state: AppState): number =>
    state[NAME]?.partnershipReducer?.visibleCustomersCount;

export const getFetchingPartnership = (state: AppState) =>
    state[NAME]?.partnershipReducer?.requestState.partnership === RequestState.InProgress;

export const getFinishedFetchingPartnership = (state: AppState) =>
    state[NAME]?.partnershipReducer?.requestState.partnership === RequestState.Done ||
    state[NAME]?.partnershipReducer?.requestState.partnership === RequestState.Error;

export const getFetchingCustomers = (state: AppState) =>
    state[NAME]?.partnershipReducer?.requestState.customers === RequestState.InProgress;

export const getFinishedFetchingCustomers = (state: AppState) =>
    state[NAME]?.partnershipReducer?.requestState.customers === RequestState.Done ||
    state[NAME]?.partnershipReducer?.requestState.customers === RequestState.Error;

// === Thunks ======

const fetchPartnership =
    (): AppThunk<
        Promise<{ partnership: PartnershipAttributes; sharingLink: string } | undefined>
    > =>
    async () => {
        try {
            const partnershipRes =
                await apiGet<
                    ResponseData<{ partnership: PartnershipAttributes; sharingLink: string }>
                >('/referral/partnership');

            return getDataFromResponse(partnershipRes);
        } catch (err) {
            // Do not show error
        }
    };

const createPartner =
    (options?: {
        silent?: boolean;
    }): AppThunk<
        Promise<{ partnership: PartnershipAttributes; sharingLink: string } | undefined>
    > =>
    async () => {
        const isDeCustomer = Router.locale === 'de';

        try {
            // Create anonymous partner
            const anonymousPartnerRes = await apiPost<
                ResponseData<{ partnership: PartnershipAttributes; sharingLink: string }>
            >('/referral/partnership', {
                data: {
                    groupKey: isDeCustomer
                        ? process.env.NEXT_PUBLIC_PARTNERSTACK_REFERRAL_GROUP_DE
                        : process.env.NEXT_PUBLIC_PARTNERSTACK_REFERRAL_GROUP_EN,
                },
            });

            return getDataFromResponse(anonymousPartnerRes);
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'creating partnership failed:',
                silent: !!options?.silent,
            });
        }
    };

export const initPartnership =
    (options?: { silent?: boolean }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const fetchedPartnerKey = getPartnerKey(state);

        if (!fetchedPartnerKey) {
            dispatch(setRequestState({ partnership: RequestState.InProgress }));

            let partnershipData:
                | { partnership: PartnershipAttributes; sharingLink: string }
                | undefined = undefined;

            try {
                partnershipData = await dispatch(fetchPartnership());
            } catch (err) {
                // Do nothing on error
            }

            if (!partnershipData || isEmpty(partnershipData?.partnership)) {
                try {
                    partnershipData = await dispatch(createPartner(options));

                    if (isEmpty(partnershipData)) {
                        throw new Error('No Partnership data found');
                    }
                } catch (err) {
                    dispatch(setRequestState({ partnership: RequestState.Error }));

                    handleRuntimeError(err, {
                        debugMessage: err?.message,
                        silent: true,
                    });

                    return;
                }
            }

            const { partnership, sharingLink } = partnershipData || {};
            const { partner_key } = partnership || {};

            dispatch(setPartnership(partnership));
            dispatch(setPartnerKey(partner_key));
            dispatch(setSharingLink(sharingLink));
            dispatch(setRequestState({ partnership: RequestState.Done }));
        }
    };

const loadCustomers = async (): Promise<CustomerAttributes[]> => {
    const customersRes = await apiGet<ResponseData<CustomerAttributes[]>>('/referral/customers', {
        params: {
            // minCreated: process.env.NEXT_PUBLIC_PARTNERSTACK_REFERRAL_PROGRAM_START_DATE,
        },
    });

    return getDataFromResponse(customersRes);
};

export const initCustomers = (): AppThunk => async (dispatch) => {
    dispatch(setRequestState({ customers: RequestState.InProgress }));

    try {
        const customers = await loadCustomers();

        dispatch(setCustomers(customers));
        dispatch(setRequestState({ customers: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ customers: RequestState.Error }));
        handleRuntimeError(err, {
            debugMessage: 'fetching customers failed:',
            silent: err?.response?.status === 404,
        });
    }
};

export default partnershipsSlice.reducer;
