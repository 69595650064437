import type { Language } from 'types/generic';

export const NAME = 'user';

export const en: Language = 'en';
export const de: Language = 'de';

export const ENDPOINTS = {
    DELETE: {
        deleteUser: (userId: string) => `/users/${userId}`,
    },
};

export const MUTATION_KEYS = {
    deleteUser: () => ['user', 'delete'],
};
