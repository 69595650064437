import { FEATURE_IDS } from '@/app/billing/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

import { getCampaignById } from '@/app/campaigns/models/campaigns';
import { getCompany, getFeatureAvailability } from '@/app/company/models/company';
import { getTranslatedFieldName } from '@/app/editor/blocks/helpers';
import { inputTypeOptions } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/InputType/inputTypeOptions';
import { getUser } from '@/app/user/models/user';
import { apiDelete, apiGet, apiPatch, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { loadCookie } from '@/utils/cookies';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@/utils/empty';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { NAME } from '../constants';

import type { TrackingProperty } from '@/app/editor/blocks/types';
import type { TrackingPropertyResource } from '@/app/editor/blocks/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Language } from 'types/generic';

interface State {
    properties: { [campaignId: string]: TrackingProperty[] };
    fetching: boolean;
    updating: boolean;
    creating: boolean;
    deleting: string;
}

const initialState: State = {
    properties: EMPTY_OBJECT,
    fetching: false,
    updating: false,
    creating: false,
    deleting: EMPTY_STRING,
};

export const personalizationSlice = createSlice({
    name: `editor/${NAME}/personalization`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetching: action.payload,
            };
        },
        setUpdating(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                updating: action.payload,
            };
        },
        setCreating(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                creating: action.payload,
            };
        },
        setDeleting(state, action: PayloadAction<string>) {
            return {
                ...state,
                deleting: action.payload,
            };
        },
        setProperties(state, action: PayloadAction<{ [campaignId: string]: TrackingProperty[] }>) {
            return {
                ...state,
                properties: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setFetching, setDeleting, setUpdating, setProperties, setCreating, reset } =
    personalizationSlice.actions;

// === Selectors ======

export const getTrackingProperties = (state: AppState) =>
    state[NAME]?.personalizationReducer?.properties;

export const getTrackingPropertiesByCampaignId = (
    state: AppState,
    campaignId?: string,
): TrackingProperty[] =>
    (campaignId && state[NAME]?.personalizationReducer?.properties[campaignId]) || EMPTY_ARRAY;

export const getTrackingPropertyByFieldName = (
    state: AppState,
    fieldName: string,
    campaignId: string,
) => {
    const properties = getTrackingPropertiesByCampaignId(state, campaignId);

    return find(properties, { fieldName });
};

// Prevent circular dependency
const getActiveCampaign = (state: AppState) => {
    const campaignId = getCampaignIdFromRouter();

    return campaignId && getCampaignById(state, campaignId);
};

export const getActiveCampaignTrackingProperties = createSelector(
    [getActiveCampaign, getTrackingProperties],
    (campaign, trackingProperties) => {
        if (!campaign) {
            return EMPTY_ARRAY;
        }

        const campaignId = campaign.id;

        return trackingProperties[campaignId] || EMPTY_ARRAY;
    },
);

export const getTrackingPropertiesFetching = (state: AppState) =>
    state[NAME]?.personalizationReducer?.fetching;

export const getTrackingPropertiesUpdating = (state: AppState) =>
    state[NAME]?.personalizationReducer?.updating;

export const getTrackingPropertiesCreating = (state: AppState) =>
    state[NAME]?.personalizationReducer?.creating;

export const getTrackingPropertiesDeleting = (state: AppState) =>
    state[NAME]?.personalizationReducer?.deleting;

// === Thunks ======

export const fetchTrackingProperties = (campaignId: string): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();

        const user = getUser(state);
        const language =
            (loadCookie('NEXT_LOCALE') as Language) || user?.attributes?.language || 'en';

        const hasPersonalizationFeature = getFeatureAvailability(FEATURE_IDS.personalization)(
            state,
        );

        if (!hasPersonalizationFeature) {
            return;
        }

        const fieldTranslations = inputTypeOptions[language];

        dispatch(setFetching(true));

        try {
            const response = await apiGet<ResponseData>(
                `/tracking-properties?campaign=${campaignId}`,
            );

            const existingProperties = getTrackingProperties(state);
            const properties = getDataFromResponse(response, EMPTY_ARRAY).map(
                (property: TrackingPropertyResource) => {
                    const propertyData: TrackingProperty = {
                        id: property.id,
                        name: getTranslatedFieldName(
                            fieldTranslations,
                            property.attributes.fieldName,
                        ),
                        title: property.attributes.title,
                        source: property.attributes.source,
                        fieldName: property.attributes.fieldName,
                        defaultValue: property.attributes.defaultValue,
                    };

                    if (property.attributes.source !== 'custom') {
                        propertyData.componentId = get(
                            property,
                            'relationships.components.data[0].id',
                            '',
                        );
                    }

                    return propertyData;
                },
            );

            const updatedProperties = {
                ...existingProperties,
                [campaignId]: properties,
            };

            dispatch(setProperties(updatedProperties));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching tracking properties failed:' });
        } finally {
            dispatch(setFetching(false));
        }
    };
};

export const updateTrackingProperty = (
    property: TrackingProperty,
    campaignId: string,
): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();

        const user = getUser(state);
        const language =
            (loadCookie('NEXT_LOCALE') as Language) || user?.attributes?.language || 'en';

        const fieldTranslations = inputTypeOptions[language];

        dispatch(setUpdating(true));

        try {
            const response = await apiPatch<ResponseData>(`/tracking-properties/${property.id}`, {
                data: {
                    attributes: {
                        source: property.source,
                        defaultValue: property.defaultValue,
                        fieldName: property.fieldName,
                        title: property.title,
                    },
                },
            });

            const data = getDataFromResponse(response);
            const updatedProperty: TrackingProperty = {
                id: data.id,
                name: getTranslatedFieldName(fieldTranslations, data.attributes.fieldName),
                title: data.attributes.title,
                fieldName: data.attributes.fieldName,
                source: data.attributes.source,
                defaultValue: data.attributes.defaultValue,
            };

            if (data.attributes.source !== 'custom') {
                updatedProperty.componentId = get(data, 'relationships.components.data[0].id', '');
            }

            const existingProperties = getTrackingProperties(state);
            const newProperties = { ...existingProperties };
            const newPropertyArray = [...newProperties[campaignId]];
            const index = findIndex(newPropertyArray, { id: updatedProperty.id });

            newPropertyArray[index] = updatedProperty;

            newProperties[campaignId] = newPropertyArray;

            dispatch(setProperties(newProperties));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating tracking property failed:' });
        } finally {
            dispatch(setUpdating(false));
        }
    };
};

export const addCustomProperty = (
    newProperty: Pick<TrackingProperty, 'fieldName' | 'defaultValue'>,
    campaignId: string,
    callback: (property: TrackingProperty) => void,
): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(setCreating(true));
        const company = getCompany(state);

        try {
            const response = await apiPost<ResponseData>('/tracking-properties', {
                data: {
                    type: 'trackingProperty',
                    attributes: {
                        source: 'custom',
                        defaultValue: newProperty.defaultValue,
                        fieldName: newProperty.fieldName,
                    },
                    relationships: {
                        company: {
                            data: {
                                type: 'company',
                                id: company.id,
                            },
                        },
                        campaign: {
                            data: {
                                id: campaignId,
                                type: 'campaign',
                            },
                        },
                    },
                },
            });

            const data = getDataFromResponse(response);
            const createdProperty: TrackingProperty = {
                id: data.id,
                name: data.attributes.title, // name is only used for mention plugin
                title: data.attributes.title,
                fieldName: data.attributes.fieldName,
                source: data.attributes.source,
                defaultValue: data.attributes.defaultValue,
            };

            const existingProperties = getTrackingProperties(state);
            const newProperties = { ...existingProperties };

            newProperties[campaignId] = [...newProperties[campaignId], createdProperty];

            dispatch(setProperties(newProperties));

            // trigger callback
            callback(createdProperty);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'creating tracking property failed:' });
        } finally {
            dispatch(setCreating(false));
        }
    };
};

export const deleteCustomProperty = (propertyId: string, campaignId: string): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(setDeleting(propertyId));

        try {
            await apiDelete(`/tracking-properties/${propertyId}`);

            const existingProperties = getTrackingProperties(state);
            const newProperties = { ...existingProperties };

            const index = findIndex(newProperties[campaignId], { id: propertyId });

            if (index > -1) {
                const newArray = [...newProperties[campaignId]];
                newArray.splice(index, 1);

                newProperties[campaignId] = newArray;
            }

            dispatch(setProperties(newProperties));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'deleting tracking property failed:' });
        } finally {
            dispatch(setDeleting(''));
        }
    };
};

export default personalizationSlice.reducer;
