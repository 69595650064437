import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';

import { useAuthGuard } from '@/app/auth/hooks/useAuthGuard';
import { isDecember } from '@/utils/dates';
import { getIsWorkspaceEnv } from '@/utils/environments';

import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
}

const GenericWorkspaceLogo = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1016_19202)">
            <path
                d="M4.07722 4.07722C5.87778 2.27666 8.13906 1.32078 12.0768 0.83378C14.9786 0.474905 18.7909 0 24 0C29.3878 0 33.2812 0.482178 36.2185 0.87161C39.9749 1.36963 42.1677 2.32217 43.9228 4.07722C45.6831 5.83749 46.636 8.0381 47.1328 11.815C47.5187 14.7484 48 18.6327 48 24C48 29.3973 47.5174 33.2949 47.1263 36.234C46.6278 39.981 45.6754 42.1701 43.9228 43.9228C42.1854 45.6602 40.019 46.6111 36.3313 47.1132C33.3811 47.5149 29.4571 48 24 48C18.7221 48 14.8782 47.5223 11.9624 47.1519C8.0951 46.6605 5.86026 45.7058 4.07722 43.9228C2.34132 42.1869 1.39051 40.0227 0.888073 36.3409C0.485312 33.3896 0 29.463 0 24C0 18.772 0.475662 14.951 0.837696 12.0453C1.32589 8.12698 2.28148 5.87296 4.07722 4.07722Z"
                fill="url(#paint0_linear_1016_19202)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1514 14.7499C23.62 14.2813 24.3798 14.2813 24.8485 14.7499L33.2485 23.1499C33.5917 23.4931 33.6943 24.0092 33.5086 24.4577C33.3229 24.9061 32.8853 25.1984 32.3999 25.1984H31.1999V32.3984C31.1999 33.0612 30.6627 33.5984 29.9999 33.5984H27.5999C26.9372 33.5984 26.3999 33.0612 26.3999 32.3984V28.7984C26.3999 28.1357 25.8627 27.5984 25.1999 27.5984H22.7999C22.1372 27.5984 21.5999 28.1357 21.5999 28.7984V32.3984C21.5999 33.0612 21.0627 33.5984 20.3999 33.5984H17.9999C17.3372 33.5984 16.7999 33.0612 16.7999 32.3984V25.1984H15.5999C15.1146 25.1984 14.677 24.9061 14.4913 24.4577C14.3055 24.0092 14.4082 23.4931 14.7514 23.1499L23.1514 14.7499Z"
                fill="white"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_1016_19202"
                x1="24"
                y1="0"
                x2="24"
                y2="48"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#212E4B" />
                <stop offset="1" stopColor="#111827" />
            </linearGradient>
            <clipPath id="clip0_1016_19202">
                <rect width="48" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const Opener = ({ children }: Props) => {
    const isAuthenticated = useAuthGuard();

    const isWorkspaceEnv = getIsWorkspaceEnv({ genericHost: true });
    const perspectiveLogo = isDecember() ? '/logo_xmas.png' : '/logo_128x128.png';

    return (
        <AnimatePresence mode="popLayout">
            {isAuthenticated ? (
                <motion.div
                    key="authenticated"
                    className="h-[inherit]"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ type: 'spring', bounce: 0 }}
                >
                    {children}
                </motion.div>
            ) : (
                <motion.div
                    key="unauthenticated"
                    className="fixed inset-0 flex h-dvh w-dvw items-center justify-center"
                    aria-hidden
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'spring', bounce: 0 }}
                >
                    <motion.div
                        className="animate-pulse text-[0]"
                        exit={{ scale: 2 }}
                        transition={{ type: 'spring', bounce: 0 }}
                    >
                        {isWorkspaceEnv ? (
                            <GenericWorkspaceLogo />
                        ) : (
                            <Image
                                src={perspectiveLogo}
                                alt="perspective logo"
                                width={48}
                                height={48}
                                priority
                            />
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
