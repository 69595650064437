import { useEffect, useCallback } from 'react';

import { useHideModal } from '@/app/modals/hooks/useHideModal';
import { useSyncModalWithQueryParams } from '@/app/modals/hooks/useSyncModalWithQueryParams';
import { useAppSelector } from '@/core/redux/hooks';

import { BaseModalView } from './BaseModal.view';
import { getModal } from '../../models/modals';

const BaseModalController = () => {
    const hideModal = useHideModal();

    const modal = useAppSelector(getModal);
    const visible = modal.visible;

    useSyncModalWithQueryParams();

    const handleClose = useCallback(
        (event?: Event) => {
            if (event) {
                const target = event.target as HTMLElement;

                // Check if target is <html> to prevent closing modal unintentionally
                if (target.tagName === 'HTML') {
                    return;
                }
            }

            if (modal.options.onClose) {
                // Custom close in case you want to open a different modal instead of closing it
                modal.options.onClose();
            } else {
                hideModal();
            }
        },
        [hideModal, modal.options],
    );

    useEffect(() => {
        const { type } = modal;

        const escFunction = (event: KeyboardEvent) => {
            if (event.code === 'Esc') {
                handleClose(event);
            }
        };

        if (type) {
            document.addEventListener('keydown', escFunction, false);
        } else {
            document.removeEventListener('keydown', escFunction, false);
        }
    }, [handleClose, modal]);

    return (
        <BaseModalView
            type={modal.type}
            props={modal.props}
            visible={visible}
            className={modal.options.className}
            overlayClassName={modal.options.overlayClassName}
            flexClassName={modal.options.flexClassName}
            shouldCloseOnOverlayClick={modal.options.shouldCloseOnOverlayClick}
            onClose={handleClose}
        />
    );
};

export default BaseModalController;
