import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { Field, reduxForm, getFormValues, change } from 'redux-form';

import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { CropButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import { HeaderLogoAlign } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/HeaderLogoAlign';
import { IconToggleButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { MediaSrc } from 'modules/app/editor/editor/components/Sidebar/BlockEdit/elements/MediaSrc';

import type { BlockResource, DefaultEditFormProps } from '../../types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ handleSubmit, activeTheme, form }) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const formValues = useAppSelector((state) => getFormValues(form)(state)) as BlockResource;

    const isLogoShown = !!formValues?.attributes?.content?.showIcon;

    const handleCropSave = useCallback(
        (src: string) => {
            dispatch(change(form, 'attributes.content.src', src));

            // The setTimeout is necessary. Without, the cropped version
            // does not get properly saved.
            setTimeout(handleSubmit);
        },
        [dispatch, handleSubmit, form],
    );

    return (
        <>
            <Field name="attributes.content.size" component={GenericSize} submit={handleSubmit} />

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.box.backgroundColor"
                    component={Color}
                    expand="center"
                    submit={handleSubmit}
                    themeColor={getThemeColor(activeTheme, 'backgroundColor')}
                    tooltip={t('background-color')}
                />
            </div>

            <Subheading text={t('header.logo.subheading')} />

            <div className="mt-4 grid grid-cols-3 gap-2">
                <CropButton
                    src={formValues?.attributes?.content?.src}
                    size="custom"
                    onSave={handleCropSave}
                    overridenTooltip={t(
                        isLogoShown ? 'header.logo.crop.enabled' : 'header.logo.crop.disabled',
                    )}
                    disabled={!isLogoShown}
                    showTooltipWhenDisabled
                />

                <Field
                    name="attributes.content.alignment"
                    component={HeaderLogoAlign}
                    submit={handleSubmit}
                    disabled={!isLogoShown}
                />
                <Field
                    name="attributes.content.showIcon"
                    component={IconToggleButton}
                    submit={handleSubmit}
                    icon="eyeSlash"
                    inverted
                    tooltip={t(
                        isLogoShown ? 'header.logo.visibility.hide' : 'header.logo.visibility.show',
                    )}
                />
            </div>

            {isLogoShown && (
                <div className="mt-6">
                    <Field
                        name="attributes.content.src"
                        component={MediaSrc}
                        submit={handleSubmit}
                        imgixParams="?auto=format,compress&h=64&dpr=2"
                    />
                </div>
            )}
        </>
    );
};

Form.displayName = 'HeaderEditForm';

export default reduxForm({})(Form);
