import { downloadFile } from '@/app/crm/utils/downloadFile';

const extractFileHandleFromDownloadFieldValue = (value: string) => {
    const url = new URL(value);
    const fileHandle = url.searchParams.get('filehandle');

    return fileHandle;
};

export const directDownloadFromFilestack = async (fileUrl: string) => {
    const fileHandle = extractFileHandleFromDownloadFieldValue(fileUrl);

    // When jobId is missing, try with fileHandle. Note that virus detection is ignored
    // Note that this is a temporal solution, will be removed ASAP.
    if (fileHandle) {
        const fileDownloadURL = `https://cdn.filestackcontent.com/content=t:attachment/${fileHandle}`;

        downloadFile(fileDownloadURL, '_blank');

        return true;
    } else {
        reportError({ error: 'File has no fileHandle' });
    }

    return false;
};
