import { NAME } from '@/app/editor/blocks/constants';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { Tooltip } from '@/ui/components/Tooltip';

export interface Props {
    iconName: string;
}

const IconFallback = ({ iconName }: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <Tooltip
            content={t('icon-fallback-description', { icon: iconName })}
            className="min-w-[230px]"
        >
            <div className="size-8">
                <ExclamationTriangleIcon className="size-full text-amber-500" />
            </div>
        </Tooltip>
    );
};

export default IconFallback;
