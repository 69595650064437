import { NAME } from '@/app/crm/constants';

import { Square2StackIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import MorphingIcons from '@/ui/components/MorphingIcons';
import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';
import { stopPropagation } from '@/utils/common';

interface Props {
    value: string;
    onCopy?: () => void;
}

export const CopyButton = ({ value, onCopy }: Props) => {
    const { t } = useTranslation(NAME);
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);

        onCopy?.();
    };

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied]);

    return (
        <Tooltip content={copied ? t('common:copied') : t('common:copy-to-clipboard')} delay={0}>
            <div
                onClick={stopPropagation}
                className={cn('text-gray-600 hover:text-gray-900', {
                    'stroke-2 text-green-500 hover:text-green-500': copied,
                })}
            >
                <CopyToClipboard text={value} onCopy={handleCopy}>
                    <div>
                        <MorphingIcons
                            animate={copied}
                            className="size-4 cursor-pointer"
                            iconClassName={cn({ 'stroke-2': copied })}
                            defaultIconComponent={Square2StackIcon}
                        />
                    </div>
                </CopyToClipboard>
            </div>
        </Tooltip>
    );
};
