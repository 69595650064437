/* CAUTION: Imgix does not support animated gifs (yet) */

import type { ImgixOptions } from './types';

export const getImgixUrl = (path: string, options?: ImgixOptions) => {
    const cleanPath = path.replace(/^\/$/g, '');

    const url = new URL(`${process.env.NEXT_PUBLIC_IMGIX_CDN}${cleanPath}`);

    if (options) {
        Object.entries(options).forEach(([option, value]) => {
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    url.searchParams.append(option, item);
                });
            } else {
                url.searchParams.append(option, value);
            }
        });
    }

    return url.href;
};

export const imgix = {
    logo: getImgixUrl('assets/app/logo/64x64.png'),
    flags: {
        us: getImgixUrl('assets/app/templateStore/usflagemoji.png'),
        de: getImgixUrl('assets/app/templateStore/deflagemoji.png'),
    },
    analytics: {
        nodata: getImgixUrl('assets/app/analytics/blurred-analytics.jpg'),
        nodataAnalyticsIcon: getImgixUrl('assets/app/analytics/bar-chart.svg'),
        nodataVisitors: getImgixUrl('assets/app/analytics/bg-visitors-blur@2x.jpg'),
        nodataVisitorsIcon: getImgixUrl('assets/app/analytics/speech-Toggle.svg'),
        nodataSummary: getImgixUrl('assets/app/analytics/bg-insights-blur@2x.jpg'),
        nodataSummaryIcon: getImgixUrl('assets/app/analytics/eye.svg'),
        csvIcon: getImgixUrl('assets/app/analytics/csv.png'),
        zipIcon: getImgixUrl('assets/app/analytics/zip.png'),
        downloadIcon: getImgixUrl('assets/app/analytics/download.svg'),
        splitTestingIcon: getImgixUrl('assets/app/analytics/splittesting.png'),
        splitTestingScreenshot: getImgixUrl('assets/app/banner/split-testing.png'),
        winnerIcon: getImgixUrl('assets/app/analytics/winner.png'),
    },
    backgrounds: {
        signInBg: getImgixUrl('assets/app/backgrounds/sign-in_bg.jpg'),
        notFound:
            'https://s3.eu-central-1.amazonaws.com/perspective-imgix/assets/app/backgrounds/travolta.gif',
    },
    banner: {
        campaignFirstUsage: getImgixUrl('assets/app/banner/allcampaigns_first-usage-banner.jpg'),
        audienceFirstUsage: getImgixUrl('assets/app/banner/audiences_first-usage-banner.jpg'),
        upgradeV3: getImgixUrl('assets/app/banner/banner_upgrade-to-3@2x.jpg'),
        noDomainsBanner: getImgixUrl('assets/app/banner/nodomainsbanner.jpg'),
        legacyPricingBanner: getImgixUrl('assets/app/banner/legacyPricingBanner.jpg'),
    },
    components: {
        webinar: {
            buttonTooltip:
                'https://s3.eu-central-1.amazonaws.com/perspective-imgix/assets/app/components/webinar/component_webinar.gif',
        },
        button: {
            buttonAnimation:
                'https://s3.eu-central-1.amazonaws.com/perspective-imgix/assets/app/components/button/animatedButton.gif',
        },
    },
    crm: {
        profileNotesSkeleton: getImgixUrl('assets/app/crm/profile-notes-skeleton.svg'),
    },
    editor: {
        sectionPreviewPath: getImgixUrl('assets/app/blocks/previews/'),
        embedBlock: {
            introVisual: getImgixUrl('assets/app/embedblock/intro-visual.png'),
            googleMapsBg: getImgixUrl('assets/app/embedblock/google-maps-bg.jpg'),
        },
        iconLib: {
            previewIconSprite: getImgixUrl('assets/app/iconlib/icon8%20style%20sprite.png'),
        },
        imageLib: {
            fileIcon: getImgixUrl('assets/app/editor/file-icon.png'),
            folderIcon: getImgixUrl('assets/app/editor/folder-icon.png'),
        },
        messages: {
            senders: {
                introHeader: getImgixUrl('assets/app/editor/messages/new-sender-intro-image.webp', {
                    auto: 'compress',
                }),
                emailHeader: getImgixUrl('assets/app/editor/messages/new-sender-email-image.webp', {
                    auto: 'compress',
                }),
            },
        },
        qrCode: {
            background: getImgixUrl('assets/app/qrBackground.jpg'),
        },
    },
    placeholder: {
        building: getImgixUrl('assets/app/placeholder/building.png'),
    },
    themes: { custom: getImgixUrl('assets/app/themes/custom.png') },
    launch: {
        funnelSettingsGraphic: getImgixUrl('assets/app/launch/funnel-settings.png'),
        shareContacts: getImgixUrl('assets/app/launch/share-contacts-header.png'),
        embedFunnelGraphic: getImgixUrl('assets/app/launch/embed-funnel-header.png'),
        pathToSuccessGraphic: getImgixUrl('assets/app/launch/how-to-get-traffic-header.png'),
    },
    settings: {
        general: {
            badgeTooltip: getImgixUrl('assets/app/general_settings/badge_tooltip.jpg'),
            favIconPreviewBg: getImgixUrl('assets/app/general_settings/favicon_preview.png'),
            progressBarTooltip: getImgixUrl('assets/app/general_settings/progressbar_tooltip.jpg'),
        },
        sharing: {
            publicTitle: getImgixUrl('assets/app/sharing_settings/sharing_public_title.png'),
            publicDescription: getImgixUrl(
                'assets/app/sharing_settings/sharing_public_description.png',
            ),
            sharingImage: getImgixUrl('assets/app/sharing_settings/sharing_image.png'),
        },
        cookies: {
            cookiePreviewSimple: getImgixUrl('assets/app/cookies/cookie-preview-simple.png'),
            cookiePreviewNone: getImgixUrl('assets/app/cookies/cookie-preview-none.png'),
            cookiePreviewExtended: getImgixUrl('assets/app/cookies/cookie-preview-extended.png'),
            cookieNotice: getImgixUrl('assets/app/cookies/cookie_notice.jpg'),
            checkmark: getImgixUrl('assets/app/cookies/checkmark.svg'),
            explicit: getImgixUrl('assets/app/cookies/advanced.png'),
            normal: getImgixUrl('assets/app/cookies/normal.png'),
            none: getImgixUrl('assets/app/cookies/none.png'),
        },
        progressbar: {
            active: getImgixUrl('assets/app/progressbar/progress-active.png'),
            inactive: getImgixUrl('assets/app/progressbar/progress-inactive.png'),
        },
        affiliate: {
            active: getImgixUrl('assets/app/affiliate/affiliate-badge-active.png'),
            inactive: getImgixUrl('assets/app/progressbar/progress-inactive.png'),
        },
    },
    billing: {
        highlightBg: getImgixUrl('assets/app/billing/highlightbg.png'),
        flowerLogo: getImgixUrl('assets/app/logo/flower.png'),
    },
    pricing: { contact: getImgixUrl('assets/app/pricing/contact.png') },
    overlays: {
        marco: getImgixUrl('assets/app/overlays/marco.png'),
        duplicationConfirmation: getImgixUrl('assets/app/overlays/duplication_confirm.png'),
        alertIcon: getImgixUrl('assets/app/overlays/alert_icon.svg'),
        infoIcon: getImgixUrl('assets/app/overlays/info_icon.svg'),
        mailIcon: getImgixUrl('assets/app/overlays/mail_icon.svg'),
    },
    contacts: {
        noData: getImgixUrl('assets/app/contacts/bg-contacts-blur.jpg'),
        noDataIcon: getImgixUrl('assets/app/contacts/icon_empty_contacts.svg'),
        noDataMigrationIcon: getImgixUrl('assets/app/contacts/icon_empty_contacts_migration.svg'),
        newContactsArea: getImgixUrl('assets/app/contacts/new-contacts-area.jpg'),
        newContactsAreaEn: getImgixUrl('assets/app/contacts/new-contacts-area-en.png'),
    },
    mentoring: {
        checkmark: getImgixUrl('assets/app/mentoring/checkmark.svg'),
    },
    metrics: {
        empty: getImgixUrl('assets/app/metrics/empty_state.svg'),
        perspectiveTextLogo: getImgixUrl('assets/app/metrics/perspectiveTextLogo_v2.png'),
        backgrounds: {
            moonrise: getImgixUrl('assets/app/metrics/backgrounds/moonrise_v2.png', {
                auto: 'compress',
            }),
            glassRainbow: getImgixUrl('assets/app/metrics/backgrounds/glass-rainbow_v2.png', {
                auto: 'compress',
            }),
            roseThorn: getImgixUrl('assets/app/metrics/backgrounds/rose-thorn_v2.png', {
                auto: 'compress',
            }),
        },
    },
    domains: {
        domainTypes: {
            custom: getImgixUrl('assets/app/zeitDomains/customdomaintype.png'),
            instant: getImgixUrl('assets/app/zeitDomains/instantdomaintype.png'),
        },
        ownDomain: {
            intro: getImgixUrl('assets/app/domains/own-domain/intro.svg'),
            domain: getImgixUrl('assets/app/domains/own-domain/domain.svg'),
            success: getImgixUrl('assets/app/domains/own-domain/success.svg'),
            providers: {
                allInkl: getImgixUrl('assets/app/domains/own-domain/providers/all-inkl.svg'),
                goDaddy: getImgixUrl('assets/app/domains/own-domain/providers/godaddy.svg'),
                ionos: getImgixUrl('assets/app/domains/own-domain/providers/ionos.svg'),
                namecheap: getImgixUrl('assets/app/domains/own-domain/providers/namecheap.svg'),
                strato: getImgixUrl('assets/app/domains/own-domain/providers/strato.svg'),
            },
        },
        instantSubDomain: {
            intro: getImgixUrl('assets/app/domains/instant-subdomain/intro.svg'),
            success: getImgixUrl('assets/app/domains/instant-subdomain/success.svg'),
        },
        verificationFailed: getImgixUrl('assets/app/zeitDomains/validationerror.png'),
        customDomainWarning: getImgixUrl('assets/app/zeitDomains/customdomainwarning.png'),
        alert: getImgixUrl('assets/app/zeitDomains/alert.png'),
        bulb: getImgixUrl('assets/app/domains/bulb.png'),
        chain: getImgixUrl('assets/app/domains/chain.png'),
        warning: getImgixUrl('assets/app/domains/warning.png'),
        check: getImgixUrl('assets/app/domains/check.png'),
        video: getImgixUrl('assets/app/domains/video.png'),
    },
    favicons: {
        perspective: getImgixUrl('assets/app/favicons/perspective.png'),
        calendar: getImgixUrl('assets/app/favicons/calendar.png'),
        gift: getImgixUrl('assets/app/favicons/gift.png'),
        lightning: getImgixUrl('assets/app/favicons/lightning.png'),
        world: getImgixUrl('assets/app/favicons/world.png'),
        star: getImgixUrl('assets/app/favicons/star.png'),
    },
    notifications: {
        error: getImgixUrl('assets/app/notifications/error.png'),
        confirmed: getImgixUrl('assets/app/notifications/confirmed.png'),
        finger: getImgixUrl('assets/app/notifications/finger.png'),
        notification: getImgixUrl('assets/app/notifications/notification.png '),
    },
    sharing: {
        affiliateBanner: getImgixUrl('assets/app/sharing_funnels/affiliate_banner.png'),
        affiliateOverlay: getImgixUrl('assets/app/sharing_funnels/affiliate_overlay_icon.png'),
        saveFunnel: getImgixUrl('assets/app/sharing_funnels/save_funnel.png'),
    },
    personalization: {
        customVarModal: getImgixUrl('assets/app/personalization/headline-nichtda.png'),
    },
    referrals: {
        customers: { emptyStateBg: getImgixUrl('assets/app/referrals/customers-empty-bg.svg') },
    },
    user: {
        unsubscribeEmail: getImgixUrl('assets/app/email/unsubscribe.png'),
    },
    surveys: {
        onboarding: {
            iconsPathPrefix: getImgixUrl('assets/app/surveys/onboarding/'),
        },
    },
    workspaces: {
        workspaceSetup: {
            commissionTileBg: getImgixUrl('assets/app/workspaces/commission-tile-bg.png'),
        },
    },
};

export default imgix;
