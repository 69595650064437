// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"129fc89996335d0769c8b1c23d1ad7594e91da58"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { infoLog } from './modules/utils/debugLogs';
import { CORRELATION_ID_HEADER } from './modules/core/api/constants';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === 'production' && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,

        beforeSend(event) {
            // Ignore errors that happens during logout (401 responses)
            if (
                event.tags?.transaction?.includes('/logout') ||
                event.transaction?.includes('/logout')
            ) {
                return null;
            }

            return event;
        },

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.01,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // Also attach stacktrace to messages via `captureMessage`
        attachStacktrace: true,

        // Enables Session Replays
        integrations: [
            Sentry.replayIntegration({
                unblock: ['svg'],
                maskAllText: false,
                networkDetailAllowUrls: [
                    'https://api.perspective.co',
                    'https://crm-api.perspective.co',
                    'https://lemon-tool-api.herokuapp.com',
                    'https://ps-crm-api.herokuapp.com',
                    'https://perspective-lemon-tree.herokuapp.com',
                    'https://parrot-ai-prod.herokuapp.com',
                    'https://api.tiptap.dev',
                ],
                networkRequestHeaders: [CORRELATION_ID_HEADER],
            }),
        ],

        allowUrls: [/https?:\/\/app\.perspective\.co/],

        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            'Load failed',
            'Non-Error promise rejection captured',
            'Could not mount master component',
            'Cancel rendering route',
            'Action timed out',
            'The object is in an invalid state.',
            'e.video is null',
            "Identifier 'wsWhitelisted' has already been declared",
            "Can't find variable: gtag",
            'gtag is not defined',
            "Can't find variable: msDiscoverChatAvailable",
            'Permission denied to access property "__SENTRY_LOADER__"',
            'Event `ErrorEvent` captured as exception with message `Script error.`', // Random (non-breaking) Safari error (https://github.com/getsentry/sentry-javascript/issues/7941#issuecomment-1815927662)
            // Network errors
            'Network Error',
            'cancelled',
            'canceled',
            'Abgebrochen',
            'Failed to fetch',
            'The operation was aborted.',
            /Loading chunk \d+ failed/,
            'NetworkError when attempting to fetch resource.',
            /Request failed with status code \d+/,
            'The request timed out.',
            'Es ist ein SSL-Fehler aufgetreten.',
            'Request aborted',
            'timeout exceeded',
            // TipTap
            /Position \d+ out of range/,
            // Draft.js
            "Cannot read properties of undefined (reading 'getIn')",
            "undefined is not an object (evaluating 'k.getBlockTree(_).getIn')",
            // From Sentry docs: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            'Cannot redefine property: googletag',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            /**
             * Thrown when firefox prevents an add-on from referencing a DOM element that has been removed.
             * This can also be filtered by enabling the browser extension inbound filter
             */
            "TypeError: can't access dead object",
            /**
             * React internal error thrown when something outside react modifies the DOM
             * This is usually because of a browser extension or Chrome's built-in translate
             */
            "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
            "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
        ],
    });
} else {
    if (typeof window !== 'undefined') {
        infoLog('Sentry: Not initiating in development environment');
    }
}
