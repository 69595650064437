import { NAME, DEFAULT_PLATFORM } from '@/app/editor/iconLibrary/constants';

import debounce from 'lodash/debounce';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import {
    getLastSelectedPlatformFromLocalStorage,
    setLastSelectedPlatformToLocalStorage,
} from '@/app/editor/iconLibrary/helper';
import {
    getFetchingPage,
    getIcons,
    getSearching,
    setPlatformAndFetchIcons,
    getFetching,
    getPagination,
    fetchNextIconsPage,
    setSearchTerm,
    getSearchTerm,
    fetchIcons,
} from '@/app/editor/iconLibrary/models/icons';
import {
    fetchPlatforms,
    getCurrentPlatform,
    getPlatforms,
    getFetching as getFetchingPlatforms,
} from '@/app/editor/iconLibrary/models/platforms';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import LibraryView from './Library.view';

import type { Icon, Platform } from '@/app/editor/iconLibrary/types';
import type { ChangeEvent } from 'react';

export interface Props {
    blockIconId: string;
    initialPlatform: string;
    onIconSelect: (icon: Icon) => void;
}

const debouncedSearch = debounce((cb) => {
    cb();
}, 200);

const LibraryController = ({ initialPlatform, blockIconId, onIconSelect }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const [initialized, setInitialized] = useState(false);

    const platforms = useAppSelector(getPlatforms);
    const icons = useAppSelector(getIcons);
    const selectedPlatform = useAppSelector(getCurrentPlatform);
    const fetchingPage = useAppSelector(getFetchingPage);
    const fetchingSearch = useAppSelector(getSearching);
    const fetchingIcons = useAppSelector(getFetching);
    const fetchingPlatforms = useAppSelector(getFetchingPlatforms);
    const pagination = useAppSelector(getPagination);
    const searchTerm = useAppSelector(getSearchTerm);

    // Init
    useEffect(() => {
        if (!initialized) {
            if (!platforms.length) {
                dispatch(fetchPlatforms(initialPlatform));
            } else {
                // Order of preference:
                // - #1 Platform stored in localstorage (might not be set)
                // - #2 Platform currently set in platforms slice (might not be set)
                // - #3 Initial platform (fallback)
                let lastSelectedPlatformFromLocalStorage =
                    getLastSelectedPlatformFromLocalStorage();

                const allStylesPlatform = {
                    value: DEFAULT_PLATFORM,
                    key: t('all-styles'),
                    isColor: false,
                };

                let platformToSet: Platform | undefined = undefined;

                // #1
                if (lastSelectedPlatformFromLocalStorage) {
                    platformToSet =
                        lastSelectedPlatformFromLocalStorage.id === 'all-styles'
                            ? allStylesPlatform
                            : platforms.find(
                                  (platform) =>
                                      platform.value === lastSelectedPlatformFromLocalStorage.id &&
                                      platform.isColor ===
                                          lastSelectedPlatformFromLocalStorage.isColor,
                              );
                }

                // #2
                if (!platformToSet && selectedPlatform.id.length > 0) {
                    platformToSet = platforms.find(
                        (platform) => platform.value === selectedPlatform.id,
                    );
                }

                // #3
                if (!platformToSet) {
                    platformToSet = platforms.find(
                        (platform) => platform.value === initialPlatform,
                    );
                }

                if (!platformToSet) {
                    platformToSet = allStylesPlatform;
                }

                dispatch(setPlatformAndFetchIcons(platformToSet.value, platformToSet.isColor));
                setInitialized(true);
            }
        }
    }, [t, dispatch, initialPlatform, platforms, initialized, selectedPlatform.id]);

    // Debounced search change
    useEffect(() => {
        debouncedSearch(() => dispatch(fetchIcons()));
    }, [dispatch, searchTerm]);

    // Unmount
    useEffect(() => {
        return () => {
            dispatch(setSearchTerm(''));
        };
    }, [dispatch]);

    const handlePlatformChange = (platform?: Platform) => {
        if (!platform) {
            return;
        }

        dispatch(setPlatformAndFetchIcons(platform.value, platform.isColor));

        setLastSelectedPlatformToLocalStorage({ id: platform.value, isColor: platform.isColor });
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event?.target?.value;

        dispatch(setSearchTerm(searchTerm));
    };

    const handleIconSelect = (icon: Icon) => {
        onIconSelect(icon);

        // We want to use the icon style as the "last selected style".
        const platform = platforms.find((platform) => platform.value === icon.platform);

        if (platform) {
            setLastSelectedPlatformToLocalStorage({
                id: platform.value,
                isColor: platform.isColor,
            });
        }
    };

    const handleLoadMore = () => {
        dispatch(fetchNextIconsPage());
    };

    return (
        <LibraryView
            fetchingPlatforms={fetchingPlatforms}
            fetchingIcons={fetchingIcons}
            fetchingSearch={fetchingSearch}
            fetchingPage={fetchingPage}
            platforms={platforms}
            selectedPlatform={selectedPlatform}
            onPlatformChange={handlePlatformChange}
            onSearchChange={handleSearchChange}
            onIconSelect={handleIconSelect}
            onLoadMore={pagination?.next ? handleLoadMore : undefined}
            searchTerm={searchTerm}
            icons={icons}
            blockIconId={blockIconId}
        />
    );
};

export default LibraryController;
