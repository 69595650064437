export const MastercardIcon = () => {
    return (
        <svg
            width="32"
            height="20"
            viewBox="0 0 32 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19.1071 4.54102H12.8743V15.459H19.1071V4.54102Z" fill="#FF5A00" />
            <path
                d="M13.2892 10C13.2892 7.78181 14.3587 5.81305 15.9999 4.54101C14.7921 3.61509 13.2684 3.05548 11.6065 3.05548C7.66945 3.05548 4.48389 6.16141 4.48389 10C4.48389 13.8386 7.66945 16.9446 11.6065 16.9446C13.2684 16.9446 14.7921 16.3849 15.9999 15.459C14.3564 14.2049 13.2892 12.2182 13.2892 10Z"
                fill="#EB001B"
            />
            <path
                d="M27.5163 10C27.5163 13.8386 24.3307 16.9446 20.3937 16.9446C18.7317 16.9446 17.2081 16.3849 16.0002 15.459C17.6622 14.1847 18.711 12.2182 18.711 10C18.711 7.78181 17.6414 5.81305 16.0002 4.54101C17.2058 3.61509 18.7294 3.05548 20.3913 3.05548C24.3307 3.05548 27.5163 6.18165 27.5163 10Z"
                fill="#F79E1B"
            />
        </svg>
    );
};
