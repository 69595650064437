import { NAME as SHARED_FUNNELS } from '@/app//sharedFunnels/constants';
import { NAME as ANALYTICS } from '@/app/analytics/constants';
import { NAME as ASSETS } from '@/app/assets/constants';
import { NAME as AUTH } from '@/app/auth/constants';
import { NAME as BILLING } from '@/app/billing/constants';
import { NAME as CAMPAIGNS } from '@/app/campaigns/constants';
import { NAME as CANCELLATION } from '@/app/cancellation/constants';
import { NAME as COMMAND_MENU } from '@/app/cmdk/constants';
import { NAME as COMPANY } from '@/app/company/constants';
import { NAME as CRM } from '@/app/crm/constants';
import { NAME as DOMAINS } from '@/app/domains/constants';
import { NAME as BLOCKS } from '@/app/editor/blocks/constants';
import { NAME as COMMANDS } from '@/app/editor/commands/constants';
import { NAME as EDITOR } from '@/app/editor/editor/constants';
import { NAME as ICON_LIBRARY } from '@/app/editor/iconLibrary/constants';
import { NAME as PAGES } from '@/app/editor/pages/constants';
import { NAME as SECTIONS } from '@/app/editor/sections/constants';
import { NAME as THEMES } from '@/app/editor/themes/constants';
import { NAME as FAVICON } from '@/app/funnelSettings/favicon/constants';
import { NAME as FUNNEL_SETTINGS } from '@/app/funnelSettings/funnelSettings/constants';
import { NAME as MODALS } from '@/app/modals/constants';
import { NAME as PATH_TO_SUCCESS } from '@/app/pathToSuccess/constants';
import { NAME as REFERRALS } from '@/app/referrals/constants';
import { NAME as SETTINGS } from '@/app/settings/constants';
import { NAME as USER } from '@/app/user/constants';
import { NAME as WORKSPACES } from '@/app/workspaces/constants';

import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import sharedFunnelsReducer from '@/app//sharedFunnels/models';
import analyticsReducer from '@/app/analytics/models';
import assetsReducer from '@/app/assets/models';
import authReducer from '@/app/auth/models';
import billingReducer from '@/app/billing/models';
import campaignsReducer from '@/app/campaigns/models';
import cancellationReducer from '@/app/cancellation/models';
import commandMenuReducer from '@/app/cmdk/models';
import companyReducer from '@/app/company/models';
import crmReducer from '@/app/crm/models';
import domainsReducer from '@/app/domains/models';
import blocksReducer from '@/app/editor/blocks/models';
import commandsReducer from '@/app/editor/commands/models';
import editorReducer from '@/app/editor/editor/models';
import iconLibraryReducer from '@/app/editor/iconLibrary/models';
import pagesReducer from '@/app/editor/pages/models';
import sectionsReducer from '@/app/editor/sections/models';
import themesReducer from '@/app/editor/themes/models';
import faviconsReducer from '@/app/funnelSettings/favicon/models';
import funnelSettingsReducer from '@/app/funnelSettings/funnelSettings/models';
import modalsReducer from '@/app/modals/models';
import pathToSuccessReducer from '@/app/pathToSuccess/models';
import referralsReducer from '@/app/referrals/models';
import settingsReducer from '@/app/settings/models';
import userReducer from '@/app/user/models';
import workspacesReducer from '@/app/workspaces/models';
import { getIsLocalhostEnv } from '@/utils/environments';

// Combine all reducers
const reducers = {
    form: formReducer,
    [ANALYTICS]: analyticsReducer,
    [ASSETS]: assetsReducer,
    [AUTH]: authReducer,
    [BILLING]: billingReducer,
    [BLOCKS]: blocksReducer,
    [CAMPAIGNS]: campaignsReducer,
    [CANCELLATION]: cancellationReducer,
    [COMMANDS]: commandsReducer,
    [COMMAND_MENU]: commandMenuReducer,
    [COMPANY]: companyReducer,
    [CRM]: crmReducer,
    [DOMAINS]: domainsReducer,
    [EDITOR]: editorReducer,
    [FAVICON]: faviconsReducer,
    [FUNNEL_SETTINGS]: funnelSettingsReducer,
    [ICON_LIBRARY]: iconLibraryReducer,
    [MODALS]: modalsReducer,
    [PAGES]: pagesReducer,
    [PATH_TO_SUCCESS]: pathToSuccessReducer,
    [REFERRALS]: referralsReducer,
    [SECTIONS]: sectionsReducer,
    [SETTINGS]: settingsReducer,
    [SHARED_FUNNELS]: sharedFunnelsReducer,
    [THEMES]: themesReducer,
    [USER]: userReducer,
    [WORKSPACES]: workspacesReducer,
};

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: getIsLocalhostEnv(),
});
