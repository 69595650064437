import type { DEFAULT_COLOR_VALUES } from './constants';
import type { CampaignCRMStatus } from '../crm/types';
import type { Relationship, Resource } from '@/core/api/types';

export const enum WidgetType {
    KPI = 'kpi',
    Chart = 'chart',
}

export enum AvailableCharts {
    ActivityByDaytime = 'chart_activity_by_daytime',
    ButtonClicks = 'chart_button_clicks',
    PageToPageConversionRate = 'chart_page_to_page_conversion_rate',
    StatusMakeup = 'chart_status_makeup',
    TimeOnPage = 'chart_time_on_page',
    TopUTMSources = 'chart_top_utm_sources',
    VisitorDevices = 'chart_visitor_devices',
    TopAnswers = 'chart_top_answers',
    // To be implemented
    // ContactsOverTime = 'chart_contacts_over_time',
    // ConversionRate = 'chart_conversion_rate',
    // VideoAnalytics = 'chart_video_analytics',
}

export enum AvailableABTestCharts {
    PageCompletionRate = 'ab-page-completion-rate',
    PageConversionRate = 'ab-page-conversion-rate',
    TimeOnPage = 'ab-time-on-page',
    Visits = 'ab-visits',
}

export enum AvailableKPIs {
    CompletionRate = 'kpi_completion_rate',
    ConversionRate = 'kpi_conversion_rate',
    NewContacts = 'kpi_new_contacts',
    TotalSessions = 'kpi_total_sessions',
    // To be implemented
    // TimeOnPage = 'kpi_time_on_page',
    // PipelineValue = 'kpi_pipeline_value',
    // ClosingRate = 'kpi_closing_rate',
    // CustomPageConversionRate = 'kpi_custom_page_conversion_rate',
}

export type WidgetSubtype = AvailableCharts | AvailableKPIs;

// Dashboard

export interface DashboardWidget {
    id: string;
    subtype: WidgetSubtype;
}

export interface MetricsDashboardAttributes {
    kpis: DashboardWidget[];
    charts: DashboardWidget[];
    pipeline_value: number;
}

export interface MetricsDashboardRelationships {
    campaign: Relationship;
}

export type MetricsDashboardResource = Resource<
    MetricsDashboardAttributes,
    MetricsDashboardRelationships
>;

// Dashboard Analytics

export type KpiRateAnalytics = {
    rate: number;
    relative_difference?: number;
};

export type KpiAggregateAnalytics = {
    aggregate: number;
    relative_difference?: number;
};

export type InsightAnalyticsItem = {
    insightId: string;
    title: string;
    aggregates: Record<string, number>;
    raw_aggregates: Record<string, number>;
};

export type ChartActivityByDaytimeItem = {
    hour_bucket: string;
    activity_per_hour: number;
    relative_activity_per_hour: number;
};

export type ChartButtonClicksItem = {
    button_id: string;
    button_text: string;
    click_count: number;
};

export type ChartPage2PageCvrItem = {
    page_id: string;
    page_name: string;
    page_loads: number;
    page_conversion_rate: number;
    campaign_conversion_rate: number;
};

export type ChartTimeOnPageItem = {
    page_id: string;
    page_name: string;
    time_on_page_seconds: number;
};

export type ChartTopUtmSourcesAnalytics = {
    total: number;
    sources_relative: Record<string, number>;
    sources_total: Record<string, number>;
};

export type ChartButtonClicksAnalytics = ChartButtonClicksItem[];

export type ChartPage2PageCvrAnalytics = ChartPage2PageCvrItem[];

export type ChartTimeOnPageAnalytics = ChartTimeOnPageItem[];

export type ChartVisitorDevicesAnalytics = {
    total_visitors: number;
    total_mobile: number;
    total_desktop: number;
    total_tablet: number;
    total_other: number;
    relative_mobile: number;
    relative_desktop: number;
    relative_tablet: number;
    relative_other: number;
};

export type InsightAnalytics = InsightAnalyticsItem[];

export type ChartActivityByDaytimeAnalytics = ChartActivityByDaytimeItem[];

export type ChartStatusMakeupAnalytics = CampaignCRMStatus[];

export interface MetricsChartAnalytics {
    type: WidgetType.Chart;
    widget_id: string;
    subtype: AvailableCharts;
    calculation:
        | InsightAnalytics
        | ChartActivityByDaytimeAnalytics
        | ChartVisitorDevicesAnalytics
        | ChartTopUtmSourcesAnalytics
        | ChartButtonClicksAnalytics
        | ChartPage2PageCvrAnalytics
        | ChartTimeOnPageAnalytics
        | ChartStatusMakeupAnalytics
        | {};
}

export interface MetricsKpiAnalytics {
    type: WidgetType.KPI;
    widget_id: string;
    subtype: AvailableKPIs;
    calculation: KpiRateAnalytics | KpiAggregateAnalytics;
}

export type WidgetCalculation =
    | (MetricsKpiAnalytics['calculation'] | MetricsChartAnalytics['calculation'])
    | {};

export type DashboardAnalyticsAttributes = Record<
    string,
    MetricsKpiAnalytics | MetricsChartAnalytics
>;

type ABTestSharedAnalytics = {
    campaign_conversion_rate: number;
    completion_rate: number;
    conversion_rate: number;
    conversions: number;
    sessions: number;
    time_page: number;
};

type ABTestCaseAnalytics = ABTestSharedAnalytics & {
    page_id: string;
};

type ABTestSummary = {
    duration_days: number;
    is_live: boolean;
    relevance: string;
    start_date: string;
    statistical_significance: number;
    total_visits: number;
    winner: string;
};

type ABTestPageVisits = {
    campaign_conversion_rate: number;
    page_conversion_rate: number;
    page_id: string;
    page_loads: number;
    page_name: string;
};

export type OptionalAttributes = {
    type?: 'dashboard_analytics';
    ab_test?: {
        both: ABTestSharedAnalytics;
        original_page: ABTestCaseAnalytics;
        variant_page: ABTestCaseAnalytics;
        summary: ABTestSummary;
        page_visits: ABTestPageVisits[];
    };
};

export type DashboardAnalyticsResource = Resource<DashboardAnalyticsAttributes, null> & {
    optional_attributes?: OptionalAttributes;
};

type ABTestChartsOriginalDataItem = {
    name: 'Original';
    value: number;
};

type ABTestChartsVariantDataItem = {
    name: 'Variant';
    value: number;
};

export type ABTestChartsData = {
    pageConversionRate: [ABTestChartsOriginalDataItem, ABTestChartsVariantDataItem];
    pageCompletionRate: [ABTestChartsOriginalDataItem, ABTestChartsVariantDataItem];
    timeOnPage: [ABTestChartsOriginalDataItem, ABTestChartsVariantDataItem];
    visits: [ABTestChartsOriginalDataItem, ABTestChartsVariantDataItem];
};

// Charts

export interface SortableChartAttributes extends DashboardWidget {
    size?: 1 | 2;
}

export type DefaultChartDataItem = {
    name: string;
    value: number;
    percentage?: number;
    count?: number;
    meta?: Record<string, string | number>;
};

export type DefaultColorValue = (typeof DEFAULT_COLOR_VALUES)[number];

export type DateTimeByDayAndHour = {
    day: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
    hour: string;
    value: number;
};

type ActivityByDatetimeDataGroup = {
    '2am': number;
    '4am': number;
    '6am': number;
    '8am': number;
    '10am': number;
    '12pm': number;
    '2pm': number;
    '4pm': number;
    '6pm': number;
    '8pm': number;
    '10pm': number;
    '12am': number;
};

export type ActivityByDatetimeData = {
    monday: ActivityByDatetimeDataGroup;
    tuesday: ActivityByDatetimeDataGroup;
    wednesday: ActivityByDatetimeDataGroup;
    thursday: ActivityByDatetimeDataGroup;
    friday: ActivityByDatetimeDataGroup;
    saturday: ActivityByDatetimeDataGroup;
    sunday: ActivityByDatetimeDataGroup;
};

export type ExportMetricBgOption = {
    id: 'glassRainbow' | 'moonrise' | 'roseThorn';
    url: string;
};

// KPIs

export type ComputeSpanSize = (index: number) => number;

// Settings

export const enum AddWidgetModalTabs {
    all = 'all',
    kpis = 'kpis',
    charts = 'charts',
    insights = 'insights',
}

export enum KPIValueType {
    Number = 'number',
    Percentage = 'percentage',
}

export enum TimeframeOption {
    Today = 'today',
    Yesterday = 'yesterday',
    LastWeek = 'lastWeek',
    Last2Weeks = 'last2Weeks',
    Last4Weeks = 'last4Weeks',
    Last3Months = 'last3Months',
    Last12Months = 'last12Months',
    MonthToDate = 'monthToDate',
    QuarterToDate = 'quarterToDate',
    YearToDate = 'yearToDate',
    AllTime = 'allTime',
    Custom = 'custom',
}

// API

export type AddWidgetParams = {
    type: WidgetType;
    subtype: WidgetSubtype;
    insight_id?: string;
};

export type UpdateWidgetOrderParams = {
    kpis?: string[];
    charts?: string[];
};

export type ManageDashboardParams = {
    create: AddWidgetParams[];
    delete: string[];
};
