import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { useClickAway } from 'react-use';

import { cn } from '@/utils/cn';
import { stopPropagation } from '@/utils/common';

import { modalMap } from '../../map';

export interface Props {
    type: string;
    props: any;
    visible: boolean;
    className?: string;
    overlayClassName?: string;
    flexClassName?: string;
    shouldCloseOnOverlayClick?: boolean;
    onClose?: (event: Event) => void;
}

export const BaseModalView = ({
    type,
    props,
    visible,
    className,
    overlayClassName,
    flexClassName,
    shouldCloseOnOverlayClick = true,
    onClose,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const Modal = modalMap[type];
    const isOpen = visible && !!Modal;

    useClickAway(ref, (event) => {
        if (shouldCloseOnOverlayClick) {
            onClose?.(event);
        }
    });

    return (
        <AnimatePresence initial={false}>
            {isOpen && (
                <div className="relative z-modal">
                    <motion.div
                        className={cn('fixed inset-0 bg-gray-800/50', overlayClassName)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.2 } }}
                        exit={{ opacity: 0, transition: { duration: 0.15 } }}
                    />

                    <div className="fixed inset-0 overflow-y-auto">
                        <motion.div
                            className={cn(
                                'flex h-screen items-center justify-center',
                                flexClassName,
                            )}
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1, transition: { duration: 0.2 } }}
                            exit={{ opacity: 0, scale: 0.95, transition: { duration: 0.15 } }}
                        >
                            <div
                                className={cn('w-full p-4', className)}
                                ref={ref}
                                onClick={stopPropagation}
                            >
                                {Modal ? <Modal {...props} onClose={onClose} /> : null}
                            </div>
                        </motion.div>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};
