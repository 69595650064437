import type { UniqueInputType } from '@/app/editor/blocks/types';

export type SidebarViewId =
    | 'pages'
    | 'editBlock'
    | 'addBlock'
    | 'addEmailBlock'
    | 'editEmailBlock'
    | 'addBlockToColumn'
    | 'themes'
    | 'editTheme'
    | 'footer'
    | 'messages';

export const enum ArtBoardSize {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}

export type ColorPickerExpandDirection = 'left' | 'right' | 'center';

export interface InputOption {
    icon?: string;
    name: string;
    field: string | UniqueInputType;
    inputType: string;
    placeholder: string;
    customSelectIcon?: string;
    customOptions?: Record<string, boolean | string | string[]>;
    misc?: any;
}

export interface InputTypeOptions {
    en: InputOption[];
    de: InputOption[];
}
