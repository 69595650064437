import { TRACKING_EVENTS } from '@/core/tracking/constants';

import {
    AtSymbolIcon,
    Bars3BottomLeftIcon,
    CalendarIcon,
    CheckIcon,
    DocumentIcon,
    EnvelopeIcon,
    HashtagIcon,
    LinkIcon,
    PhoneArrowUpRightIcon,
    PhoneIcon,
    StarIcon,
    UserIcon,
    ClockIcon,
    GlobeAltIcon,
    GlobeEuropeAfricaIcon,
    MapPinIcon,
} from '@heroicons/react/24/outline';
import { i18n } from 'next-i18next';

import { BoolInput } from '@/app/crm/components/Property/PropertyForm/BoolInput';
import { DateInput } from '@/app/crm/components/Property/PropertyForm/DateInput';
import { FileInput } from '@/app/crm/components/Property/PropertyForm/FileInput';
import { MultiSelectInput } from '@/app/crm/components/Property/PropertyForm/MultiSelectInput';
import { RatingInput } from '@/app/crm/components/Property/PropertyForm/RatingInput';
import { SelectInput } from '@/app/crm/components/Property/PropertyForm/SelectInput';
import { TextInput } from '@/app/crm/components/Property/PropertyForm/TextInput';
import { FieldType, UniqueFieldName } from '@/app/crm/types';
import { track } from '@/core/tracking';

import { CSVIcon } from '../components/Icons/CSVIcon';
import MultiSelectIcon from '../components/Icons/MultiSelectIcon';
import SelectIcon from '../components/Icons/SelectIcon';
import StatusIcon from '../components/Icons/StatusIcon';
import { XLXSIcon } from '../components/Icons/XLXSIcon';
import { ZIPIcon } from '../components/Icons/ZIPIcon';
import { ADD_NEW_PROPERTY_LIST, NAME } from '../constants';
import { PropertyView } from '../types';

import type { AddPropertyListOption, SchemaProperty } from '../types';
import type { CRMFieldName, ExportFormat, ExportFormatItem } from '@/app/crm/types';
import type { HeroIconType } from '@/ui/types';

// Mappers

export const getPropertyDefaultIcon = (fieldType: FieldType) =>
    ({
        [FieldType.text]: Bars3BottomLeftIcon,
        [FieldType.select]: SelectIcon,
        [FieldType.selectMulti]: MultiSelectIcon,
        [FieldType.date]: CalendarIcon,
        [FieldType.file]: DocumentIcon,
        [FieldType.url]: LinkIcon,
        [FieldType.email]: AtSymbolIcon,
        [FieldType.phone]: PhoneIcon,
        [FieldType.number]: HashtagIcon,
        [FieldType.rating]: StarIcon,
        [FieldType.boolean]: CheckIcon,
    })[fieldType];

export const getTextPropertyIcon = (fieldType: FieldType): HeroIconType | undefined =>
    ({
        [FieldType.email]: EnvelopeIcon,
        [FieldType.url]: LinkIcon,
        [FieldType.phone]: PhoneArrowUpRightIcon,
    })[fieldType];

export const getFormPropertyInputComponent = (fieldType: FieldType) =>
    ({
        [FieldType.text]: TextInput,
        [FieldType.url]: TextInput,
        [FieldType.email]: TextInput,
        [FieldType.phone]: TextInput,
        [FieldType.number]: TextInput,
        [FieldType.select]: SelectInput,
        [FieldType.selectMulti]: MultiSelectInput,
        [FieldType.date]: DateInput,
        [FieldType.file]: FileInput,
    })[fieldType];

export const getPropertyCustomInputComponent = (fieldType: FieldType) =>
    ({
        [FieldType.rating]: RatingInput,
        [FieldType.boolean]: BoolInput,
    })[fieldType];

export const getPropertyFieldNameIcon = (fieldName: UniqueFieldName) =>
    ({
        name: UserIcon,
        firstName: UserIcon,
        lastName: UserIcon,
    })[fieldName];

export const getFilterIcon = (field: CRMFieldName) =>
    ({
        status: StatusIcon,
        person: UserIcon,
        name: UserIcon,
        email: AtSymbolIcon,
        firstName: UserIcon,
        lastName: UserIcon,
        createdAt: ClockIcon,
        phone: PhoneIcon,
        country: GlobeEuropeAfricaIcon,
        zip: MapPinIcon,
        street: MapPinIcon,
        city: MapPinIcon,
        website: GlobeAltIcon,
    })[field];

export const getCrmDownloadFormat = (): ExportFormatItem<ExportFormat>[] => [
    {
        format: 'csv',
        Icon: CSVIcon,
        descriptionTextId: 'export.format-csv',
    },
    {
        format: 'xlsx',
        Icon: XLXSIcon,
        descriptionTextId: 'export.format-xlsx',
    },
    {
        format: 'zip',
        Icon: ZIPIcon,
        descriptionTextId: 'export.format-zip',
    },
];

// Helpers

export const getIsMultilineText = (fieldType: FieldType, fieldName: string) => {
    if (fieldType !== FieldType.text) {
        return false;
    }

    const isSingleLineTextField =
        fieldType === FieldType.text &&
        ['address', UniqueFieldName.firstName, UniqueFieldName.lastName, UniqueFieldName.name].some(
            (field) => fieldName.startsWith(field),
        );

    if (isSingleLineTextField) {
        return false;
    }

    return true;
};

export const getPropertyVisibleFromView = (view: PropertyView) => (property: SchemaProperty) => {
    // @todo: Do not default to `property?.visible` for Kanban
    // Temp fix until backend migrates data to visibility
    if (view === PropertyView.kanban) {
        return !!property?.visibility?.[view];
    }

    // @todo: remove case property.visible, when backend migrates data to visibility
    return property?.visibility?.[view] ?? property?.visible;
};

export const getIsExemptedTableField = (fieldname: string) => {
    return [UniqueFieldName.firstName, UniqueFieldName.lastName, UniqueFieldName.name].includes(
        fieldname as UniqueFieldName,
    );
};

export const getAddPropertyListOptions = (
    handleAddProperty?: (propertyType: FieldType) => () => void,
): AddPropertyListOption[] =>
    ADD_NEW_PROPERTY_LIST.map((property) => ({
        type: property,
        label: i18n?.t(`${NAME}:property-${property}-label`) ?? '',
        icon: getPropertyDefaultIcon(property),
        handleClick: handleAddProperty?.(property),
    }));

export const trackPropertyVisibilitySet = (
    view: PropertyView,
    fieldName: string,
    visible: boolean,
) => {
    if (view === PropertyView.kanban) {
        track(TRACKING_EVENTS.crm.kanban.property.visibility, {
            property_visible: visible,
            crm_property_name: fieldName,
        });
    }
};

export const trackPropertyReordered = (view: PropertyView, fieldName: string) => {
    if (view === PropertyView.kanban) {
        track(TRACKING_EVENTS.crm.kanban.property.order, {
            crm_property_name: fieldName,
        });
    }
};

export const getFilteredTableProperties = (properties: SchemaProperty[]) => {
    return properties.filter((property) => {
        return property.fieldType && !getIsExemptedTableField(property.fieldName);
    });
};

export const getVisibleProperties = (properties: SchemaProperty[], view: PropertyView) => {
    return properties.filter(getPropertyVisibleFromView(view));
};

export const getTableVisibleProperties = (properties: SchemaProperty[]) => {
    const filteredProperties = getFilteredTableProperties(properties);

    return getVisibleProperties(filteredProperties, PropertyView.table);
};

export const getNextVisiblePropertyIndex = (
    properties: SchemaProperty[],
    columnOrder: string[],
    columnToInsert: string,
) => {
    const propertyIndex = properties.findIndex((prop) => prop.fieldName === columnToInsert);

    const slicedProperties = properties.slice(propertyIndex);
    const nearestVisibleProperty = slicedProperties.find((property) =>
        getPropertyVisibleFromView(PropertyView.table)(property),
    );

    return nearestVisibleProperty
        ? columnOrder.findIndex((column) => column === nearestVisibleProperty.fieldName)
        : columnOrder.length;
};
