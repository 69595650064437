import qs from 'query-string';

import type { MappedMedia, MediaType } from '@/app/mediaLibrary/types';

export const NAME = 'mediaLibrary';

export const IMAGES_PER_PAGE = 30; // Max is 30 because the Unsplash API only allows 30 images per page
export const MAX_IMAGE_FILE_SIZE = 10_000_000; // 10MB
export const MAX_VIDEO_FILE_SIZE = 2000_000_000; // 2GB
export const MAX_FILE_COUNT = 30;
export const UPLOAD_RESET_TIMEOUT_MS = 4_000;
export const UNSPLASH_UTM_NAME = 'perspective';
export const MAX_FOLDERS = 100;
export const RECENT_UNSPLASH_SEARCHES = 'perspective.recent-unsplash-searches';
export const MAX_UNSPLASH_RECENT_SEARCH = 5;
export const DESKTOP_CONTENT_MAX_WIDTH = 1440;
export const BRANDFETCH_CDN_URL = 'https://cdn.brandfetch.io';
export const SOFT_DELETE_TIMEOUT_MS = 5000;

export const FIXED_FOLDER_IDS = {
    all: 'all',
    unsplash: 'unsplash',
    brandfetch: 'brandfetch',
} as const;

export const FIXED_FOLDER_NAMES = {
    [FIXED_FOLDER_IDS.all]: 'all-media',
    [FIXED_FOLDER_IDS.unsplash]: 'Unsplash',
    [FIXED_FOLDER_IDS.brandfetch]: 'Brandfetch',
} as const;

export const IMAGE_SIZE_ASPECT_MAP = {
    small: 16 / 9,
    medium: 4 / 3,
    large: 5 / 4,
    xLarge: 1,
    answer: 4 / 3,
} as const;

export const ACCEPTED_IMAGE_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp'],
    'image/gif': ['.gif'],
};

export const ACCEPTED_VIDEO_FILE_TYPES = {
    'video/mp4': ['.mp4'],
    'video/x-msvideo': ['.avi'],
    'video/x-ms-wmv': ['.wmv'],
    'video/mpeg': ['.mpeg', '.mpeg2', '.mpeg4'],
    'video/webm': ['.webm'],
    'video/quicktime': ['.mov'],
};

export const ENDPOINTS = {
    GET: {
        folders: () => '/folders',
        folderMedia: ({
            folderId,
            pageParam = 0,
            searchTerm,
            type,
        }: {
            folderId: string;
            pageParam: number;
            searchTerm?: string;
            type?: MediaType;
        }) => {
            const filterQuery = qs.stringify({
                limit: IMAGES_PER_PAGE,
                page: pageParam,
                search: searchTerm ? searchTerm : undefined,
                folder: folderId === FIXED_FOLDER_IDS.all ? undefined : folderId,
                type,
            });

            return `/media?${filterQuery}`;
        },
        brandDetails: ({ domain }: { domain: string }) => `/brands/details/${domain}`,
        brandSearch: ({ searchTerm }: { searchTerm: string }) =>
            `/brands/search?query=${searchTerm}`,
        trackImageDownload: (imageId: string) =>
            `/photos/${imageId}/download?client_id=${process.env.NEXT_PUBLIC_UNSPLASH_APP_ID}`,
        mediaStatus: (mediaId: string) => `/media/${mediaId}/status`,
    },
    POST: {
        createFolder: () => '/folders',
        moveMedia: (mediaId: string, folderId: string) => `/media/${mediaId}/folder/${folderId}`,
        useMedia: (mediaId: string) => `/media/${mediaId}/use`,
        generateUploadUrl: () => '/media/url',
        createMediaResource: () => '/media',
    },
    PATCH: {
        updateFolder: (folderId: string) => `/folders/${folderId}`,
    },
    DELETE: {
        deleteFolder: (folderId: string) => `/folders/${folderId}`,
        deleteMedia: (mediaId: string) => `/media/${mediaId}`,
        removeMediaFromFolder: (mediaId: string, folderId: string) =>
            `/media/${mediaId}/folder/${folderId}`,
    },
} as const;

export const QUERY_KEYS = {
    folders: () => ['folders', 'list'],
    allFolderMedia: () => ['folder', 'media', 'list'],
    folderMedia: (folderId: string, mediaType?: MediaType, searchTerm?: string) => {
        const key = ['folder', 'media', 'list', folderId];

        if (searchTerm) {
            key.push(searchTerm);
        }

        if (mediaType) {
            key.push(mediaType);
        }

        return key;
    },
    unsplashImages: (searchTerm?: string) => {
        const key = ['unsplashImages'];

        if (searchTerm) {
            key.push(searchTerm);
        }

        return key;
    },
    brands: (searchTerm: string) => ['brands', 'list', searchTerm],
    brandImages: (brandDomain: string | null) => ['brandImages', brandDomain],
    mediaStatus: (mediaId: string) => ['media', 'status', mediaId],
} as const;

export const MUTATION_KEYS = {
    createFolder: () => ['folder', 'create'],
    updateFolder: (folderId: string) => ['folder', 'update', folderId],
    deleteFolder: (folderId: string) => ['folder', 'delete', folderId],
    deleteMedia: (folderId: string, mediaId: string) => ['media', 'delete', mediaId, folderId],
    moveMedia: () => ['media', 'move'],
    useMedia: () => ['media', 'use'],
    trackImageDownload: () => ['image', 'track-download'],
    uploadMedia: () => ['media', 'upload'],
} as const;

export const BRANDFETCH_PLACEHOLDER_LOGOS: MappedMedia[] = [
    {
        id: 'Google',
        type: 'imageBrandfetch',
        url: 'https://cdn.brandfetch.io/id6O2oGzv-/theme/dark/logo.svg',
        brandDomain: 'google.com',
    },
    {
        id: 'Nike',
        type: 'imageBrandfetch',
        url: 'https://cdn.brandfetch.io/id_0dwKPKT/theme/dark/logo.svg',
        brandDomain: 'nike.com',
    },
    {
        id: 'Coca Cola',
        type: 'imageBrandfetch',
        url: 'https://cdn.brandfetch.io/idftrGPfCd/theme/dark/logo.svg',
        brandDomain: 'cocacola.com',
    },
    {
        id: 'Apple',
        type: 'imageBrandfetch',
        url: 'https://cdn.brandfetch.io/idnrCPuv87/theme/dark/logo.svg',
        brandDomain: 'apple.com',
    },
    {
        id: 'Lego',
        type: 'imageBrandfetch',
        url: 'https://cdn.brandfetch.io/idmoRM4Wwz/w/400/h/400/theme/dark/icon.jpeg',
        brandDomain: 'lego.com',
    },
    {
        id: 'Braun',
        type: 'imageBrandfetch',
        url: 'https://cdn.brandfetch.io/idrq7oeNQD/w/820/h/347/theme/dark/logo.png',
        brandDomain: 'braun.com',
    },
];

export const IMGIX_VIDEO_THUMBNAIL_PARAM = { 'video-generate': 'thumbnail' };
