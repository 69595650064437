import { reset as resetActivities } from './activities';
import { reset as resetContacts } from './contacts';
import { reset as resetFileUploadsStatus } from './fileUploads';
import { reset as resetFilters } from './filters';
import { reset as resetKanban } from './kanban';
import { reset as resetKanbanDnd } from './kanbanDnd';
import { reset as resetSchema } from './schema';
import { reset as resetSidebar } from './sidebar';
import { reset as resetTable } from './table';
import { reset as resetWorkspaceStatus } from './workspaceStatus';
import { reset as resetWorkspaceStatusTemplates } from './workspaceStatusTemplates';
import { reset as resetWorkspaceUsers } from './workspaceUsers';

import type { AppThunk } from '@/core/redux/types';

export const resetCrmState = (): AppThunk => (dispatch) => {
    dispatch(resetSchema());
    dispatch(resetKanban());
    dispatch(resetKanbanDnd());
    dispatch(resetContacts());
    dispatch(resetActivities());
    dispatch(resetTable());
    dispatch(resetWorkspaceUsers());
    dispatch(resetSidebar());
    dispatch(resetFilters());
    dispatch(resetFileUploadsStatus());
    dispatch(resetWorkspaceStatusTemplates());
    dispatch(resetWorkspaceStatus());
};
