import { getCampaignFallbackUrl, getMostImportantDomainStatus } from '@/app/campaigns/helpers';

import type {
    CampaignResource,
    CampaignStatus,
    CampaignVersionResource,
} from '@/app/campaigns/types';
import type { DomainResource } from '@/app/domains/types';

/**
 * Evaluates which domain to use for the next publish action.
 *
 * If a draftPublishDomain is given and the campaign status is either
 * draft or building, then the draftPublishDomain is returned.
 *
 * Otherwise, if the status is draft and there is a verified defaultDomain is present,
 * then this defaultDomain is returned.
 *
 * Otherwise, if the latestVersionDomain is present, this latestVersionDomain is returned.
 *
 * Otherwise, undefined is returned. Undefined means "use the shared domain
 * provided by Perspective" (like perspectivefunnel.co).
 *
 * @param defaultDomains List of default domains.
 * @param campaignStatus Status of the campaign to be published.
 * @param draftPublishDomain Optional domain to be used for publishing a draft to live.
 * @param latestVersionDomain Optional domain of the latest published campaign version.
 * @return DomainResource to used (custom/instant domain) or undefined for default/public perspective domain (like perspectivefunnel.co).
 */
export const determinePublishDomain = ({
    defaultDomains,
    campaignStatus,
    draftPublishDomain,
    latestVersionDomain,
}: {
    defaultDomains: DomainResource[];
    campaignStatus: CampaignStatus;
    draftPublishDomain?: DomainResource;
    latestVersionDomain?: DomainResource;
}): DomainResource | undefined => {
    const defaultDomain = defaultDomains[0];
    const defaultDomainStatus = getMostImportantDomainStatus(defaultDomain);

    if ((campaignStatus === 'draft' || campaignStatus === 'building') && draftPublishDomain?.id) {
        return draftPublishDomain;
    }

    if (
        campaignStatus === 'draft' &&
        defaultDomain &&
        defaultDomain?.id &&
        defaultDomainStatus === 'verified'
    ) {
        return defaultDomain;
    }

    if (latestVersionDomain && latestVersionDomain.id) {
        return latestVersionDomain;
    }

    return undefined;
};

/**
 * Determines what the publish slug will be.
 *
 * If a non-null/non-undefined overridenPublishSlug is given, its value will be returned.
 *
 * If a latestCampaignVersion is given, the slug is returned from that version.
 *
 * If a domain is given (like a default custom domain), then only the campaign ID is returned.
 *
 * Otherwise, the fallback for shared domains (like perspectivefunnel.co)
 * will be returned. It has the following schema: "{companyId}/{campaignId}"
 *
 * @param campaign Campaign to determine publish slug for.
 * @param domain Optional publish domain. Undefined means "shared perspective domain".
 * @param latestCampaignVersion Optional latest campaign version.
 * @param overridenPublishSlug Optional slug to be used in favor of everything else (like setting a new slug).
 * @return Slug to be used. Empty slug for root pathname funnels.
 */
export const determinePublishSlug = ({
    campaign,
    domain,
    latestCampaignVersion,
    overridenPublishSlug,
}: {
    campaign: CampaignResource;
    domain?: DomainResource;
    latestCampaignVersion?: CampaignVersionResource;
    overridenPublishSlug?: string;
}): string => {
    if (!campaign) {
        return '';
    }

    if (typeof overridenPublishSlug === 'string') {
        return overridenPublishSlug;
    }

    if (typeof latestCampaignVersion?.attributes?.slug === 'string') {
        return latestCampaignVersion.attributes.slug;
    }

    if (domain) {
        return campaign.id;
    }

    return `${campaign.relationships.company.data?.id}/${campaign.id}`;
};

/**
 * Constructs the full URL for a campaign including the protocol.
 *
 * @param domain Optional domain. Undefined means "shared perspective domain". (like perspectivefunnel.co)
 * @param slug Optional slug. Undefined means campaign is hosted under the root pathname.
 * @param campaign Campaign resource.
 * @return Full campaign URL including protocol and slug.
 */
export const determineFullCampaignUrl = ({
    domain,
    slug,
    campaign,
}: {
    domain?: DomainResource;
    slug?: string;
    campaign: CampaignResource;
}): string => {
    if (domain?.id && slug) {
        return `https://${domain?.attributes?.url}/${slug}/`;
    }

    if (domain?.id && !slug) {
        return `https://${domain?.attributes?.url}/`;
    }

    return `https://${getCampaignFallbackUrl(campaign)}`;
};
