import { store } from '@/core/redux/store';

import { getIsAdmin } from '@/app/user/helper';
import { getUser } from '@/app/user/models/user';
import { buildUserTraits } from '@/core/tracking/helper';
import { infoLog } from '@/utils/debugLogs';
import { getIsLocalhostEnv } from '@/utils/environments';

import type { SubscriptionResource } from '@/app/billing/types';
import type { CompanyResource } from '@/app/company/types';
import type { UserResource } from '@/app/user/types';
import type { AllFeatureFlags } from '@/core/loli-feature-flags/types';
import type { TrackingObject, UserTraits } from '@/core/tracking/types';
import type { Language } from 'types/generic';

export const initSegment = () => {
    // only init in production env
    if (
        getIsLocalhostEnv() ||
        !process.env.NEXT_PUBLIC_SEGMENT_SOURCE_ID ||
        process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ) {
        infoLog('Segment: Not initiating in non-production environment');

        return;
    }

    // only init on client side
    if (typeof window === 'undefined' || !process.env.NEXT_PUBLIC_SEGMENT_SOURCE_ID) {
        infoLog('Segment: Not initiating on server');

        return;
    }

    // Init segment
    /* eslint-disable */
    (function () {
        const analytics = (window.analytics = window.analytics || []);

        if (!analytics.initialize) {
            if (analytics.invoked) {
                window.console && console.error && console.error('Segment snippet included twice.');
            } else {
                analytics.invoked = !0;
                analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                ];
                analytics.factory = function (t) {
                    return function () {
                        const e = Array.prototype.slice.call(arguments);
                        e.unshift(t);
                        analytics.push(e);

                        return analytics;
                    };
                };
                for (let t = 0; t < analytics.methods.length; t++) {
                    const e = analytics.methods[t];
                    analytics[e] = analytics.factory(e);
                }
                analytics.load = function (t, e?: any) {
                    const n = document.createElement('script');
                    n.type = 'text/javascript';
                    n.async = !0;
                    n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
                    const a = document.getElementsByTagName('script')[0];
                    a?.parentNode?.insertBefore(n, a);
                    // eslint-disable-next-line no-underscore-dangle
                    analytics._loadOptions = e;
                };
                analytics.SNIPPET_VERSION = '4.1.0';
                analytics.load(process.env.NEXT_PUBLIC_SEGMENT_SOURCE_ID);
            }
        }
    })();
    /* eslint-enable */
};

export const segmentIdentify = (user: UserTraits, isAdmin: boolean, language: Language) => {
    if (window?.analytics) {
        window.analytics.identify(
            user.id,
            {
                ...user,
                // Not supported as an intercom Setting, so it has to be set as a user property
                language_override: language,
            },
            {
                All: true,
                Intercom: isAdmin ? { hideDefaultLauncher: true } : false,
            },
        );

        window.analytics.group(user.company.id, user.company, {
            All: true,
            Intercom: isAdmin,
        });
    }
};

export const identifySegmentUser = (
    user: UserResource,
    company: CompanyResource,
    subscription: SubscriptionResource,
    language: Language,
    allFeatureFlags: AllFeatureFlags,
) => {
    const isAdmin = getIsAdmin(user);
    const userTraits = buildUserTraits(user, company, subscription, allFeatureFlags);

    if (
        process.env.NODE_ENV === 'production' &&
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ) {
        segmentIdentify(userTraits, isAdmin, language);
    } else {
        infoLog('Segment: Not identifying user in non-production environment', userTraits);

        return;
    }
};

export const trackSegment = (eventName: string, trackingObj: TrackingObject) => {
    if (window?.analytics) {
        const isAdmin = getIsAdmin(getUser(store?.getState()));

        window.analytics.track(eventName, trackingObj, {
            All: true,
            Intercom: isAdmin,
        });
    }
};
