import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';

import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import { usePerspectiveEditorEngine } from '@/app/editor/engine/PerspectiveEditorEngine';
import IconLibrary from '@/app/editor/iconLibrary/components/Library';
import { downloadIcon } from '@/app/editor/iconLibrary/models/icons';
import { getPlatforms } from '@/app/editor/iconLibrary/models/platforms';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { getBlockById } from '../../models/blocks';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { Icon } from '@/app/editor/iconLibrary/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const iconSizeMap = {
    S: { value: 'small' },
    M: { value: 'regular' },
    L: { value: 'large' },
    XL: { value: 'title2' },
};

const Form: FC<Props & InjectedFormProps> = ({
    blockId,
    handleSubmit,
    activeTheme,
    initialValues,
}) => {
    const { isActive: isWithinEditorEngine, actions } = usePerspectiveEditorEngine();
    const { t } = useTranslation(NAME);
    const { isInColumn } = useLayoutInfo(blockId);

    const dispatch = useAppDispatch();

    const block = useAppSelector((state) => getBlockById(state, blockId));
    const blockIconId = block?.attributes?.content?.icon;
    const blockPlatform = block?.attributes?.content?.platform;

    const platforms = useAppSelector(getPlatforms);
    const isColoredIcon = useMemo(() => {
        const platform = platforms.find((platform) => platform.value === blockPlatform);

        return platform?.isColor ?? false;
    }, [platforms, blockPlatform]);

    const handleIconSelect = (icon: Icon) => {
        dispatch(
            downloadIcon(
                icon,
                blockId,
                isWithinEditorEngine
                    ? (values) => {
                          actions.changeBlock.enqueue({ values });
                      }
                    : undefined,
            ),
        );
    };

    return (
        <>
            <Field
                name="attributes.content.size"
                component={GenericSize}
                submit={handleSubmit}
                valueMap={iconSizeMap}
            />

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.color"
                    component={Color}
                    expand="right"
                    submit={handleSubmit}
                    themeColor={getThemeColor(activeTheme, 'fontColor')}
                    tooltip={!isColoredIcon ? t('icon-color') : t('icon-color-disabled')}
                    disabled={isColoredIcon}
                />
                {!isInColumn && (
                    <Field
                        name="attributes.content.box.backgroundColor"
                        component={Color}
                        expand="center"
                        submit={handleSubmit}
                        themeColor={getThemeColor(activeTheme, 'backgroundColor')}
                        tooltip={t('background-color')}
                    />
                )}
            </div>

            <div className="mt-6">
                <IconLibrary
                    initialPlatform={initialValues?.attributes?.content?.platform}
                    blockIconId={blockIconId}
                    onIconSelect={handleIconSelect}
                />
            </div>
        </>
    );
};

Form.displayName = 'IconEditForm';

export default reduxForm({})(Form);
