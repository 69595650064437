import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

interface Props {
    tooltip: string;
    className?: string;
}

export const WarningPing = ({ tooltip, className }: Props) => {
    return (
        <Tooltip content={tooltip}>
            <div
                className={cn(
                    'absolute -right-3 -top-3 flex size-6 items-center justify-center',
                    className,
                )}
            >
                <span className="absolute inline-flex size-5 animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="absolute flex size-full items-center justify-center rounded-full bg-red-500">
                    <ExclamationCircleIcon className="z-10 size-4 text-white" />
                </span>
            </div>
        </Tooltip>
    );
};
