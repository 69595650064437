import {
    ArchiveBoxIcon,
    LinkIcon,
    PencilSquareIcon,
    PowerIcon,
    ArrowUpOnSquareIcon,
    Squares2X2Icon,
    StarIcon,
} from '@heroicons/react/24/outline';

import { CampaignFilter } from '@/app/campaigns/types';

import type { CampaignQuery, CookieSettings, CampaignOrder } from '@/app/campaigns/types';

export const NAME = 'campaigns';

export const ENDPOINTS = {
    GET: {
        campaign: (campaignId: string) => `/campaigns/${campaignId}`,
        filter: () => '/campaigns/filter',
    },
    PATCH: {
        updateCampaign: (campaignId: string) => `/campaigns/${campaignId}`,
    },
    POST: {
        archiveCampaign: (campaignId: string) => `/campaigns/${campaignId}/archive`,
        duplicateCampaign: (campaignId: string) => `/campaigns/${campaignId}/duplicate`,
        restoreCampaign: (campaignId: string) => `/campaigns/${campaignId}/restore`,
        moveCampaigns: () => '/workspaces/move-campaigns',
    },
    DELETE: {
        deleteCampaign: (campaignId: string) => `/campaigns/${campaignId}`,
    },
} as const;

export const QUERY_KEYS = {
    campaigns: ({
        filter,
        search,
        workspaceId,
        page,
        crmCampaignsOnly,
        order,
    }: {
        filter: string;
        search: string | null;
        workspaceId: string;
        page: string;
        crmCampaignsOnly: boolean;
        order: CampaignOrder;
    }) => {
        const isSearching = search && search.length > 0;
        const filterQK = isSearching
            ? CampaignFilter.search
            : ((filter as string) ?? CampaignFilter.all);
        const searchQK = isSearching ? (search as string) : null;
        const pageQK = (page as string) || '1';
        const crmCampaignsOnlyQK = crmCampaignsOnly || false;
        const orderQK = (order as CampaignOrder) || '-isFav';

        return ['campaigns', filterQK, searchQK, workspaceId, pageQK, crmCampaignsOnlyQK, orderQK];
    },
    campaign: (campaignId: string) => ['campaign', campaignId],
};

export const MUTATION_KEYS = {
    updateCampaign: (campaignId: string) => ['campaign', 'update', campaignId],
    favoriteCampaign: (campaignId: string) => ['campaign', 'favorite', campaignId],
    archiveCampaign: (campaignId: string) => ['campaign', 'archive', campaignId],
    restoreCampaign: (campaignId: string) => ['campaign', 'restore', campaignId],
    duplicateCampaign: (campaignId: string) => ['campaign', 'duplicate', campaignId],
    deleteCampaign: (campaignId: string) => ['campaign', 'delete', campaignId],
    moveCampaign: (campaignId: string) => ['campaign', 'move', campaignId],
    renameCampaign: (campaignId: string) => ['campaign', 'rename', campaignId],
} as const;

export const ROUTES = {
    editor: (campaignId: string) => `/funnel/${campaignId}`,
    publish: (campaignId: string) => `/funnel/${campaignId}/publish`,
    embed: (campaignId: string) => `/funnel/${campaignId}/embed`,
} as const;

export const CAMPAIGN_LIST_DISPLAY_MODE_KEY = 'campaign-list-displaymode';

export const CAMPAIGN_SLUG_FORM = 'campaignSlugForm';

export const CREATE_FUNNEL_BUTTON_ID = 'CREATE_FUNNEL_BUTTON_ID';

export const PUBLISH_HELP_ARTICLE_URL =
    'https://intercom.help/perspective-funnels/de/articles/4065677-warum-kann-ich-meinen-funnel-nicht-veroffentlichen';
export const PUBLISH_HELP_ARTICLE_URL_EN =
    'https://intercom.help/perspective-funnels/en/articles/4065677-why-can-t-i-publish-my-funnel';

export const DUPLICATION_LEARN_MORE_ARTICLE_URL_DE =
    'https://intercom.help/perspective-funnels/de/articles/8291054-upgrade-deine-funnel-von-v3-auf-v4';
export const DUPLICATION_LEARN_MORE_ARTICLE_URL_EN =
    'https://intercom.help/perspective-funnels/en/articles/8291054-upgrade-your-v3-funnel-to-v4';

export const FUNNEL_EMBED_LEARN_MORE_ARTICLE_URL_DE =
    'https://intercom.help/perspective-funnels/de/articles/9076143-ihren-funnel-einbetten';
export const FUNNEL_EMBED_LEARN_MORE_ARTICLE_URL_EN =
    'https://intercom.help/perspective-funnels/en/articles/9076143-embed-your-funnel';

export const CAMPAIGN_STATUS = {
    live: 'live',
    building: 'building',
    offline: 'offline',
    draft: 'draft',
};

const DEFAULT_FILTER_QUERY_OPTIONS = {
    page: 1,
    limit: 15,
};

export const FILTER_QUERY_MAP: { [key: string]: CampaignQuery } = {
    recent: { ...DEFAULT_FILTER_QUERY_OPTIONS, status: 'draft,live', order: '-updatedAt' },
    all: { ...DEFAULT_FILTER_QUERY_OPTIONS, status: 'draft,live,offline' },
    fav: { ...DEFAULT_FILTER_QUERY_OPTIONS, fav: 'true', order: '-isFav' },
    live: { ...DEFAULT_FILTER_QUERY_OPTIONS, status: 'live' },
    draft: { ...DEFAULT_FILTER_QUERY_OPTIONS, status: 'draft' },
    archived: { ...DEFAULT_FILTER_QUERY_OPTIONS, archived: 'true' },
    shared: { ...DEFAULT_FILTER_QUERY_OPTIONS, shared: 'true' },
    offline: { ...DEFAULT_FILTER_QUERY_OPTIONS, status: 'offline' },
    search: {
        ...DEFAULT_FILTER_QUERY_OPTIONS,
        status: 'draft,live,offline',
        order: '-updatedAt',
        archived: 'false,true',
        shared: 'false,true',
        fav: 'false,true',
    },
};

export const CAMPAIGN_STATUS_MAP = {
    live: {
        text: 'live',
        color: 'success',
        textColor: 'text-green-500',
    },
    building: {
        text: 'building',
        color: 'success',
        textColor: 'text-yellow-500',
    },
    draft: {
        text: 'draft',
        color: 'grey',
        textColor: 'text-gray-500',
    },
    offline: {
        text: 'offline',
        color: 'danger',
        textColor: 'text-red-600',
    },
    error: {
        text: 'error',
        color: 'danger',
        textColor: 'text-red-600',
    },
    unknown: {
        text: 'unknown',
        color: 'grey',
        textColor: 'text-gray-500',
    },
};

export const CAMPAIGN_STATUS_KEYS = Object.keys(CAMPAIGN_STATUS_MAP);

export const CAMPAIGN_FILTER_OPTIONS = [
    {
        label: 'filter-all',
        value: CampaignFilter.all,
        icon: Squares2X2Icon,
    },
    {
        label: 'filter-fav',
        value: CampaignFilter.fav,
        icon: StarIcon,
    },
    {
        label: 'filter-draft',
        value: CampaignFilter.draft,
        icon: PencilSquareIcon,
    },
    {
        label: 'filter-live',
        value: CampaignFilter.live,
        icon: LinkIcon,
    },
    {
        label: 'filter-offline',
        value: CampaignFilter.offline,
        icon: PowerIcon,
    },
    {
        label: 'filter-archived',
        value: CampaignFilter.archived,
        icon: ArchiveBoxIcon,
    },
    {
        label: 'filter-shared',
        value: CampaignFilter.shared,
        icon: ArrowUpOnSquareIcon,
    },
];

export const CAMPAIGN_SUBHEADLINE_STATES = {
    LIVE: 'live',
    BUILDING_ESTIMATE: 'building_estimate',
    BUILDING_TAKING_TOO_LONG: 'building_taking_too_long',
    BUILDING_PROGRESS: 'building_progress',
    DRAFT: 'draft',
    OFFLINE: 'offline',
    SHARED: 'shared',
};

export const CAMPAIGN_DELETION_REMINDER_LS_KEY = 'campaign-deletion-reminder';

// NPS
export const DUPLICATION_CONFIRMATION_HIDDEN = 'campaign.duplication-confirmation-hidden';

export const DOMAIN_WARNING_TOOLTIP_KEYS = {
    inProgress: 'unverified tooltip',
    unverified: 'unverified tooltip',
    verifying: 'verifying tooltip',
    splitTestingInstantDomain: 'split testing instant domain tooltip',
    splitTestingCustomDomain: 'split testing legacy domain tooltip',
};

export const DEFAULT_COOKIE_SETTINGS: CookieSettings = {
    layout: 'none',
    language: 'en',
};

export const NON_LEGACY_DOMAIN_TYPES = ['domainInstant', 'domainCloudflare'];

export const V3_SHARING_WARNING_BANNER_KEY =
    'perspective.workspaces.v3-sharing-warning-has-seen-banner';

export const REGULAR_PLAN_IDS = [
    'ps-business-1-month',
    'ps-business-12-month',
    'ps-pro-1-month',
    'ps-pro-12-month',
    'ps-agency-1-month',
    'ps-agency-12-month',
    // USD plans
    'ps-business-USD-1-month',
    'ps-business-USD-12-month',
    'ps-pro-USD-1-month',
    'ps-pro-USD-12-month',
    'ps-agency-USD-1-month',
    'ps-agency-USD-12-month',
    // GBP plans
    'ps-business-GBP-1-month',
    'ps-business-GBP-12-month',
    'ps-pro-GBP-1-month',
    'ps-pro-GBP-12-month',
    'ps-agency-GBP-1-month',
    'ps-agency-GBP-12-month',
];
