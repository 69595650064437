import { DEFAULT_BOX, NAME } from '@/app/editor/blocks/constants';

import { ChevronDownIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { getCampaignCookieSettings } from '@/app/campaigns/helpers';
import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { MastercardIcon } from '@/app/editor/blocks/components/PaymentForm/icons/MastercardIcon';
import { StripeIcon } from '@/app/editor/blocks/components/PaymentForm/icons/StripeIcon';
import { VisaIcon } from '@/app/editor/blocks/components/PaymentForm/icons/VisaIcon';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getColorByLightness, getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { WarningPing } from '@/ui/components/WarningPing';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';
import Text from '../Text/Component';

import type { DefaultBlockComponentProps } from '../../types';
import type { JSONContent } from '@tiptap/core';

export interface Props extends DefaultBlockComponentProps {
    wysiwyg: JSONContent;
    color: string;
    currency: string;
    amountInText: string;
    status: 'complete' | 'incomplete';
}

const Component = ({
    artBoardIndex,
    blockId,
    box = DEFAULT_BOX,
    isDragged,
    isPreview,
    wysiwyg,
    activeTheme,
    color,
    currency,
    amountInText,
    status = 'incomplete',
}: Props) => {
    const { t } = useTranslation(NAME);
    const { isInColumn } = useLayoutInfo(blockId, isPreview);
    const campaign = useAppSelector(getActiveCampaign);

    const cookieSettings = getCampaignCookieSettings(campaign);
    const cookieLanguage = cookieSettings.language;

    const btnBorderRadiusClass = useBorderRadius('button');
    const btnText =
        cookieLanguage === 'en'
            ? `Pay ${amountInText}${currency === 'eur' ? '€' : '$'} now`
            : `Jetzt ${amountInText}${currency === 'eur' ? '€' : '$'} bezahlen`;
    const btnStyles = useMemo(() => {
        const backgroundColor = getThemedOrCustomColor(color, 'buttonBackgroundColor', activeTheme);

        return {
            backgroundColor,
            color: getColorByLightness('transparent', backgroundColor, true),
        };
    }, [activeTheme, color]);

    const inputBorderRadiusClass = useBorderRadius('fieldsWrapper');

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            {/*Question Text*/}
            <Text
                align="center"
                blockId={blockId}
                wysiwyg={wysiwyg}
                color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
            />

            {/*Fake Inputs*/}
            <div className="relative mt-4 grid w-full grid-cols-4 gap-4 text-gray-400">
                <div
                    className={cn(
                        'col-span-full flex h-16 items-center justify-between border bg-white px-4 font-sans',
                        inputBorderRadiusClass,
                    )}
                >
                    <span>{cookieLanguage === 'en' ? 'Card number' : 'Kartennummer'}</span>
                    <div className="flex items-center gap-1">
                        <div className="rounded-md border p-1">
                            <MastercardIcon />
                        </div>
                        <div className="rounded-md border p-1">
                            <VisaIcon />
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        'col-span-2 flex h-16 items-center border bg-white px-4 font-sans',
                        inputBorderRadiusClass,
                    )}
                >
                    MM / YY
                </div>
                <div
                    className={cn(
                        'col-span-2 flex h-16 items-center justify-between border bg-white px-4 font-sans',
                        inputBorderRadiusClass,
                    )}
                >
                    <span>CVC</span>
                    <InformationCircleIcon className="size-5" />
                </div>
                <div
                    className={cn(
                        'col-span-full flex h-16 items-center justify-between border bg-white px-4',
                        inputBorderRadiusClass,
                    )}
                >
                    <div className="font-sans">
                        <p className="text-xs font-medium text-gray-500">
                            {cookieLanguage === 'en' ? 'Country' : 'Land'}
                        </p>
                        <p className="font-medium leading-none text-gray-800">
                            {cookieLanguage === 'en' ? 'United States' : 'Deutschland'}
                        </p>
                    </div>

                    <ChevronDownIcon className="size-6" />
                </div>

                {/* Connect warning */}
                {status === 'incomplete' && !isPreview && (
                    <WarningPing tooltip={t('payment.stripe.warning')} />
                )}

                {/* Button */}
                <div
                    className={cn(
                        'col-span-full p-4 text-center font-semibold text-white shadow-button-inset',
                        btnBorderRadiusClass,
                    )}
                    style={btnStyles}
                >
                    {btnText}
                </div>

                <div className="col-span-full flex items-center justify-center gap-1.5 px-4 text-sm">
                    <LockClosedIcon className="size-3" />
                    <span>{cookieLanguage === 'en' ? 'Secured by' : 'Gesichert von'}</span>
                    <StripeIcon className="mt-0.5" />
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'PaymentForm';

export default Component;
