import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { fetchBoot } from '@/app/auth/models/boot';
import { fetchLoginWithCredentials } from '@/app/auth/models/login';
import { showToast } from '@/app/toasts/utils/showToast';
import { AccountLoginScreens, RequestState } from '@/app/workspaces/types';
import { handleRuntimeError } from '@/core/api';

import { dataFetchWorkspaceByDomain } from './workspaces';
import { NAME } from '../constants';
import { resetPasswordRequest } from '../helpers/apis';
import { getWorkspaceDomainFromUrl } from '../helpers/utils';

import type { FetchLoginBody } from '@/app/auth/types';
import type { WorkspaceResource } from '@/app/workspaces/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

interface State {
    loginError: string | null;
    passwordResetError: string | null;
    workspace: WorkspaceResource | null;
    currentStep: AccountLoginScreens;
    requestState: Partial<
        Record<'workspaceLogin' | 'workspacePasswordReset' | 'initWorkspace', RequestState>
    >;
}

const initialState: State = {
    loginError: null,
    passwordResetError: null,
    workspace: null,
    currentStep: AccountLoginScreens.Login,
    requestState: {
        initWorkspace: RequestState.Idle,
        workspaceLogin: RequestState.Idle,
        workspacePasswordReset: RequestState.Idle,
    },
};

export const workspaceLoginSlice = createSlice({
    name: `${NAME}/workspaceLogin`,
    initialState,
    reducers: {
        setCurrentLoginStep(state, action: PayloadAction<AccountLoginScreens>) {
            state.currentStep = action.payload;
        },
        setWorkspace(state, action: PayloadAction<WorkspaceResource>) {
            state.workspace = action.payload;
        },
        setLoginError(state, action: PayloadAction<string>) {
            state.loginError = action.payload;
        },
        setPasswordResetError(state, action: PayloadAction<string | null>) {
            state.passwordResetError = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setCurrentLoginStep,
    setWorkspace,
    setLoginError,
    setPasswordResetError,
    setRequestState,
    reset,
} = workspaceLoginSlice.actions;

// === Selectors ======

export const getLoginCurrentStep = (state: AppState) =>
    state[NAME].workspaceLoginReducer.currentStep;

export const getWorkspace = (state: AppState) => state[NAME].workspaceLoginReducer.workspace;

export const getLoginError = (state: AppState) => state[NAME].workspaceLoginReducer.loginError;

export const getPasswordResetError = (state: AppState) =>
    state[NAME].workspaceLoginReducer.passwordResetError;

export const getRequestState = (state: AppState) => state[NAME].workspaceLoginReducer.requestState;

// === Thunk ======

export const initWorkspace =
    (domain: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                initWorkspace: RequestState.InProgress,
            }),
        );

        try {
            const workspace = await dataFetchWorkspaceByDomain(domain);

            dispatch(setWorkspace(workspace));

            dispatch(
                setRequestState({
                    initWorkspace: RequestState.Success,
                }),
            );
        } catch (err) {
            dispatch(
                setRequestState({
                    initWorkspace: RequestState.Error,
                }),
            );
        }
    };

export const handleLoginFailure =
    (status: number): AppThunk =>
    (dispatch) => {
        const isAuthError = status.toString().startsWith('4');
        const isNetworkError = status.toString().startsWith('5');

        dispatch(
            setRequestState({
                workspaceLogin: RequestState.Error,
            }),
        );

        if (isNetworkError) {
            handleRuntimeError(
                { message: 'Workspace login failed' } as AxiosError<{ message: string }>,
                {
                    debugMessage: 'workspace login failed:',
                },
            );
        }

        dispatch(
            setLoginError(isAuthError ? 'workspace-login-auth-error' : 'workspace-server-error'),
        );
    };

export const handleLoginSuccess = (): AppThunk => async (dispatch) => {
    let workspace: WorkspaceResource;

    try {
        const workspaceDomain = getWorkspaceDomainFromUrl();

        if (!workspaceDomain) {
            throw new Error('Workspace domain could not be extracted from URL');
        }

        workspace = await dataFetchWorkspaceByDomain(workspaceDomain);
        workspace?.id && dispatch(setWorkspace(workspace));
    } catch (error) {
        handleRuntimeError(error, { debugMessage: 'Workspace fetch by domain failed:' });
    }

    await dispatch(fetchBoot());

    // Redirect
    void Router.push('/');
};

export const handleWorkspaceLogin =
    (credentials: FetchLoginBody, handlePostLogin?: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                workspaceLogin: RequestState.InProgress,
            }),
        );

        const status = await dispatch(
            fetchLoginWithCredentials(credentials, { useLemonTree: true, triggerPostLogin: false }),
        );

        const isSuccess = status.toString().startsWith('2');

        if (isSuccess) {
            handlePostLogin && (await dispatch(handleLoginSuccess()));

            dispatch(
                setRequestState({
                    workspaceLogin: RequestState.Success,
                }),
            );

            return;
        }

        dispatch(
            setRequestState({
                workspaceLogin: RequestState.Error,
            }),
        );

        dispatch(handleLoginFailure(status));
    };

export const handleWorkspacePasswordReset =
    (email: string): AppThunk =>
    async (dispatch) => {
        dispatch(setPasswordResetError(null));
        dispatch(
            setRequestState({
                workspacePasswordReset: RequestState.InProgress,
            }),
        );

        try {
            await resetPasswordRequest(email);

            dispatch(
                setRequestState({
                    workspacePasswordReset: RequestState.Success,
                }),
            );

            showToast({
                message: `${NAME}:password-reset-success-label`,
                description: `${NAME}:password-reset-success-description`,
                type: 'success',
                duration: 8000,
            });
        } catch (err) {
            const status = err?.response?.status;
            const isAuthError = status.toString().startsWith('4');
            const isNetworkError = status.toString().startsWith('5');

            dispatch(
                setRequestState({
                    workspacePasswordReset: RequestState.Error,
                }),
            );

            if (isNetworkError) {
                handleRuntimeError(err as AxiosError<{ message: string }>, {
                    debugMessage: 'Workspace password reset failed:',
                });
            }

            dispatch(
                setPasswordResetError(
                    isAuthError ? 'workspace-password-reset-auth-error' : 'workspace-server-error',
                ),
            );
        }
    };

export default workspaceLoginSlice.reducer;
