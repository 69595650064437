import * as Select from '@radix-ui/react-select';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import { SelectContent } from '../_wrappers/SelectContent';
import { SelectOption } from '../_wrappers/SelectOption';
import { SelectTrigger } from '../_wrappers/SelectTrigger';

import type { DropdownCategory, DropdownOption } from '@/ui/types';
import type { ComponentProps, ReactNode } from 'react';

export type CategorizedDropdownButtonIcon =
    | ((props: ComponentProps<'svg'>) => ReactNode)
    | ((props: ComponentProps<'span'>) => ReactNode);

export interface Props {
    categories: DropdownCategory[];
    value: string;
    onChange: (value: string) => void;
    dropdownClass?: string;
    buttonClass?: string;
    placeholder?: string;
    buttonIcon?: CategorizedDropdownButtonIcon;
}

const CategorizedDropdown = ({
    categories,
    value,
    onChange,
    dropdownClass,
    buttonClass,
    placeholder,
    buttonIcon,
}: Props) => {
    const ButtonIcon = buttonIcon;

    const selectedOption: DropdownOption | undefined = useMemo(() => {
        for (const category of categories) {
            for (const option of category.options) {
                if (option.value === value) {
                    return option;
                }
            }
        }
    }, [categories, value]);

    return (
        <Select.Root value={value} onValueChange={onChange}>
            <SelectTrigger
                selectedOption={selectedOption}
                buttonClass={buttonClass}
                placeholder={placeholder}
            >
                {ButtonIcon && (
                    <ButtonIcon className="mr-3 flex size-5 min-w-5 flex-row items-center justify-center text-base font-medium leading-none" />
                )}
            </SelectTrigger>

            <SelectContent dropdownClass={dropdownClass} hasPortal>
                {categories.map((category, index) => {
                    const generatedKey = nanoid();

                    return (
                        <Select.Group
                            className={index >= 1 ? 'mt-2' : ''}
                            key={category.name || generatedKey}
                        >
                            {category.name && (
                                <p className="px-3 py-2 text-sm font-semibold leading-6">
                                    {category.name}
                                </p>
                            )}
                            {category.options.map((option, index) => (
                                <SelectOption option={option} key={`${option.key}-${index}`} />
                            ))}
                        </Select.Group>
                    );
                })}
            </SelectContent>
        </Select.Root>
    );
};

export default CategorizedDropdown;
