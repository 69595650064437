import { NAME } from '@/app/editor/blocks/constants';
import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import { CreditCardIcon, LinkIcon } from '@heroicons/react/24/outline';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { Field, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { usePaymentAccountConnection } from '@/app/editor/blocks/hooks/mutations/usePaymentAccountConnection';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import {
    CurrencyDropdown,
    DEFAULT_CURRENCY_CODE,
} from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CurrencyDropdown';
import Linking from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking';
import { TextInput } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextInput';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { usePerspectiveNodeManager } from '@/app/editor/engine/hooks/usePerspectiveNodeManager';
import { Button } from '@/ui/components/Button';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FormErrors, InjectedFormProps } from 'redux-form';

type Props = DefaultEditFormProps & InjectedFormProps;

const amountRegex = /^\d{1,3}(?:[.,]\d{3})*[.,]\d{2}$/;

const validate = (values: BlockResource): FormErrors => {
    const errors: FormErrors<BlockResource, any> = {
        attributes: {
            content: {},
        },
    };

    const amountInText = get(values, 'attributes.content.amountInText', '');

    if (!amountInText) {
        errors.attributes.content.amountInText = 'required';
    }

    if (!!amountInText && !amountRegex.test(amountInText)) {
        errors.attributes.content.amountInText = 'payment.validation.invalid-amount';
    }

    return errors;
};

const Form = ({ handleSubmit, blockId, activeTheme, pageId, campaignId }: Props) => {
    const { t } = useTranslation(NAME);
    const { isInColumn } = useLayoutInfo(blockId);

    const { resolveVirtualId } = usePerspectiveNodeManager();

    // We always need the real block ID to connect to stripe, not the virtual one if there is one (e.g. after adding the block)
    const blockIdToConnect = resolveVirtualId(blockId);

    const { mutate: connectPaymentAccount, isPending: isConnecting } =
        usePaymentAccountConnection(blockIdToConnect);

    const handleConnectClick = async () => {
        if (!isConnecting) {
            await connectPaymentAccount();
        }
    };

    return (
        <>
            <div className="mb-2 mt-4">
                <Field
                    name="attributes.content.amountInText"
                    component={TextInput}
                    onBlurSubmit={handleSubmit}
                    placeholder="29,90"
                    icon={CreditCardIcon}
                    parse={(value: string) => value.replace(/[^0-9.,]/g, '')}
                />
            </div>
            <div className="mb-2">
                <Field
                    name="attributes.content.currency"
                    component={CurrencyDropdown}
                    onChangeSubmit={handleSubmit}
                    defaultValue={DEFAULT_CURRENCY_CODE}
                />
            </div>
            <div className="mb-2">
                <Button
                    loading={isConnecting}
                    className="text-sm font-normal md:w-full"
                    variant="primary"
                    type="button"
                    onClick={handleConnectClick}
                >
                    <LinkIcon className="mr-2 size-5" /> {t('payment.stripe.connect')}
                </Button>
            </div>

            <Subheading text={t('linking')} helpTooltip={HELP_TOOLTIP_CONTENT.EDIT_FORM_LINKING} />

            <div className="mt-4">
                <Field
                    name="attributes.content.linking"
                    component={Linking}
                    pageId={pageId}
                    campaignId={campaignId}
                    submit={handleSubmit}
                    blockId={blockId}
                />
            </div>

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                hiddenColor={isInColumn}
            />
        </>
    );
};

export const PaymentFormForm = reduxForm({
    validate,
})(Form);
