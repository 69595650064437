import { SPLIT_TESTING_MODAL_KEY } from '@/app/analytics/constants';
import { ROUTES } from '@/app/campaigns/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';

import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import omit from 'lodash/omit';
import Router from 'next/router';

import { fetchCampaign } from '@/app/campaigns/models/campaigns';
import { setPageBlockOrder } from '@/app/editor/blocks/models/blockOrder';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';
import { LocalStorageValue } from 'types/generic';

import { setActivePage, setPage } from './pages';
import { NAME } from '../constants';

import type { PageResource } from '../types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    creatingPageVariantId: string;
    pageVariant: {
        [campaignId: string]: PageResource;
    };
}

const initialState: State = {
    creatingPageVariantId: '',
    pageVariant: EMPTY_OBJECT,
};

export const variantSlice = createSlice({
    name: `editor/${NAME}/variant`,
    initialState,
    reducers: {
        setCreatingPageVariantId(state, action: PayloadAction<string>) {
            return {
                ...state,
                creatingPageVariantId: action.payload,
            };
        },
        addPageVariant(
            state,
            action: PayloadAction<{
                campaignId: string;
                pageVariant: PageResource;
            }>,
        ) {
            return {
                ...state,
                pageVariant: {
                    ...state.pageVariant,
                    [action.payload.campaignId]: action.payload.pageVariant,
                },
            };
        },
        setPageVariant(state, action: PayloadAction<{ [campaignId: string]: PageResource }>) {
            return {
                ...state,
                pageVariant: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setCreatingPageVariantId, addPageVariant, setPageVariant, reset } =
    variantSlice.actions;

// === Selectors ======

export const getCreatingPageVariantId = (state: AppState) =>
    state[NAME]?.variantPageReducer?.creatingPageVariantId;

export const getPageVariant = (state: AppState) => state[NAME]?.variantPageReducer?.pageVariant;

export const getPageVariantByCampaignId = (state: AppState, campaignId: string) =>
    get(state[NAME], `variantPageReducer.pageVariant[${campaignId}]`);

// === Thunks ======

export const createPageVariant = (
    pageId: string,
    navigateToVariant = false,
    changeRoute = true,
): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setCreatingPageVariantId(pageId));

            const response = await apiPost<ResponseData<PageResource>>(
                `/pages/${pageId}/variant`,
                {},
            );

            const pageVariant = getDataFromResponse(response);
            const campaignId = get(pageVariant, 'relationships.campaign.data.id', '');

            if (!campaignId) {
                throw new Error('Campaign id not found');
            }

            dispatch(addPageVariant({ pageVariant, campaignId }));

            // Also add to regular pages in Redux
            dispatch(setPage({ campaignId, page: pageVariant }));

            // Refetch campaign for updated relationships & mappings
            await dispatch(fetchCampaign(campaignId));

            if (navigateToVariant && pageVariant) {
                dispatch(setActivePage(campaignId, pageVariant.id));
                dispatch(setPageBlockOrder(pageVariant));

                // e.g. navigation from analytics to editor
                if (changeRoute) {
                    await Router.push(ROUTES.editor(campaignId));
                }

                // First usage banner
                if (!localStorage.getItem(SPLIT_TESTING_MODAL_KEY)) {
                    dispatch(
                        showModal(
                            Modals.SPLIT_TESTING_INTRO,
                            {
                                onClose: () =>
                                    localStorage.setItem(
                                        SPLIT_TESTING_MODAL_KEY,
                                        LocalStorageValue.TRUE,
                                    ),
                            },
                            MODAL_OPTIONS.tall,
                        ),
                    );
                }
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'creating page variant failed:' });
        } finally {
            dispatch(setCreatingPageVariantId(''));
        }
    };
};

export const removePageVariant = (campaignId: string): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        const pageVariant = getPageVariant(state);

        // Remove from redux
        const withoutCampaign = omit(pageVariant, campaignId);

        dispatch(setPageVariant(withoutCampaign));
    };
};

export default variantSlice.reducer;
