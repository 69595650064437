import type { State } from '@/app/mediaLibrary/store/index';
import type { BrandSearchResult } from '@/app/mediaLibrary/types';
import type { StateCreator } from 'zustand';

export interface BrandSlice {
    activeBrand: BrandSearchResult | undefined;

    setActiveBrand: (activeBrand: BrandSearchResult | undefined) => void;
}

export const createBrandSlice: StateCreator<
    State,
    [['zustand/devtools', never]],
    [],
    BrandSlice
> = (set) => ({
    activeBrand: undefined,

    setActiveBrand: (activeBrand) => {
        set({ activeBrand });
    },
});
