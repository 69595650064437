import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_OBJECT } from '@/utils/empty';

import { DEFAULT_MODAL_OPTIONS, NAME, TRANSITION_DURATION } from '../constants';

import type { ModalOptions, ModalData, ModalProps } from '../types';
import type { Modals } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State extends ModalData<Modals> {
    visible: boolean;
}

const initialState: State = {
    visible: false,
    type: undefined,
    props: EMPTY_OBJECT,
    options: EMPTY_OBJECT,
};

export const modalsSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setVisible(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                visible: action.payload,
            };
        },
        setModals<Type extends Modals>(state, action: PayloadAction<ModalData<Type>>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setModals, setVisible, reset } = modalsSlice.actions;

// === Selectors ======

export const getModal = (state: AppState) => state[NAME]?.modalsReducer;

// === Thunks ======

export const showModal =
    <Type extends Modals>(
        type: Type,
        props: Omit<ModalProps<Type>, 'onClose'>,
        options: ModalOptions = DEFAULT_MODAL_OPTIONS,
    ): AppThunk =>
    (dispatch) => {
        dispatch(setVisible(true));

        dispatch(
            setModals({
                type,
                props,
                options,
            }),
        );
    };

export const hideModal = (): AppThunk => (dispatch) => {
    // Trigger animation
    dispatch(setVisible(false));

    // Reset after animation is done
    setTimeout(() => {
        dispatch(reset());
    }, TRANSITION_DURATION);
};

export default modalsSlice.reducer;
