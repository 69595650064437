import { NAME } from '@/app/editor/blocks/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';

import { ScissorsIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch } from '@/core/redux/hooks';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { Size } from '@/app/editor/blocks/types';

export interface Props {
    src: string;
    onSave: (src: string) => void;
    size: Size;
    withSizeSlider?: boolean;
    overridenTooltip?: string;
    disabled?: boolean;
    showTooltipWhenDisabled?: boolean;
}

export const CropButton = ({
    src,
    onSave,
    size,
    withSizeSlider,
    overridenTooltip,
    disabled,
    showTooltipWhenDisabled,
}: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();

    if (!src) {
        return null;
    }

    const handleClick = () => {
        dispatch(
            showModal(
                Modals.IMAGE_CROPPING,
                { src, onSave, originalSize: size, withSizeSlider },
                MODAL_OPTIONS[Modals.IMAGE_CROPPING],
            ),
        );
    };

    return (
        <FieldContainer>
            <Tooltip
                content={overridenTooltip ?? t('crop-image')}
                disabled={disabled && !showTooltipWhenDisabled}
            >
                <button
                    type="button"
                    className={cn(
                        'flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 text-gray-400 hover:text-gray-600',
                        {
                            bump: !disabled,
                            'cursor-not-allowed text-gray-300 hover:text-gray-300': disabled,
                        },
                    )}
                    onClick={disabled ? undefined : handleClick}
                >
                    <ScissorsIcon className="size-5" />
                </button>
            </Tooltip>
        </FieldContainer>
    );
};
