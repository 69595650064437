import { NAME } from '@/app/crm/constants';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { Tooltip } from '@/ui/components/Tooltip';

export const FileInfoIcon = () => {
    const { t } = useTranslation(NAME);

    return (
        <Tooltip content={t('custom-file-info-tooltip')}>
            <div className="flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-600 transition-colors hover:bg-gray-100">
                <InformationCircleIcon className="size-4" />
            </div>
        </Tooltip>
    );
};
