import { createSelector, createSlice } from '@reduxjs/toolkit';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

import { getCampaignById } from '@/app/campaigns/models/campaigns';
import {
    getActivePageBlockIds,
    getPageBlockIds,
    setOrderByPage,
} from '@/app/editor/blocks/models/blockOrder';
import { setActiveView } from '@/app/editor/editor/models/sidebar';
import { EditorEngineCriticalError } from '@/app/editor/engine/utils/error/EditorEngineCriticalError';
import {
    getPreviewIsInParentType,
    getPreviewIsInSingleColumn,
    getPreviewParentColumn,
} from '@/app/editor/sections/models/sections';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@/utils/empty';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { NAME } from '../constants';
import { getBlockConfig, getBlockDetails } from '../helpers';

import type { CampaignResource } from '@/app/campaigns/types';
import type { BlockResource } from '@/app/editor/blocks/types';
import type { RelationshipObject, ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

type BlocksObject = { [blockId: string]: BlockResource };

interface BlocksState {
    blocks: BlocksObject;
    fetchedBlockPages: string[];
    activeBlockId: string;
}

const initialState: BlocksState = {
    blocks: EMPTY_OBJECT,
    fetchedBlockPages: EMPTY_ARRAY,
    activeBlockId: EMPTY_STRING,
};

export const blocksSlice = createSlice({
    name: `editor/${NAME}/blocks`,
    initialState,
    reducers: {
        setBlock(state, action: PayloadAction<BlockResource>) {
            return {
                ...state,
                blocks: {
                    ...state.blocks,
                    [action.payload.id]: action.payload,
                },
            };
        },
        setMultipleBlocks(state, action: PayloadAction<BlocksObject>) {
            return {
                ...state,
                blocks: {
                    ...state.blocks,
                    ...action.payload,
                },
            };
        },
        setActiveBlockId(state, action: PayloadAction<string>) {
            return {
                ...state,
                activeBlockId: action.payload,
            };
        },
        setFetchedBlockPages(state, action: PayloadAction<string>) {
            return {
                ...state,
                fetchedBlockPages: [...state.fetchedBlockPages, action.payload],
            };
        },
        removeBlockById(state, action: PayloadAction<string>) {
            const blocks = { ...state.blocks };
            delete blocks[action.payload];

            return {
                ...state,
                blocks,
            };
        },
        resetFetchedBlockPages(state) {
            return {
                ...state,
                fetchedBlockPages: initialState.fetchedBlockPages,
            };
        },
        reset(state) {
            return {
                ...state,
                activeBlockId: initialState.activeBlockId,
            };
        },
    },
});

// === Actions ======

export const {
    setBlock,
    setMultipleBlocks,
    setActiveBlockId,
    setFetchedBlockPages,
    removeBlockById,
    resetFetchedBlockPages,
    reset,
} = blocksSlice.actions;

// Prevent circular dependency

const getActiveCampaign = (state: AppState) => {
    const campaignId = getCampaignIdFromRouter();

    return getCampaignById(state, campaignId);
};

// === Selectors ======

export const getBlocks = (state: AppState) => state[NAME]?.blocksReducer?.blocks;

export const getActiveBlockId = (state: AppState) => state[NAME]?.blocksReducer?.activeBlockId;

export const getBlockById = (state: AppState, blockId: string) =>
    state[NAME]?.blocksReducer?.blocks[blockId];

// === Parent & Child selectors ======

// Get the parent block of a block
export const getBlockParent = (state: AppState, blockId: string): BlockResource | null => {
    const block = getBlockById(state, blockId);

    const parentId = block?.relationships?.parent?.data?.id;

    return parentId ? getBlockById(state, parentId) : null;
};

// Get all parents of a block
export const getAllBlockParents = (state: AppState, blockId: string): BlockResource[] => {
    const block = getBlockById(state, blockId);

    const parentId = block?.relationships?.parent?.data?.id;

    if (!parentId) {
        return [];
    }

    const parent = getBlockById(state, parentId);

    return [parent, ...getAllBlockParents(state, parentId)];
};

// Will use the right selector depending on the nested level
export const getBlocksByParent = createSelector(
    [
        getActivePageBlockIds,
        getBlockById,
        (_: AppState, parentId: string) => parentId,
        (_: AppState, _parentId: string, resolveConcreteId: (concreteId: string) => string) =>
            resolveConcreteId,
    ],
    (blockIds, block, parentId: string | null, resolveConcreteId) => {
        if (!parentId) {
            return blockIds.map(resolveConcreteId);
        }

        return (block?.relationships?.components?.data.map((child) => child.id) ?? []).map(
            resolveConcreteId,
        );
    },
);

// Go up the parent tree until you find a block of a certain type
export const getNextHigherParentByType = (
    state: AppState,
    blockId: string,
    componentType: string,
): BlockResource => {
    const block = getBlockById(state, blockId);
    const componentTypeMatch = block?.attributes?.componentType === componentType;

    if (componentTypeMatch) {
        return block;
    }

    const parentId = block?.relationships?.parent?.data?.id;

    return parentId ? getNextHigherParentByType(state, parentId, componentType) : block;
};

export const getBlockChildComponents = (state: AppState, blockId: string): BlockResource[] => {
    const block = getBlockById(state, blockId);

    return block?.relationships?.components?.data?.map((childData) => {
        return getBlockById(state, childData.id);
    });
};

export const getBlockChildComponentsMemoized = (blockId: string) =>
    createSelector([getBlocks], (blocks): BlockResource[] => {
        const block = blocks[blockId];

        if (!block) {
            return EMPTY_ARRAY;
        }

        return block?.relationships?.components?.data?.map((childData) => {
            return blocks[childData.id];
        });
    });

// Travers block's child components until you find a block of a certain type
export const getBlockChildComponentByType = (
    state: AppState,
    blockId: string,
    componentType: string,
): BlockResource => {
    const children = getBlockChildComponents(state, blockId);

    const childMatch = find(children, (childBlock) => {
        return childBlock?.attributes?.componentType === componentType;
    });

    return getBlockById(state, childMatch?.id);
};

export const getBlockIsOnlyChild = (state: AppState, blockId: string) => {
    const parent = getBlockParent(state, blockId);

    if (!parent) {
        return false;
    }

    const children = parent.relationships.components.data;

    return children.length === 1;
};

export const getBlockIsInParentType = (
    state: AppState,
    blockId: string,
    parentComponentType: string,
): boolean => {
    const parents = getAllBlockParents(state, blockId);

    return parents.some((parent) => parent?.attributes?.componentType === parentComponentType);
};

export const getBlockOrPreviewIsInParentType = (
    state: AppState,
    blockId: string,
    parentComponentType: string,
): boolean => {
    const blockIsInParent = getBlockIsInParentType(state, blockId, parentComponentType);
    const previewIsInParent = getPreviewIsInParentType(state, blockId, parentComponentType);

    return blockIsInParent || previewIsInParent;
};

// Column selectors

export const getBlockParentIsType = (
    state: AppState,
    blockId: string,
    componentType: string,
): boolean => {
    const parent = getBlockParent(state, blockId);

    if (!parent) {
        return false;
    }

    return parent?.attributes?.componentType === componentType;
};

export const getBlockIsInColumn = (state: AppState, blockId: string): boolean => {
    return getBlockIsInParentType(state, blockId, 'gridColumn');
};

export const getBlockParentColumn = (state: AppState, blockId: string): BlockResource | null => {
    const isInColumn = getBlockIsInColumn(state, blockId);

    if (!isInColumn) {
        return null;
    }

    const parents = getAllBlockParents(state, blockId);

    return parents.find((parent) => parent.attributes.componentType === 'gridColumn');
};

export const getIsSingleColumn = (state: AppState, columnBlockId: string): boolean => {
    const layoutBlock = getBlockParent(state, columnBlockId);

    if (!layoutBlock) {
        return false;
    }

    const children = layoutBlock.relationships.components.data;

    return children.length === 1;
};

export const getBlockIsInSingleColumn = (state: AppState, blockId: string): boolean => {
    const parentColumn = getBlockParentColumn(state, blockId);

    if (!parentColumn) {
        return false;
    }

    return getBlockIsOnlyChild(state, parentColumn?.id);
};

export const getColumnCount = (state: AppState, blockId: string): number => {
    const column = getBlockParent(state, blockId);
    const layout = getBlockParent(state, column?.id);

    return layout?.relationships.components.data.length || 0;
};

// For blocks and section previews

export const getBlockOrPreviewIsInColumn = (state: AppState, blockId: string): boolean => {
    return getBlockOrPreviewIsInParentType(state, blockId, 'gridColumn');
};

export const getBlockOrPreviewParentColumn = (
    state: AppState,
    blockId: string,
): BlockResource | null => {
    return getBlockParentColumn(state, blockId) || getPreviewParentColumn(state, blockId);
};

export const getBlockOrPreviewIsInSingleColumn = (state: AppState, blockId: string): boolean => {
    return getPreviewIsInSingleColumn(state, blockId) || getBlockIsInSingleColumn(state, blockId);
};

// === Child selectors ======

export const getHeaderAndFooterIds = createSelector([getActiveCampaign], (campaign) => {
    return {
        headerId: get(campaign, 'relationships.header.data.id', EMPTY_STRING),
        footerId: get(campaign, 'relationships.footer.data.id', EMPTY_STRING),
    };
});

export const getActiveBlock = (state: AppState) => {
    const activeBlockId = getActiveBlockId(state);

    return getBlockById(state, activeBlockId);
};

export const getActiveBlockParent = (state: AppState): BlockResource | null => {
    const activeBlock = getActiveBlock(state);

    const parentBlockId = get(activeBlock, 'relationships.parent.data.id');

    if (parentBlockId) {
        return getBlockById(state, parentBlockId);
    }

    return null;
};

export const getHasActiveChild = (state: AppState, blockId: string) => {
    const block = getBlockById(state, blockId);
    const activeBlockId = getActiveBlockId(state);

    if (!block || !activeBlockId) {
        return false;
    }

    const childBlocks = block?.relationships?.components;

    if (!childBlocks) {
        return false;
    }

    const childBlockIndex = childBlocks.data.findIndex(
        (childBlock) => childBlock.id === activeBlockId,
    );

    if (childBlockIndex > -1) {
        return true;
    }

    // Recursively check child blocks
    return childBlocks.data.some((childBlock) => {
        return getHasActiveChild(state, childBlock.id);
    });
};

export const getHasActiveDirectChild = (state: AppState, blockId: string) => {
    const block = getBlockById(state, blockId);
    const activeBlockId = getActiveBlockId(state);

    if (!block || !activeBlockId) {
        return false;
    }

    const childBlocks = block?.relationships?.components;

    if (!childBlocks) {
        return false;
    }

    const childBlockIndex = childBlocks.data.findIndex(
        (childBlock) => childBlock.id === activeBlockId,
    );

    return childBlockIndex > -1;
};

export const getHasActiveParent = (state: AppState, blockId: string) => {
    const activeBlockId = getActiveBlockId(state);
    const parent = getBlockParent(state, blockId);

    if (!parent) {
        return false;
    }

    const parentId = parent?.id;

    return parentId === activeBlockId;
};

export const getFetchedBlockPages = (state: AppState) =>
    state[NAME]?.blocksReducer?.fetchedBlockPages;

export const getActiveTopLevelBlock = createSelector(
    [getActiveBlock, getActiveBlockParent],
    (activeBlock, activeBlockParent) => {
        const config = getBlockConfig(activeBlock?.attributes?.componentType);

        if (!config || !activeBlock?.id) {
            return undefined;
        }

        return !activeBlockParent?.id && !config.borderMenu.hidden ? activeBlock : undefined;
    },
);

export const getHasParentWithActiveChild = (state: AppState, blockId: string) => {
    const block = getBlockById(state, blockId);
    const activeBlockId = getActiveBlockId(state);

    if (!block || !activeBlockId) {
        return false;
    }

    const parent = getBlockParent(state, blockId);

    if (!parent) {
        return false;
    }

    const childBlocks = parent?.relationships?.components;

    if (!childBlocks) {
        return false;
    }

    const childBlockIndex = childBlocks.data.findIndex(
        (childBlock) => childBlock.id === activeBlockId,
    );

    return childBlockIndex > -1;
};

// === Thunks ======

// fetch a single block
export const fetchBlock =
    (blockId: string): AppThunk<Promise<BlockResource>> =>
    async (dispatch) => {
        try {
            const response = await apiGet<ResponseData<BlockResource>>(`/components/${blockId}`);
            const block: BlockResource = getDataFromResponse(response);

            dispatch(setBlock(block));

            return block;
        } catch (err) {
            handleRuntimeError(err, { debugMessage: `fetching block (${blockId}) failed:` });
        }
    };

// fetch block and it's child blocks
export const fetchBlockWithChildren =
    (blockId: string): AppThunk =>
    async (dispatch) => {
        const block = await dispatch(fetchBlock(blockId));

        const { childComponents } = getBlockDetails(block);

        return Promise.all(
            childComponents.data.map((child) => {
                return dispatch(fetchBlockWithChildren(child.id));
            }),
        );
    };

// fetch header and footer of active page
export const fetchHeaderAndFooter =
    (campaign?: CampaignResource): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (campaign) {
                const headerId = get(campaign, 'relationships.header.data.id');
                const footerId = get(campaign, 'relationships.footer.data.id');

                if (headerId) {
                    await dispatch(fetchBlock(headerId));
                }

                if (footerId) {
                    await dispatch(fetchBlock(footerId));
                }

                return;
            }

            const { headerId, footerId } = getHeaderAndFooterIds(getState());

            if (headerId && footerId) {
                await Promise.all([dispatch(fetchBlock(headerId)), dispatch(fetchBlock(footerId))]);
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching Header and Footer failed:' });
        }
    };

// fetch all blocks of a page
export const fetchPageBlocks =
    (pageId: string): AppThunk =>
    async (dispatch) => {
        if (!pageId) {
            return;
        }

        try {
            const response = await apiGet<ResponseData<BlockResource[]>>(
                `/pages/${pageId}/components/all`,
            );

            const newBlocks = resourceArrayToObject(getDataFromResponse(response));

            dispatch(setMultipleBlocks(newBlocks));
            dispatch(setFetchedBlockPages(pageId));
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `Fetching page components for pageId ${pageId} failed:`,
            });
        }
    };

// set active block
export const setActiveBlock =
    (blockId: string): AppThunk =>
    (dispatch) => {
        if (!blockId) {
            return;
        }

        dispatch(setActiveBlockId(blockId));
        dispatch(setActiveView('editBlock'));
    };

// remove child from parent block (optimistically delete child block)
export const removeChildBlockById =
    (childBlockId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const childBlock = getBlockById(state, childBlockId);

        // get Parent
        const { parentComponent } = getBlockDetails(childBlock);
        const parentBlock = getBlockById(state, parentComponent.data?.id);

        // get child blocks
        const childBlocks = parentBlock.relationships.components.data;
        const childBlockIndex = findIndex(childBlocks, { id: childBlockId });

        // remove from child blocks
        const updatedChildBlocks = [...childBlocks];
        updatedChildBlocks.splice(childBlockIndex, 1);

        // update in state
        const updatedParentBlock = {
            ...parentBlock,
            relationships: {
                ...parentBlock.relationships,
                components: { data: updatedChildBlocks },
            },
        };

        dispatch(setBlock(updatedParentBlock));
    };

/**
 * Insert a block at a specific index as a child of another block, or at top
 * level if parent is null. If the block is already present, update its parent
 * and index.
 */
export const upsertBlockAtIndex =
    (block: BlockResource, index: number): AppThunk =>
    (dispatch, getState) => {
        const state = getState();

        dispatch(setBlock(block));

        const updateChildren = <T extends string | RelationshipObject<string>>(
            children: T[],
            newRelationship: T,
        ) => {
            if (index < 0 || index > children.length) {
                const pageBlockIds = getPageBlockIds(state, block.relationships.page.data?.id);

                throw new EditorEngineCriticalError(
                    'Attempting to upsert a block at an index which is out of bounds',
                    {
                        blockId: block.id,
                        index,
                        childrenLength: children.length,
                        parent: block.relationships.parent?.data,
                        blockPageId: block.relationships.page.data?.id,
                        pageBlockIds,
                    },
                );
            }

            const updatedChildBlocks = [
                ...children.filter((child) => {
                    if (typeof child === 'object' && typeof newRelationship === 'object') {
                        return child.id !== newRelationship.id;
                    }

                    return child !== newRelationship;
                }),
            ];

            updatedChildBlocks.splice(index, 0, newRelationship);

            return updatedChildBlocks;
        };

        const pageId = block.relationships.page.data?.id;
        const activePageBlockIds = getPageBlockIds(state, pageId);

        if (block.relationships.parent.data === null) {
            // Adding to root level
            dispatch(
                setOrderByPage({
                    pageId,
                    blockIds: updateChildren(activePageBlockIds, block.id),
                }),
            );

            return;
        }

        const parentBlock = getBlockById(state, block?.relationships?.parent?.data?.id);

        if (!parentBlock) {
            throw new Error('Parent block not found');
        }

        const childBlocks = parentBlock.relationships.components.data;

        const updatedParentBlock = {
            ...parentBlock,
            relationships: {
                ...parentBlock.relationships,
                components: {
                    data: updateChildren(childBlocks, { id: block.id, type: 'component' }),
                },
            },
        } satisfies BlockResource;

        dispatch(setBlock(updatedParentBlock));
    };

export default blocksSlice.reducer;
