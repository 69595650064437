import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';

import { BlockComponentType } from '@/app/editor/blocks/types';
import { PerspectiveEditorEngineNodeTypeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEngineNodeTypeRenderer';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { WarningPing } from '@/ui/components/WarningPing';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    url: string | undefined;
}

const Component: FC<Props> = ({ blockId, url, isPreview, componentType }) => {
    const { t } = useTranslation(NAME);

    const showWarningPing = !url && !isPreview && componentType === BlockComponentType.FORM_URL;

    return (
        <div className="relative mt-6">
            <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                parentId={blockId}
                className="flex flex-col space-y-4"
                filter={({ block }) => block.attributes.componentType !== BlockComponentType.BUTTON}
            />

            <div className="mt-4">
                <PerspectiveEditorEngineNodeTypeRenderer
                    parentId={blockId}
                    componentType={BlockComponentType.BUTTON}
                    draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
                />
            </div>

            {/* Connect warning */}
            {showWarningPing && <WarningPing tooltip={t('calendly-missing-url-warning')} />}
        </div>
    );
};

Component.displayName = 'Form';

export default Component;
