import { ENDPOINTS, QUERY_KEYS } from '@/app/campaigns/constants';

import { useQuery } from '@tanstack/react-query';

import { apiGet } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';

export const useCampaign = (campaignId: string) => {
    return useQuery({
        queryKey: QUERY_KEYS.campaign(campaignId || ''),
        queryFn: async () => {
            try {
                const response = await apiGet<ResponseData<CampaignResource>>(
                    ENDPOINTS.GET.campaign(campaignId),
                );

                return getDataFromResponse(response);
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};
