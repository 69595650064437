import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import * as Select from '@radix-ui/react-select';
import get from 'lodash/get';

import { cn } from '@/utils/cn';

import type { DropdownOption } from '@/ui/types';
import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    selectedOption: DropdownOption | undefined;
    buttonClass?: string;
    placeholder?: string;
    isDisabled?: boolean;
}

export const SelectTrigger = ({
    children,
    selectedOption,
    buttonClass,
    placeholder,
    isDisabled = false,
}: Props) => {
    return (
        <Select.Trigger disabled={isDisabled} asChild>
            <button
                type="button"
                className={cn(
                    'group relative flex h-12 w-full cursor-pointer items-center rounded-lg bg-gray-100 pl-4 text-left text-sm text-gray-500 outline-none hover:text-gray-800 data-[state=open]:text-gray-800',
                    'disabled:cursor-not-allowed disabled:hover:text-gray-500',
                    buttonClass,
                )}
                disabled={isDisabled}
            >
                {children}
                <span className="block flex-1 truncate">
                    {get(selectedOption, 'key', placeholder || '-')}
                </span>
                {!isDisabled && (
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="size-5" aria-hidden="true" />
                    </span>
                )}
            </button>
        </Select.Trigger>
    );
};
