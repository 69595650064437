import { createSlice } from '@reduxjs/toolkit';

import { hideModal } from '@/app/modals/models/modals';
import { EMPTY_STRING } from '@/utils/empty';

import { NAME } from '../constants';
import { CommandMenuType } from '../types';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    open: boolean;
    type: CommandMenuType;
    search: string;
}

const initialState: State = {
    open: false,
    type: CommandMenuType.default,
    search: EMPTY_STRING,
};

export const menuSlice = createSlice({
    name: `${NAME}/menu`,
    initialState,
    reducers: {
        setOpen(state, action: PayloadAction<boolean>) {
            state.open = action.payload;
        },
        setType(state, action: PayloadAction<CommandMenuType>) {
            state.type = action.payload;
        },
        setSearch(state, action: PayloadAction<string>) {
            state.search = action.payload;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setOpen, setType, setSearch, reset } = menuSlice.actions;

// === Selectors ======

export const getOpen = (state: AppState) => state[NAME]?.menuReducer?.open;
export const getType = (state: AppState) => state[NAME]?.menuReducer?.type;
export const getSearch = (state: AppState) => state[NAME]?.menuReducer?.search;

// === Thunks ======

export const openMenu =
    (type: CommandMenuType = CommandMenuType.default): AppThunk =>
    async (dispatch) => {
        dispatch(hideModal());
        dispatch(setOpen(true));
        dispatch(setType(type));
        dispatch(setSearch(''));
    };

export default menuSlice.reducer;
