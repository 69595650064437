import formatDateIntl from 'date-fns/intlFormat';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/ui/components/Tooltip';
import { hasIntl } from '@/utils/oldBrowsers';

import { getFullFormattedDate } from '../helpers';

import type { DateFnsFormatOptions } from 'types/generic';

interface Props {
    className?: string;
    tooltipHidden?: boolean;
    localizedDate?: boolean;
}

export const useDate = ({
    timestamp,
    formatOptions = {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
    },
    tooltipFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    },
}: {
    timestamp: string;
    formatOptions?: DateFnsFormatOptions;
    tooltipFormatOptions?: DateFnsFormatOptions;
}) => {
    const { t } = useTranslation();
    const { locale } = useRouter();
    const date = Date.parse(timestamp) ? new Date(timestamp) : null;

    const formattedContent =
        hasIntl && date
            ? formatDateIntl(date, formatOptions, {
                  locale,
              })
            : '-';

    const formattedTime =
        hasIntl && date ? getFullFormattedDate(date, locale, tooltipFormatOptions) : '-';

    const DateString = ({ className, tooltipHidden, localizedDate }: Props) => {
        const tooltipValue = localizedDate
            ? t('common:localized-date', { formattedDate: formattedTime })
            : formattedTime;

        const formattedValue = tooltipHidden ? formattedTime : formattedContent;
        const renderedValue = localizedDate
            ? t('common:localized-date', {
                  formattedDate: formattedValue,
              })
            : formattedValue;

        return (
            <Tooltip content={tooltipValue} delay={1000} disabled={tooltipHidden}>
                <div className={className}>{renderedValue}</div>
            </Tooltip>
        );
    };

    return { DateString, formattedContent, formattedTime };
};
