import { StarIcon } from '@heroicons/react/24/solid';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { compressAvatar } from '@/app/editor/blocks/components/Reviews/compressAvatar';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getThemeColor, getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '../../types';
import type { JSONContent } from '@tiptap/core';

const TipTapBlock = dynamic(
    () => import('../Text/TipTapBlock/TipTapBlock').then((mod) => mod.TipTapBlock),
    { ssr: false },
);

export interface ComponentProps extends DefaultBlockComponentProps {
    rating: number;
    images: string[];
    wysiwyg: JSONContent;
    align: 'left' | 'center';
    showImages: boolean;
    showStars: boolean;
    color: string;
    textColor: string;
}

const Component = ({
    artBoardIndex,
    blockId,
    box,
    rating = 5,
    images,
    wysiwyg,
    color,
    align,
    showImages,
    showStars,
    activeTheme,
}: ComponentProps) => {
    const { isInColumn, isMobile, isDesktop } = useLayoutInfo(blockId);

    return (
        <Box
            box={{
                ...getBoxAttributes({ box, isInColumn }),
                left: isInColumn ? 'none' : 'medium',
                right: isInColumn ? 'none' : 'medium',
            }}
            artBoardIndex={artBoardIndex}
        >
            <div
                className={cn('flex items-center justify-center', {
                    'flex-col': align === 'center',
                    'justify-start': align === 'left' && isInColumn,
                })}
            >
                {showImages && (
                    <div
                        className={cn('flex flex-shrink-0 items-center justify-center -space-x-3', {
                            'mb-2': align === 'center',
                        })}
                    >
                        {images.map((image, index) => (
                            <div
                                key={`${image}-${index}`}
                                className={cn(
                                    'relative overflow-hidden rounded-full border-2',
                                    images.length === 1 ? 'size-14' : 'size-10',
                                )}
                                style={{
                                    borderColor: isInColumn
                                        ? 'white'
                                        : getThemedOrCustomColor(
                                              box?.backgroundColor,
                                              'backgroundColor',
                                              activeTheme,
                                          ),
                                }}
                            >
                                <Image
                                    src={compressAvatar(image)}
                                    alt={`Avatar ${index}`}
                                    className="object-cover"
                                    fill
                                />
                            </div>
                        ))}
                    </div>
                )}

                <div className={cn({ 'ml-5': align === 'left' })}>
                    {showStars && (
                        <div
                            className={cn('relative isolate h-6 w-[120px]', {
                                'mx-auto': align === 'center',
                            })}
                        >
                            {/* Empty stars */}
                            <div className="absolute inset-0 flex size-full items-center">
                                {[...Array(5)].map((_, index) => (
                                    <StarIcon
                                        key={`empty-star-${index}`}
                                        className="size-6 flex-shrink-0 text-gray-300"
                                    />
                                ))}
                            </div>

                            {/* Filled stars */}
                            <div
                                className="absolute inset-0 z-10 flex h-full items-center overflow-hidden"
                                style={{ width: `${(rating / 5) * 100}%` }}
                            >
                                {[...Array(5)].map((_, index) => (
                                    <StarIcon
                                        key={`filled-star-${index}`}
                                        className="size-6 flex-shrink-0"
                                        style={{ color }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <div
                        className={cn('mt-2 text-left text-sm', {
                            'text-center': align === 'center',
                            'max-w-[200px]': align === 'left' && isMobile,
                            'max-w-[343px]': align === 'center' && isMobile,
                            'max-w-[750px]': align === 'left' && isDesktop,
                        })}
                    >
                        <TipTapBlock
                            blockId={blockId}
                            wysiwyg={wysiwyg}
                            // Only used when no color is set via TipTap
                            textColor={getThemeColor(activeTheme, 'fontColor')}
                        />
                    </div>
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'Reviews';

export default Component;
