import { ROUTES } from '@/app/campaigns/constants';

import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { getCampaignWithPagination } from '@/app/campaigns/helpers';
import { fetchCampaign, getCampaignRequest } from '@/app/campaigns/models/campaigns';
import { CampaignFilter } from '@/app/campaigns/types';
import { setPageBlockOrder } from '@/app/editor/blocks/models/blockOrder';
import { setRenameAddedPageId } from '@/app/editor/pages/models/add';
import { setActivePage, setPage } from '@/app/editor/pages/models/pages';
import { getWorkspaces } from '@/app/workspaces/models/workspaces';
import { RequestState } from '@/app/workspaces/types';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@/utils/empty';
import { reportError } from '@/utils/sentry';

import { NAME } from '../constants';

import type { CampaignResource, CampaignsWithPagination } from '@/app/campaigns/types';
import type { PageResource } from '@/app/editor/pages/types';
import type { Pagination } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    campaigns: CampaignResource[];
    pagination: Partial<Pagination>;
    search: string;
    loading: boolean;
    hasFetchedCampaigns: boolean;
    requestState: Partial<Record<'funnels' | 'search', RequestState>>;
}

const initialState: State = {
    campaigns: EMPTY_ARRAY,
    pagination: EMPTY_OBJECT,
    search: EMPTY_STRING,
    loading: false,
    hasFetchedCampaigns: false,
    requestState: {
        funnels: RequestState.Idle,
        search: RequestState.Idle,
    },
};

export const duplicatePageSlice = createSlice({
    name: `editor/${NAME}/duplicate`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<State['loading']>) {
            state.loading = action.payload;
        },
        setHasFetchedCampaigns(state, action: PayloadAction<State['hasFetchedCampaigns']>) {
            state.hasFetchedCampaigns = action.payload;
        },
        setSearch(state, action: PayloadAction<State['search']>) {
            state.search = action.payload;
        },
        setCampaigns(state, action: PayloadAction<State['campaigns']>) {
            state.campaigns = action.payload;
        },
        appendCampaigns(state, action: PayloadAction<State['campaigns']>) {
            state.campaigns.push(...action.payload);
        },
        setPagination(state, action: PayloadAction<State['pagination']>) {
            state.pagination = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setLoading,
    setHasFetchedCampaigns,
    setSearch,
    setCampaigns,
    appendCampaigns,
    setPagination,
    setRequestState,
    reset,
} = duplicatePageSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.duplicatePageReducer?.loading;
export const getSearch = (state: AppState) => state[NAME]?.duplicatePageReducer?.search;
export const getCampaigns = (state: AppState) => state[NAME]?.duplicatePageReducer?.campaigns;
export const getPagination = (state: AppState) => state[NAME]?.duplicatePageReducer?.pagination;
export const getSearching = (state: AppState) =>
    state[NAME]?.duplicatePageReducer?.requestState.search === RequestState.InProgress;
export const getFetchingCampaigns = (state: AppState) =>
    state[NAME]?.duplicatePageReducer?.requestState.funnels === RequestState.InProgress;
export const getHasFetchedCampaigns = (state: AppState) =>
    state[NAME]?.duplicatePageReducer?.hasFetchedCampaigns;

// === Thunks ======

// Helper function
const postDuplicationActions =
    (page: PageResource, setActive: boolean = true): AppThunk =>
    async (dispatch) => {
        if (page) {
            const campaignId = page?.relationships?.campaign?.data?.id;

            if (!campaignId) {
                reportError({
                    error: 'Attempted to execute post duplication actions without a campaignID',
                });

                return;
            }
            // Set page and block order
            await dispatch(setPage({ campaignId, page }));
            await dispatch(setPageBlockOrder(page));

            if (setActive) {
                // fetch updated campaign + mapping
                await dispatch(fetchCampaign(campaignId));
                dispatch(setActivePage(campaignId, page.id));
            }
        }
    };

// easy duplicate
export const duplicatePage =
    (pageId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const response = await apiPost(`/pages/${pageId}`, {});
            const newPage = getDataFromResponse(response) as PageResource;

            // Update redux state and navigate to new page
            dispatch(postDuplicationActions(newPage));

            // We automatically activate the rename mode for the newly created/duplicated page
            dispatch(setRenameAddedPageId(newPage?.id));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'duplicating page failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

const fetchCampaignData =
    (nextPageUrl?: string): AppThunk<Promise<CampaignsWithPagination | undefined>> =>
    async (dispatch, getState) => {
        const state = getState();
        const search = getSearch(state);
        const workspaces = getWorkspaces(state);
        const workspaceIdsToSearch = Object.keys(workspaces)?.join(',');

        const response = await dispatch(
            getCampaignRequest({
                workspaceIds: workspaceIdsToSearch,
                queryOptions: {
                    search,
                    filter: CampaignFilter.all,
                },
                nextPageUrl,
            }),
        );

        if (!response) {
            return;
        }

        return getCampaignWithPagination(response);
    };

const fetchAndSetCampaigns =
    (nextUrl?: string, append?: boolean): AppThunk =>
    async (dispatch) => {
        const campaignData = await dispatch(fetchCampaignData(nextUrl));

        const setCampaignsAction = append ? appendCampaigns : setCampaigns;

        if (campaignData?.pagination) {
            dispatch(setPagination(campaignData.pagination));
        }

        if (campaignData?.campaigns) {
            dispatch(setCampaignsAction(campaignData.campaigns));
        }
    };

export const fetchCampaigns = (): AppThunk => async (dispatch) => {
    dispatch(setRequestState({ funnels: RequestState.InProgress }));

    try {
        await dispatch(fetchAndSetCampaigns());
        dispatch(setRequestState({ funnels: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ funnels: RequestState.Error }));
        handleRuntimeError(err, { debugMessage: 'fetching campaigns failed:' });
    } finally {
        dispatch(setHasFetchedCampaigns(true));
    }
};

export const loadNextCampaignPage = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const nextPageUrl = getPagination(state)?.next;

    if (!nextPageUrl) {
        return;
    }
    dispatch(setRequestState({ funnels: RequestState.InProgress }));

    try {
        await dispatch(fetchAndSetCampaigns(nextPageUrl, true));
        dispatch(setRequestState({ funnels: RequestState.Done }));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching next campaign page failed:' });
        dispatch(setRequestState({ funnels: RequestState.Error }));
    }
};

// duplicate to funnel
export const duplicatePageToFunnel =
    (pageId: string, campaignId: string, navigate: boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const response = await apiPost(`/pages/${pageId}`, { data: { campaign: campaignId } });

            if (navigate) {
                // navigate to funnel
                await Router.push(ROUTES.editor(campaignId));
            }

            // Set in Redux
            await dispatch(
                postDuplicationActions(getDataFromResponse(response) as PageResource, navigate),
            );
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `duplicating page ${pageId} to funnel ${campaignId} failed:`,
            });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default duplicatePageSlice.reducer;
