import { useDate } from '@/app/crm/hooks/useDate';

import type { DateFnsFormatOptions } from 'types/generic';

interface Props {
    value: string;
    formatOptions?: DateFnsFormatOptions;
    tooltipFormatOptions?: DateFnsFormatOptions;
}

export const DateCell = ({
    value,
    formatOptions = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    },
    tooltipFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    },
}: Props) => {
    const { DateString } = useDate({
        timestamp: value,
        formatOptions,
        tooltipFormatOptions,
    });

    return (
        <div className="flex h-full min-h-10 items-center px-2 py-1.5">
            <DateString className="truncate text-gray-700" localizedDate />
        </div>
    );
};
