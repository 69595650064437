import { createSlice } from '@reduxjs/toolkit';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';

import { getUser } from '@/app/user/models/user';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { loadCookie } from '@/utils/cookies';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { NAME, ROUTES, SESSION_TABLE_DEFAULT_COLUMNS } from '../constants';
import { removeEmailFromSchema, replaceDotFields } from '../helper';

import type { Column, SchemaItem } from '../types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Language } from 'types/generic';

interface State {
    loading: boolean;
    schema: { [campaignId: string]: SchemaItem[] };
    activeColumn: SchemaItem;
    menuVisible: boolean;
    visibleColumns: Column[];
}

const initialState: State = {
    loading: false,
    schema: EMPTY_OBJECT,
    activeColumn: EMPTY_OBJECT as SchemaItem,
    menuVisible: false,
    visibleColumns: EMPTY_ARRAY,
};

export const columnSelectSlice = createSlice({
    name: `${NAME}/columnSelect`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setCampaignSessionSchema(
            state,
            action: PayloadAction<{ campaignId: string; schema: SchemaItem[] }>,
        ) {
            return {
                ...state,
                schema: {
                    ...state.schema,
                    [action.payload.campaignId]: action.payload.schema,
                },
            };
        },
        setActiveColumn(state, action: PayloadAction<{ campaignId: string; column: SchemaItem }>) {
            return {
                ...state,
                activeColumn: {
                    ...state.activeColumn,
                    [action.payload.campaignId]: action.payload.column,
                },
            };
        },
        setColumnMenuVisibility(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                menuVisible: action.payload,
            };
        },
        setVisibleColumns(state, action: PayloadAction<Column[]>) {
            return {
                ...state,
                visibleColumns: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setLoading,
    setCampaignSessionSchema,
    setActiveColumn,
    setColumnMenuVisibility,
    setVisibleColumns,
    reset,
} = columnSelectSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.columnSelectReducer?.loading;

export const getCampaignSessionsSchemaForId = (state: AppState, campaignId: string) =>
    get(state[NAME]?.columnSelectReducer?.schema, campaignId, EMPTY_ARRAY);

export const getActiveColumn = (state: AppState, campaignId: string): SchemaItem =>
    state[NAME]?.columnSelectReducer?.activeColumn[campaignId] || EMPTY_OBJECT;

export const getColumnMenuVisibility = (state: AppState) =>
    state[NAME]?.columnSelectReducer?.menuVisible;

export const getVisibleColumns = (state: AppState) =>
    state[NAME]?.columnSelectReducer?.visibleColumns;

// === Thunks ======

export const updateVisibleColumns =
    (campaignId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();

        const user = getUser(state);
        const language =
            (loadCookie('NEXT_LOCALE') as Language) || user?.attributes?.language || 'en';

        const activeColumn = getActiveColumn(state, campaignId);

        const columns: Column[] = SESSION_TABLE_DEFAULT_COLUMNS.map((column) => {
            return {
                ...column,
                text: column.translation,
                language,
            };
        });

        if (activeColumn.fieldName) {
            columns.push({
                name: `profile.${activeColumn.fieldName}`,
                text: activeColumn.title || activeColumn.fieldName,
                path: `answers.${activeColumn.fieldName}`,
                clickable: true,
                sortable: true,
            });
        }

        return dispatch(setVisibleColumns(columns));
    };

export const setActiveColumnAndUpdateVisibleColumns =
    (campaignId: string, column: SchemaItem): AppThunk =>
    async (dispatch) => {
        await dispatch(setActiveColumn({ campaignId, column }));
        await dispatch(updateVisibleColumns(campaignId));
    };

export const fetchCampaignSessionsSchema =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        dispatch(setLoading(true));

        try {
            const response = await apiGet<ResponseData<SchemaItem[]>>(
                `/sessions/profile-schema?campaign=${campaignId}`,
            );
            let schema = getDataFromResponse(response, EMPTY_ARRAY);
            schema = removeEmailFromSchema(schema);
            schema = replaceDotFields(schema);
            const activeColumn = getActiveColumn(state, campaignId);
            const activeColumnsToSet = isEmpty(activeColumn) ? schema[0] : activeColumn;

            dispatch(setActiveColumnAndUpdateVisibleColumns(campaignId, activeColumnsToSet));
            dispatch(setCampaignSessionSchema({ campaignId, schema }));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching funnel sessions schema failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export const navigateToSessionsAndSetActiveColumn =
    (campaignId: string, questionTitle: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getCampaignSessionsSchemaForId(state, campaignId);
        const url = ROUTES.analyticsSessions(campaignId);

        const column = find(schema, { title: questionTitle });

        dispatch(setActiveColumnAndUpdateVisibleColumns(campaignId, column));

        // Redirect
        await Router.push(url);
    };

export default columnSelectSlice.reducer;
