import { NAME } from '@/app/editor/blocks/constants';
import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import { useTranslation } from 'next-i18next';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { getBlockComponentType, getIsTipTapData } from '@/app/editor/blocks/helpers';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { ColorPickerHistoryProvider } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color/ColorPickerHistoryProvider';
import EmojiPicker from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/EmojiPicker';
import { IconToggleButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import Linking from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking';
import ResultMapping from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ResultMapping';
import { TextSize } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextSize';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import TrackingIdInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TrackingIdInput';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { getCalculatedTextColor, getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import { SidebarPortalTarget } from '@/ui/components/_BlockEditFields/SidebarPortalTarget';

import type { BlockResource, DefaultEditFormProps, TextSizeShort } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const textSizes: TextSizeShort[] = ['S', 'M', 'L'];

const Form: FC<Props & InjectedFormProps> = ({
    blockId,
    pageId,
    campaignId,
    handleSubmit,
    activeTheme,
    parent,
    initialValues,
}) => {
    const { t } = useTranslation(NAME);

    const formValues = useAppSelector((state) => getFormValues(blockId)(state) as BlockResource);

    const isTipTap = getIsTipTapData(initialValues?.attributes?.content?.wysiwyg);

    const calculatedTextColor = getCalculatedTextColor({
        block: formValues,
        activeTheme,
        bgThemeColorKey: 'formFieldBackgroundColor',
        hasSameColorCheck: false,
    });

    const isMultipleChoice = getBlockComponentType(parent) === 'questionMultipleChoice';
    const showEmojiPicker = formValues?.attributes?.content?.showIcon;

    return (
        <div>
            {isTipTap ? (
                <>
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-size-portal-${blockId}`} />
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-styles-portal-${blockId}`} />
                </>
            ) : (
                <>
                    <TextSize textBlockId={blockId} sizes={textSizes} />
                    <TextStyle textBlockId={blockId} hideLink />
                </>
            )}

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.showIcon"
                    component={IconToggleButton}
                    submit={handleSubmit}
                    icon="emoji"
                />

                {isTipTap ? (
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-color-portal-${blockId}`} />
                ) : (
                    <Field
                        name="attributes.content.color"
                        component={Color}
                        expand="center"
                        submit={handleSubmit}
                        themeColor={calculatedTextColor}
                        tooltip={t('font-color')}
                    />
                )}

                <ColorPickerHistoryProvider>
                    <Field
                        name="attributes.content.backgroundColor"
                        component={Color}
                        expand="right"
                        submit={handleSubmit}
                        themeColor={getThemeColor(activeTheme, 'formFieldBackgroundColor')}
                        tooltip={t('background-color')}
                        hasGradient
                    />
                </ColorPickerHistoryProvider>
            </div>

            {showEmojiPicker && (
                <Field
                    name="attributes.content.icon"
                    component={EmojiPicker}
                    submit={handleSubmit}
                />
            )}

            {isMultipleChoice ? (
                <Subheading text={t('tracking')} />
            ) : (
                <Subheading
                    text={t('linking')}
                    helpTooltip={HELP_TOOLTIP_CONTENT.EDIT_FORM_LINKING}
                />
            )}

            {!isMultipleChoice && (
                <>
                    <div className="mt-4">
                        <Field
                            name="attributes.content.linking"
                            component={Linking}
                            pageId={pageId}
                            blockId={blockId}
                            submit={handleSubmit}
                            campaignId={campaignId}
                        />
                    </div>

                    <ResultMapping
                        pageId={pageId}
                        campaignId={campaignId}
                        blockId={blockId}
                        className="mt-2"
                    />
                </>
            )}

            <div className={isMultipleChoice ? 'mt-4' : 'mt-2'}>
                <Field
                    name="attributes.content.clickId"
                    component={TrackingIdInput}
                    submit={handleSubmit}
                />
            </div>
        </div>
    );
};

Form.displayName = 'QuestionTextAnswerEditForm';

export default reduxForm({})(Form);
