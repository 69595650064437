import imgix from '@/core/imgix';

import { AvailableCharts, TimeframeOption } from './types';

import type { ActivityByDatetimeData, ExportMetricBgOption, WidgetSubtype } from './types';

export const NAME = 'metrics';

// KPIs

export const GRID_COLUMNS = 12;
export const GRID_GAP = 16; // px

export const MAX_ITEMS_PER_ROW = 4;
export const LG_ITEMS_PER_ROW = 3;
export const MD_ITEMS_PER_ROW = 2;
export const SM_ITEMS_PER_ROW = 1;
export const BREAKPOINT_LG = 968;
export const BREAKPOINT_MD = 730;
export const BREAKPOINT_SM = 500;

// Settings

export const DEFAULT_TIMEFRAME_PICKER_OPTION = TimeframeOption.AllTime;
export const LAST_SET_TIMEFRAME_OPTION = 'perspective.metrics.timeframe';

// Chart

export const ADVANCED_CHARTS = [AvailableCharts.TimeOnPage, AvailableCharts.TopUTMSources];

export const ELLIPSABLE_TEXT_MEASURE_ID = 'labelSizeMeasure';

export const STATUS_GAP = 4;
export const STATUS_MIN_WIDTH = 4;
export const BAR_CHART_COLUMN_ITEMS_THRESHOLD = 10;
export const HORIZONTAL_BAR_CHART_MOBILE_ITEM_HEIGHT = 40;
export const HORIZONTAL_BAR_CHART_LABEL_WIDTH = {
    desktop: 52,
    mobile: 48,
};
export const TOP_ANSWERS_COLUMN_ITEMS_THRESHOLD = 6;
export const TOP_ANSWERS_MOBILE_ROW_HEIGHT = 48;
export const TOP_ANSWERS_MOBILE_PREVIEW_ROW_HEIGHT = 36;
export const TOP_ANSWERS_MIN_ROW_HEIGHT = 34;
export const TOP_ANSWERS_BAR_RADIUS = {
    preview: 2,
    dashboard: 4,
};
export const TOP_ANSWERS_BAR_SIZE = {
    sm: 18,
    md: 24,
    lg: 32,
};
export const BAR_CHART_MAX_BAR_SIZE = {
    preview: 24,
    desktop: 37,
};

export const DAY_NAMES = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const;

export const DEFAULT_DATETIME_DATA_RANGE = {
    '2am': 0,
    '4am': 0,
    '6am': 0,
    '8am': 0,
    '10am': 0,
    '12pm': 0,
    '2pm': 0,
    '4pm': 0,
    '6pm': 0,
    '8pm': 0,
    '10pm': 0,
    '12am': 0,
};

export const DEFAULT_DATETIME_DATA: ActivityByDatetimeData = {
    monday: { ...DEFAULT_DATETIME_DATA_RANGE },
    tuesday: { ...DEFAULT_DATETIME_DATA_RANGE },
    wednesday: { ...DEFAULT_DATETIME_DATA_RANGE },
    thursday: { ...DEFAULT_DATETIME_DATA_RANGE },
    friday: { ...DEFAULT_DATETIME_DATA_RANGE },
    saturday: { ...DEFAULT_DATETIME_DATA_RANGE },
    sunday: { ...DEFAULT_DATETIME_DATA_RANGE },
};

export const EXPORT_METRIC_BACKGROUND_OPTIONS: ExportMetricBgOption[] = [
    { id: 'roseThorn', url: imgix.metrics.backgrounds.roseThorn },
    {
        id: 'glassRainbow',
        url: imgix.metrics.backgrounds.glassRainbow,
    },
    { id: 'moonrise', url: imgix.metrics.backgrounds.moonrise },
];

const DEFAULT_EXPORT_ASPECT_RATIO = 16 / 9;
export const DEFAULT_EXPORT_IMAGE_WIDTH = 3840;
export const DEFAULT_EXPORT_IMAGE_HEIGHT = DEFAULT_EXPORT_IMAGE_WIDTH / DEFAULT_EXPORT_ASPECT_RATIO;

// AB Test

export const AB_TEST_CHARTS_DATA = [
    { metricId: 'pageConversionRate', dataKey: 'conversion_rate' },
    { metricId: 'pageCompletionRate', dataKey: 'completion_rate' },
    { metricId: 'timeOnPage', dataKey: 'time_page' },
    { metricId: 'visits', dataKey: 'sessions' },
];

// Colors

const generateColors = <T extends number>(
    color: 'blue' | 'red' | 'gray' | 'white',
    values?: readonly T[],
) => {
    if (!values) {
        return `var(--color-${color})`;
    }

    return Object.fromEntries(
        values.map((value) => [value, `var(--color-${color}-${value})`]),
    ) as Record<T, string>;
};

export const DEFAULT_COLOR_VALUES = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950] as const;

// Also extend the color list in `tailwind.config.js` when adding a new color here
export const VAR_COLOR = {
    BLUE: generateColors<(typeof DEFAULT_COLOR_VALUES)[number]>('blue', DEFAULT_COLOR_VALUES),
    RED: generateColors<(typeof DEFAULT_COLOR_VALUES)[number]>('red', DEFAULT_COLOR_VALUES),
    GRAY: generateColors<(typeof DEFAULT_COLOR_VALUES)[number]>('gray', DEFAULT_COLOR_VALUES),
    WHITE: generateColors('white') as string,
};

// Session Storage

export const METRICS_DASH_VIEW_TRACKED_KEY = 'perspective.metrics.dash-viewed';

// React-query

export const QUERY_KEYS = {
    dashboard: (campaignId: string) => ['dashboard', campaignId],
    dashboardAnalytics: (campaignId: string, from?: string, to?: string) => [
        'dashboardAnalytics',
        campaignId,
        ...(from ? [from] : []),
        ...(to ? [to] : []),
    ],
    widgetPreview: (campaignId: string, widgetType: WidgetSubtype, from?: string, to?: string) => [
        'widgetPreview',
        campaignId,
        widgetType,
        ...(from ? [from] : []),
        ...(to ? [to] : []),
    ],
} as const;

export const ENDPOINTS = {
    GET: {
        dashboard: (campaignId: string) => `/dashboard/campaigns/${campaignId}`,
        dashboardAnalytics: (dashboardId: string) => `/analytics/dashboard/${dashboardId}`,
        widgetPreview: (campaignId: string, widgetType: WidgetSubtype) =>
            `/dashboard/campaigns/${campaignId}/widgets/${widgetType}/preview`,
    },
    POST: {
        addWidget: (campaignId: string) => `/dashboard/campaigns/${campaignId}/widget`,
        manageDashboardContent: (campaignId: string) =>
            `/dashboard/campaigns/${campaignId}/widgets/bulk_edit`,
    },
    PUT: {
        updateWidgetOrder: (campaignId: string) => `/dashboard/campaigns/${campaignId}/order`,
    },
    DELETE: {
        removeWidget: (widgetId: string) => `/dashboard/widgets/${widgetId}`,
        deleteDashboard: (campaignId: string) => `/analytics/kpis?campaign=${campaignId}`,
    },
} as const;

export const MUTATION_KEYS = {
    addWidget: (campaignId: string) => ['dashboard', 'add', campaignId],
    removeWidget: (campaignId: string) => ['dashboard', 'remove', campaignId],
    manageDashboardContent: (campaignId: string) => ['dashboard', 'bulk-edit', campaignId],
    updateWidgetOrder: (campaignId: string) => ['dashboard', 'updateOrder', campaignId],
    deleteDashboard: (campaignId: string) => ['dashboard', 'delete', campaignId],
} as const;

export const ROUTES = {
    // @todo(AD): Use it's own Metrics AB Test page url
    metricsABTest: (campaignId: string) => `/funnel/${campaignId}/analytics/ab-test`,
    metricsSessions: (campaignId: string) => `/funnel/${campaignId}/metrics/sessions`,
    metricsAnalytics: (campaignId: string) => `/funnel/${campaignId}/analytics/dashboard`,
    // @todo(AD): Use it's own Metrics page url
    metrics: (campaignId: string) => `/funnel/${campaignId}/analytics/metrics`,
} as const;
