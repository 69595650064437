import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';
import { reportError } from '@/utils/sentry';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    uploading: string[]; // file names of assets that are currently being uploaded
    progress: Record<string, number>; // progress of each asset being uploaded
}

const initialState: State = {
    uploading: EMPTY_ARRAY,
    progress: EMPTY_OBJECT,
};

export const assetsSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setUploading(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                uploading: action.payload,
            };
        },
        setProgress(state, action: PayloadAction<Record<string, number>>) {
            return {
                ...state,
                progress: action.payload,
            };
        },
    },
});

// === Actions ======

export const { setUploading, setProgress } = assetsSlice.actions;

// === Selectors ======

const getUploading = (state: AppState) => state[NAME].assetsReducer.uploading;

const getProgress = (state: AppState) => state[NAME].assetsReducer.progress;

// Not used yet, but will come in handy when we want to show progress for bigger file uploads
// const getProgressForFile = (state: AppState, fileName: string) => getProgress(state)[fileName];

// === Thunks ======

// This will upload an asset to the company ID of the passed Auth token
export const uploadAsset =
    (file: File, fileType: string | undefined): AppThunk<Promise<string>> =>
    async (dispatch, getState) => {
        const normalizedFileType = fileType?.toLowerCase();

        try {
            const currentlyUploading = getUploading(getState());

            // 1. Add file to uploading list
            dispatch(setUploading([...currentlyUploading, file.name]));

            // 2. Create pre-signed S3 upload URL
            const urlResponse = await apiPost(`/assets?fileType=${normalizedFileType}`, {});
            const {
                id, // This id will be used as the file name in S3: <id>.<fileType>
                attributes: { preSignedUrl },
            } = getDataFromResponse(urlResponse, { id: '', attributes: {} });

            if (!preSignedUrl) {
                throw Error('Creating pre-signed URL failed');
            }

            // 3. Upload to pre-signed S3 URL
            await axios.put(preSignedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
                onUploadProgress: (progressEvent) => {
                    const currentProgress = getProgress(getState());

                    if (!progressEvent.total) {
                        reportError({
                            error: 'Attempted to update file upload progress with unknown total size',
                            source: 'runtime',
                        });
                    }

                    // 4. Update progress
                    dispatch(
                        setProgress({
                            ...currentProgress,
                            [file.name]: progressEvent.total
                                ? Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                : 0,
                        }),
                    );
                },
            });

            // 4. Mark as complete
            await apiPost(`/assets/${id}/upload-finished`, {});

            // 5. Remove file from uploading list
            dispatch(
                setUploading(
                    currentlyUploading.filter(
                        (uploadingFileName) => uploadingFileName !== file.name,
                    ),
                ),
            );

            // 6. Return uploaded asset ID
            return id;
        } catch (err) {
            handleRuntimeError(err, { message: 'Uploading asset failed' });

            return Promise.reject(err);
        }
    };

export default assetsSlice.reducer;
