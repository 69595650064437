import { SparklesIcon } from '@heroicons/react/24/outline';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    animationName: string;
    input: WrappedFieldInputProps;
    submit: () => void;
}

const Animation = ({ input, submit, animationName = 'jittery' }: Props) => {
    const { value, onChange } = input;

    const handleClick = () => {
        const newValue = value === '' ? animationName : '';

        onChange(newValue);

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <FieldContainer>
            <Tooltip content="Animation">
                <button
                    className="bump flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 text-gray-400 hover:text-gray-600"
                    onClick={handleClick}
                    type="button"
                >
                    <SparklesIcon
                        className={cn(
                            'size-4',
                            value === animationName ? 'text-blue-500' : 'text-inherit',
                        )}
                    />
                </button>
            </Tooltip>
        </FieldContainer>
    );
};

export default Animation;
