import { NAME } from '@/app/editor/editor/constants';

import { PlusIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';

import { getActivePageBlockIds } from '@/app/editor/blocks/models/blockOrder';
import { getHeaderAndFooterIds, setActiveBlockId } from '@/app/editor/blocks/models/blocks';
import {
    setInsertingInBlankColumnId,
    setPreviewBlockId,
} from '@/app/editor/sections/models/insert';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { Tooltip } from '@/ui/components/Tooltip';

import { setActiveView } from '../../../models/sidebar';

import type { MouseEvent } from 'react';

export interface Props {
    columnBlockId?: string;
}

export const AddBlockButton = ({ columnBlockId }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const pageBlockIds = useAppSelector(getActivePageBlockIds);
    const headerAndFooterBlockIds = useAppSelector(getHeaderAndFooterIds);

    const handleClick = (event: MouseEvent) => {
        event.stopPropagation();

        // Change Sidebar view
        if (columnBlockId) {
            dispatch(setActiveView('addBlockToColumn'));
            dispatch(setInsertingInBlankColumnId(columnBlockId));
        } else {
            dispatch(setActiveView('addBlock'));
        }

        // set previewBlockId to last blockId on page or headerId if there are no blocks yet
        const previewBlockId =
            pageBlockIds[pageBlockIds.length - 1] || headerAndFooterBlockIds.headerId;
        dispatch(setPreviewBlockId(columnBlockId || previewBlockId));

        // Deselect currently selected Block
        dispatch(setActiveBlockId(''));
    };

    return (
        <Tooltip
            content={t(columnBlockId ? 'add-block-to-column' : 'add-block')}
            placement="bottom"
        >
            <button
                className="mx-auto my-4 flex size-16 cursor-pointer items-center justify-center rounded-full border bg-white text-gray-400 shadow-md outline-none transition-all hover:scale-110 hover:border-blue-500 hover:text-blue-500"
                onClick={handleClick}
            >
                <PlusIcon className="size-7" />
            </button>
        </Tooltip>
    );
};
