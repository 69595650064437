import { ORIGIN_MAP } from '@/ui/constants';

import {
    arrow,
    offset,
    flip,
    shift,
    autoUpdate,
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    useHover,
    useId,
    useClick,
    safePolygon,
    FloatingFocusManager,
    FloatingPortal,
    FloatingArrow,
    size,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneElement, useEffect, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { cn } from '@/utils/cn';

import type {
    Placement,
    Middleware,
    UseFloatingOptions,
    OpenChangeReason,
} from '@floating-ui/react';
import type { ReactNode } from 'react';

export interface Props {
    open?: boolean;
    openOnHover?: boolean;
    render: (data: { popoverId: string }) => ReactNode;
    onExit?: () => void;
    placement?: Placement;
    children: JSX.Element;
    mainAxisOffsetValue?: number;
    crossAxisOffsetValue?: number;
    fallbackPlacements?: Placement[];
    onOpenChange?: (open: boolean, reason?: OpenChangeReason) => void;
    isAnimated?: boolean;
    hasShift?: boolean;
    flipCrossAxis?: boolean;
    zIndexClass?: string;
    middleware?: Middleware[];
    showArrow?: boolean;
    arrowClassName?: string;
    elements?: UseFloatingOptions['elements'];
    sameWidthAsReference?: boolean;
    disabled?: boolean;
    delay?: number;
}

const APPEAR_ANIMATION_DURATION = 500;

export const Popover = ({
    children,
    open: openFromProps,
    openOnHover = false,
    onExit,
    render,
    placement,
    mainAxisOffsetValue = 5,
    crossAxisOffsetValue = 0,
    onOpenChange,
    fallbackPlacements,
    isAnimated = true,
    hasShift = true,
    flipCrossAxis = true,
    zIndexClass = 'z-50',
    middleware: extendedMiddleware = [],
    arrowClassName,
    showArrow = false,
    elements,
    sameWidthAsReference = false,
    disabled = false,
    delay = 0,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [animatePosition, setAnimatePosition] = useState(false);
    const arrowRef = useRef(null);

    const isOpen = openFromProps ?? open;

    useEffect(() => {
        if (isOpen && isAnimated) {
            setTimeout(() => {
                if (isOpen) {
                    setAnimatePosition(true);
                }
            }, APPEAR_ANIMATION_DURATION); // Wait until initial appear animation has finished
        } else {
            setAnimatePosition(false);
        }
    }, [isAnimated, isOpen]);

    const handleOpenChange = (open: boolean, _: Event, reason?: OpenChangeReason) => {
        setOpen(open);

        onOpenChange?.(open, reason);
    };

    const middleware = [
        offset({
            mainAxis: showArrow ? mainAxisOffsetValue + 5 : mainAxisOffsetValue,
            crossAxis: crossAxisOffsetValue,
        }),
        flip({
            crossAxis: flipCrossAxis,
            fallbackPlacements,
        }),
        arrow({
            element: arrowRef,
        }),
        ...(sameWidthAsReference
            ? [
                  size({
                      apply({ rects, elements }) {
                          Object.assign(elements.floating.style, {
                              width: `${rects.reference.width}px`,
                          });
                      },
                  }),
              ]
            : []),
        ...extendedMiddleware,
    ];

    if (hasShift) {
        middleware.push(shift());
    }

    const {
        x,
        y,
        strategy,
        context,
        refs,
        placement: calculatedPlacement,
    } = useFloating({
        open: isOpen,
        onOpenChange: handleOpenChange,
        middleware,
        placement,
        whileElementsMounted: autoUpdate,
        elements,
    });

    const popoverId = useId();

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context, {
            enabled: !openOnHover && !disabled,
        }),
        useHover(context, {
            enabled: openOnHover,
            handleClose: safePolygon(),
            delay,
        }),
        useRole(context),
        useDismiss(context),
    ]);

    // Preserve the consumer's ref
    const ref = useMemo(
        () => mergeRefs([refs.setReference, (children as any).ref]),
        [refs, children],
    );

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref, ...children.props }))}

            <AnimatePresence onExitComplete={onExit}>
                {isOpen && !disabled && (
                    <FloatingPortal>
                        <FloatingFocusManager
                            context={context}
                            modal={false}
                            order={['reference', 'content']}
                            returnFocus={false}
                        >
                            <motion.div
                                className={cn(
                                    'z-popover outline-none',
                                    zIndexClass,
                                    ORIGIN_MAP[calculatedPlacement],
                                    {
                                        'transition-all duration-300 ease-out':
                                            animatePosition && isAnimated,
                                    },
                                )}
                                // Framer motion
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
                                // Floating UI
                                ref={refs.setFloating}
                                style={{
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                }}
                                {...getFloatingProps()}
                            >
                                {showArrow && (
                                    <FloatingArrow
                                        context={context}
                                        ref={arrowRef}
                                        className={cn(
                                            '-translate-y-px fill-white [&>path:first-of-type]:stroke-gray-200',
                                            arrowClassName,
                                        )}
                                        tipRadius={1}
                                        strokeWidth={0.5}
                                        style={{ transform: 'translateY(-1px)' }}
                                    />
                                )}
                                {render({ popoverId })}
                            </motion.div>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};

export default Popover;
