import { isMobile } from 'react-device-detect';

import { FieldType, FixedColumnId, SidebarTab } from '@/app/crm/types';

import type { ModalView } from './components/StatusTemplates/StatusTemplateContainer/types';
import type {
    CRMFieldName,
    CRMFilterType,
    Pagination,
    Status,
    TagTheme,
    ThemeColor,
} from '@/app/crm/types';

export const NAME = 'crm';

export const MAX_STARS = 5;

// Note that some of the colors are raw colors. We are adding some exceptions with status colors
// that includes colors outside of the color palette we are using in tailwind. There are no plans
// to reuse these new colors for other items so keeping them here to reduce its scope.
export const STATUS_COLOR_MAP: Record<ThemeColor, TagTheme> = {
    red: {
        background: 'bg-red-50',
        backgroundSolid: 'bg-[#FA8383]',
        selectorBgColor: 'bg-red-100',
        selectorBorderColor: 'border-[#FEA7A7]',
        colorPickerBackground: 'bg-red-600',
        text: 'text-red-600',
        hoverOutline: 'outline-red-200',
        border: 'border-red-100',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-red-100',
    },
    orange: {
        background: 'bg-orange-50',
        backgroundSolid: 'bg-[#FDBA74]',
        selectorBgColor: 'bg-orange-100',
        selectorBorderColor: 'border-[#FDBA74]',
        colorPickerBackground: 'bg-orange-600',
        text: 'text-orange-600',
        hoverOutline: 'outline-orange-200',
        border: 'border-orange-100',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-orange-100',
    },
    amber: {
        background: 'bg-[#FEFBED]',
        backgroundSolid: 'bg-[#FEFBED]',
        selectorBgColor: 'bg-[#FEFBED]',
        selectorBorderColor: 'border-[#F6D43B]',
        colorPickerBackground: 'bg-[#CC7C2E]',
        text: 'text-[#CC7C2E]',
        hoverOutline: 'outline-amber-200',
        border: 'border-[#F9E796]',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-[#F9E796]',
    },
    green: {
        background: 'bg-green-50',
        backgroundSolid: 'bg-[#82D6A1]',
        selectorBgColor: 'bg-green-100',
        selectorBorderColor: 'border-[#82D6A1]',
        colorPickerBackground: 'bg-green-600',
        text: 'text-green-600',
        hoverOutline: 'outline-green-200',
        border: 'border-green-200',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-green-100',
    },
    lime: {
        background: 'bg-[#F5FEE9]',
        backgroundSolid: 'bg-[#ADE64C]',
        selectorBgColor: 'bg-[#D1F9B9]',
        selectorBorderColor: 'border-[#ADE64C]',
        colorPickerBackground: 'bg-[#6EBC00]',
        text: 'text-[#6EBC00]',
        hoverOutline: 'outline-lime-200',
        border: 'border-[#D1F9B9]',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-[#D1F9B9]',
    },
    blue: {
        background: 'bg-blue-50',
        backgroundSolid: 'bg-[#78A5FF]',
        selectorBgColor: 'bg-blue-100',
        selectorBorderColor: 'border-[#78A5FF]',
        colorPickerBackground: 'bg-blue-500',
        text: 'text-blue-500',
        hoverOutline: 'outline-blue-100',
        border: 'border-blue-100',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-blue-50',
    },
    cyan: {
        background: 'bg-[#EFFDFF]',
        backgroundSolid: 'bg-[#1EDCF5]',
        selectorBgColor: 'bg-[#D7F9FD]',
        selectorBorderColor: 'border-[#1EDCF5]',
        colorPickerBackground: 'bg-[#0AB1F3]',
        text: 'text-[#0AB1F3]',
        hoverOutline: 'outline-cyan-200',
        border: 'border-[#D7F9FD]',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-[#D7F9FD]',
    },
    sky: {
        background: 'bg-[#ECF5FE]',
        backgroundSolid: 'bg-[#7ABAFD]',
        selectorBgColor: 'bg-[#CEE4FB]',
        selectorBorderColor: 'border-[#7ABAFD]',
        colorPickerBackground: 'bg-[#095499]',
        text: 'text-[#095499]',
        hoverOutline: 'outline-sky-200',
        border: 'border-[#CEE4FB]',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-[#CEE4FB]',
    },
    purple: {
        background: 'bg-purple-50',
        backgroundSolid: 'bg-[#D8B4FE]',
        selectorBgColor: 'bg-purple-100',
        selectorBorderColor: 'border-[#D8B4FE]',
        colorPickerBackground: 'bg-purple-600',
        text: 'text-purple-600',
        hoverOutline: 'outline-purple-200',
        border: 'border-purple-100',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-purple-100',
    },
    fuchsia: {
        background: 'bg-[#FBF4FE]',
        backgroundSolid: 'bg-[#FDADFF]',
        selectorBgColor: 'bg-[#F7E9FD]',
        selectorBorderColor: 'border-[#FDADFF]',
        colorPickerBackground: 'bg-[#E744EA]',
        text: 'text-[#E744EA]',
        hoverOutline: 'outline-fuchsia-200',
        border: 'border-[#F7E9FD]',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-[#F7E9FD]',
    },
    black: {
        background: 'bg-gray-200',
        backgroundSolid: 'bg-gray-400',
        selectorBgColor: 'bg-gray-200',
        selectorBorderColor: 'border-gray-400',
        colorPickerBackground: 'bg-gray-900',
        text: 'text-gray-900',
        hoverOutline: 'outline-gray-300',
        border: 'border-gray-300',
        gradientFrom: 'from-blue-500',
        gradientTo: 'to-gray-200',
    },
    gray: {
        background: 'bg-gray-50',
        backgroundSolid: 'bg-gray-300',
        selectorBgColor: 'bg-gray-100',
        selectorBorderColor: 'border-gray-300',
        colorPickerBackground: 'bg-gray-600',
        text: 'text-gray-600',
        hoverOutline: 'outline-gray-200',
        border: 'border-gray-200',
        gradientFrom: 'from-blue-100',
        gradientTo: 'to-gray-100',
    },
} as const;

export type StatusColors = keyof typeof STATUS_COLOR_MAP;

export const STATUS_OPTION_COLORS: ThemeColor[] = Object.keys(STATUS_COLOR_MAP) as ThemeColor[];

export const PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS = 'prevent-sidebar-click-outside';
export const PREVENT_HEADER_CELL_CLICK_OUTSIDE_CLASS = 'prevent-header-cell-click-outside';
export const STATUS_OPTION_FOCUS_HANDLE_ID = 'status-option-focus-handle';

export const DEFAULT_WORKSPACE_STATUS: Status[] = [
    {
        value: 'New',
        color: 'blue',
    },
    {
        value: 'In Review',
        color: 'fuchsia',
    },
    {
        value: 'Document Requested',
        color: 'sky',
    },
    {
        value: 'Rejected',
        color: 'red',
    },
    {
        value: 'Hired',
        color: 'green',
    },
];

export const DEFAULT_VIEW: ModalView = { view: 'start' };

// Table View

export const CONTACT_ROW_HEIGHT = 45;

export const CELL_WIDTHS = {
    date: 180,
    email: 280,
    string: 280,
    select: 200,
    status: 100,
    default: 200,
    person: isMobile ? 200 : 250,
    file: 220,
};

export const LAST_EDITED_FIELD_NAME = 'ps_user_edited_at';
export const SOURCE_FIELD_NAME = 'ps_source';

export const SORTING_FIELD_MAP = {
    [FixedColumnId.person]: 'person',
    [FixedColumnId.converted_at]: 'ps_converted_at',
};

export const FIXED_COLUMNS = [FixedColumnId.person, FixedColumnId.converted_at];

export const UNNAMED_PERSON = 'unnamed-person';

export const LS_CELLS_WRAP_ENABLED_KEY = 'cells-wrap-enabled';

// Profile

export const SIDEBAR_CONTENT_WRAPPER_ID = 'sidebar-content-wrapper';
export const PROPERTIES_SIDEBAR_WRAPPER_ID = 'properties-sidebar-wrapper';
export const PROPERTIES_TABLE_WRAPPER_ID = 'properties-table-wrapper';
export const PROPERTY_EDIT_BUTTON_ID = 'crm-property-edit-button';
export const SIDEBAR_WIDTH_BREAKPOINT = 450;
export const SIDEBAR_TAB_LIST = [
    SidebarTab.profile,
    SidebarTab.notes,
    SidebarTab.messages,
    SidebarTab.activity,
];
export const PROFILE_NOTES_PAGE_LIMIT = 20;

// Timestamp limits

const SECOND = 1000;
export const MINUTE_DURATION_LIMIT = 60 * SECOND; // 1m in ms
export const HOUR_DURATION_LIMIT = 60 * MINUTE_DURATION_LIMIT; // 1h in ms
export const DAY_DURATION_LIMIT = 24 * HOUR_DURATION_LIMIT; // 1d in ms
export const MONTH_DURATION_LIMIT = 30 * DAY_DURATION_LIMIT; // 1m in ms
export const YEAR_DURATION_LIMIT = 12 * MONTH_DURATION_LIMIT; // 1y in ms

// Properties

export const TOGGLE_PROPERTY_MIN_TOOLTIP_CHAR_LIMIT = 28;

export const ADD_NEW_PROPERTY_LIST = [
    FieldType.text,
    FieldType.select,
    FieldType.selectMulti,
    FieldType.date,
    FieldType.url,
    FieldType.email,
    FieldType.phone,
    FieldType.file,
];

export const PROPERTY_NO_COPY_LIST = [FieldType.select, FieldType.selectMulti];

export const PROPERTY_PLACEHOLDER_KEY_MAP = {
    [FieldType.text]: 'add-text',
    [FieldType.email]: 'add-email',
    [FieldType.url]: 'add-link',
    [FieldType.phone]: 'add-phone',
    [FieldType.number]: 'add-number',
};

export const LAST_CRM_VIEW_KEY = 'perspective.crm.last-view';
export const LAST_CRM_SAVED_FILTER_KEY = 'perspective.crm.saved-filter';
export const LAST_CRM_SIDEBAR_LAST_TAB_KEY = 'perspective.crm.sidebar.last-tab';

export const SIDEBAR_MIN_WIDTH = {
    MOBILE: 320,
    DESKTOP: 450,
};
export const SIDEBAR_MAX_WIDTH = '50vw';
export const SIDEBAR_WIDTH_KEY = 'perspective.crm.sidebar-width';

export const DEFAULT_SELECT_PROPERTY_OPTIONS = [
    { value: 'Option 1' },
    { value: 'Option 2' },
    { value: 'Option 3' },
    { value: 'Option 4' },
];

// Kanban View

export const COLUMNS_WRAPPER_ID = 'columnsWrapper';
export const CONTACT_CARD_DEFAULT_HEIGHT = 107; // Name + separator + properties + footer
export const CONTACT_CARD_PROPERTY_HEIGHT = 40; // Property height + margin
export const COLUMN_VIRTUAL_LIST_OFFSET = 75;
export const COLUMN_HIDING_ANIMATION_DURATION = 400;
export const SEARCH_DEBOUNCED_WAIT = 200;
export const HIDDEN_COLUMNS_WRAPPER_ID = 'hiddenColumnsWrapper';

export const KANBAN_DEFAULT_SORTING = { sortField: 'updatedAt', sortOrder: -1 };
export const KANBAN_DEFAULT_PAGINATION = { page: 0, limit: 25 };
export const MULTI_SELECT_SEPARATOR = '; ';

// File uploads
export const MAX_FILE_SIZE = 25000000; // Max 25 MB
export const MAX_FILE_COUNT = 1;
export const ACCEPTED_FILES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/apng': ['.apng'],
    'image/svg+xml': ['.svg'],
    'image/gif': ['.gif'],
    'audio/aac': ['.aac'],
    'audio/wav': ['.wav'],
    'video/quicktime': ['.mov', '.qt'],
    'video/x-msvideo': ['.avi'],
    'audio/webm': ['.weba'],
    'video/webm': ['.webm'],
    'image/webm': ['.webp'],
    'text/plain': ['.txt'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'video/mp4': ['.mp4'],
    'video/mpeg': ['.mpeg'],
    'application/pdf': ['.pdf'],
    'text/csv': ['.csv'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/xml': ['.xml'],
    'application/gzip': ['.gz'],
    'application/zip': ['.zip'],
    'application/vnd.rar': ['.rar'],
    'application/json': ['.json'],
};

export const DUPLICATE_RESOURCE_ERROR_CODE = 101;
export const INVALID_DATA_ERROR_CODE = 100;

export const EMPTY_PAGINATION: Pagination = {
    count: 0,
    limit: 25,
    next: null,
    page: 0,
};

// Filters

export const TEXT_FILTER_TYPES = [
    'is',
    'is-not',
    'starts-with',
    'ends-with',
    'contains',
    'does-not-contain',
    'has-no-value',
    'has-any-value',
] as const;

const STATUS_FILTER_TYPES = ['in'] as const;

export const RELATIVE_DATE_FILTER_TYPES = [
    'relative-more-than',
    'relative-less-than',
    'relative-exactly',
] as const;
export const ABSOLUTE_DATE_FILTER_TYPES = [
    'absolute-after',
    'absolute-before',
    'absolute-on',
] as const;
export const DATE_FILTER_TYPES = [
    ...RELATIVE_DATE_FILTER_TYPES,
    ...ABSOLUTE_DATE_FILTER_TYPES,
] as const;

export const FILTER_TYPES = [
    ...TEXT_FILTER_TYPES,
    ...STATUS_FILTER_TYPES,
    ...DATE_FILTER_TYPES,
] as const;
export const VALUELESS_TEXT_FILTER_TYPES: CRMFilterType[] = ['has-no-value', 'has-any-value'];

export const FILTERABLE_FIELD_NAMES = [
    'status',
    'person',
    'name',
    'email',
    'firstName',
    'lastName',
    'createdAt',
    'phone',
    'country',
    'zip',
    'street',
    'city',
    'website',
] as const;

export const DATE_FIELD_NAMES: CRMFieldName[] = ['createdAt'];

export const LS_CRM_FILTERS_OPENED = 'crm-filters-bar-opened';
export const LS_CRM_FILTERS = 'crm-filters';
export const SAVED_FILTER_I8N_VALUE = 'New contacts (last 7 days)';

export const MAX_SAVED_FILTERS = 20;

// Bellow you can find maps to encode filters into short URL search params
export const FIELD_NAME_PARAM_DECODE: Record<string, CRMFieldName> = {
    c: 'country',
    ca: 'createdAt',
    ct: 'city',
    e: 'email',
    fn: 'firstName',
    ln: 'lastName',
    nm: 'name',
    p: 'person',
    pn: 'phone',
    s: 'status',
    st: 'street',
    w: 'website',
    z: 'zip',
};

export const FIELD_NAME_PARAM_ENCODE = Object.fromEntries(
    Object.entries(FIELD_NAME_PARAM_DECODE).map(([key, value]) => [value, key]),
) as Record<CRMFieldName, string>;

export const FILTER_TYPE_PARAM_DECODE: Record<string, CRMFilterType> = {
    aa: 'absolute-after',
    ab: 'absolute-before',
    ao: 'absolute-on',
    c: 'contains',
    ew: 'ends-with',
    i: 'is',
    in: 'in',
    nc: 'does-not-contain',
    ni: 'is-not',
    nv: 'has-no-value',
    re: 'relative-exactly',
    rlt: 'relative-less-than',
    rmt: 'relative-more-than',
    st: 'starts-with',
    v: 'has-any-value',
};

export const FILTER_TYPE_PARAM_ENCODE = Object.fromEntries(
    Object.entries(FILTER_TYPE_PARAM_DECODE).map(([key, value]) => [value, key]),
) as Record<CRMFilterType, string>;

export const SERVER_FILTER_TO_FILTER_TYPE: Record<string, CRMFilterType> = {
    'string-startsWith': 'starts-with',
    'string-endsWith': 'ends-with',
    'string-equals': 'is',
    'string-in': 'in',
    'string-contains': 'contains',
    'string-doesNotContain': 'does-not-contain',
    'string-doesNotEqual': 'is-not',
    'string-hasNoValue': 'has-no-value',
    'string-hasAnyValue': 'has-any-value',
    'date-exactlyDaysAgo': 'relative-exactly',
    'date-lessThanDaysAgo': 'relative-less-than',
    'date-moreThanDaysAgo': 'relative-more-than',
    'date-equals': 'absolute-on',
    'date-gte': 'absolute-after',
    'date-lte': 'absolute-before',
};

export const FILTER_TYPE_TO_SERVER_FILTER: Record<string, string> = {
    'starts-with': 'startsWith',
    'ends-with': 'endsWith',
    is: 'equals',
    'is-not': 'doesNotEqual',
    contains: 'contains',
    in: 'in',
    'does-not-contain': 'doesNotContain',
    'has-no-value': 'hasNoValue',
    'has-any-value': 'hasAnyValue',
    'relative-exactly': 'exactlyDaysAgo',
    'relative-less-than': 'lessThanDaysAgo',
    'relative-more-than': 'moreThanDaysAgo',
    'absolute-before': 'lte',
    'absolute-after': 'gte',
    'absolute-on': 'equals',
};

export const IMPORT_CONTACTS_LINK =
    'https://zapier.com/apps/facebook-lead-ads/integrations/perspective';

// React query

export const ENDPOINTS = {
    GET: {
        savedFilters: () => '/contacts/filters',
        profileNotes: (campaignId: string, contactId: string, page: number, limit: number) =>
            `/notes?campaignId=${campaignId}&contactId=${contactId}&limit=${limit}&page=${page}`,
    },
    POST: {
        addContact: () => '/contacts',
        savedFilters: () => '/contacts/filters',
        exportContacts: (campaignId: string) => `/contacts/export?campaignId=${campaignId}`,
        profileNote: (campaignId: string, contactId: string) =>
            `/notes?campaignId=${campaignId}&contactId=${contactId}`,
    },
    PUT: {
        updateContact: () => '/values',
    },
    PATCH: {
        savedFilters: (campaignId: string, filterId: string) =>
            `/contacts/filters/${filterId}?campaignId=${campaignId}`,
        profileNote: (campaignId: string, contactId: string, noteId: string) =>
            `/notes/${noteId}?campaignId=${campaignId}&contactId=${contactId}`,
    },
    DELETE: {
        savedFilters: (campaignId: string, filterId: string) =>
            `/contacts/filters/${filterId}?campaignId=${campaignId}`,
        profileNote: (campaignId: string, contactId: string, noteId: string) =>
            `/notes/${noteId}?campaignId=${campaignId}&contactId=${contactId}`,
    },
} as const;

export const QUERY_KEYS = {
    profileNotes: (campaignId: string, contactId: string) => [
        'profileNotes',
        contactId,
        campaignId,
    ],
    savedFilters: (campaignId: string) => ['savedFilters', campaignId],
} as const;

export const MUTATION_KEYS = {
    addSavedFilter: (campaignId: string) => ['savedFilter', 'add', campaignId],
    removeSavedFilter: (filterId: string) => ['savedFilter', 'remove', filterId],
    updateSavedFilter: () => ['savedFilter', 'update'],
    exportContacts: () => ['contacts', 'export'],
    addContact: () => ['contacts', 'add'],
    updateContact: () => ['contacts', 'update'],
    addProfileNote: () => ['contacts', 'profileNotes', 'add'],
    editProfileNote: (noteId: string) => ['contacts', 'profileNotes', 'edit', noteId],
    removeProfileNote: (noteId: string) => ['contacts', 'profileNotes', 'remove', noteId],
} as const;
