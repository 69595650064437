import { BlockComponentType, BlockIntro, type BoxSizeOption } from '@/app/editor/blocks/types';
import { Modals } from '@/app/modals/types';

import type { AiLanguageOption, AiToneOption, BoxProps } from '@/app/editor/blocks/types';

export const NAME = 'blocks';

// Personalization
export const PERSONALIZATION_HELP_ARTICLE_LINK =
    'https://intercom.help/perspective-funnels/de/articles/5533414-wie-funktioniert-personalisierung';
export const PERSONALIZATION_TRIGGER = '@';
export const NO_PERSONALIZATION_BLOCK_PARENTS = [
    BlockComponentType.QUESTION_MEDIA_ANSWER,
    BlockComponentType.QUESTION_TEXT_ANSWER,
    BlockComponentType.FORM,
    BlockComponentType.FORM_URL,
    BlockComponentType.ACCORDION_ITEM,
];
export const LIGHTNESS_DEPENDENT_BLOCKS = [BlockComponentType.BUTTON, BlockComponentType.SUBMIT];

export const NO_FONT_SIZE_BLOCK_PARENTS = [BlockComponentType.LIST_ITEM];

// Form
export const CONVERSION_INPUTS = ['email', 'phone'];

export const UNIQUE_INPUTS = [
    ...CONVERSION_INPUTS,
    'name',
    'firstName',
    'lastName',
    'birthday',
    'country',
    'city',
    'street',
    'website',
    'zip',
];

export const UNIQUE_INPUTS_IN_FORM = [...UNIQUE_INPUTS, 'file'];

// Border Menu
export const MINIMUM_CHILD_COUNT = {
    [BlockComponentType.LIST]: 1,
    [BlockComponentType.QUESTION_MEDIA]: 2,
    [BlockComponentType.QUESTION_TEXT]: 2,
    [BlockComponentType.QUESTION_MULTIPLE_CHOICE]: 3,
    [BlockComponentType.FORM]: 2,
    [BlockComponentType.FORM_URL]: 3,
    [BlockComponentType.ACCORDION]: 1,
    [BlockComponentType.GRID_ROW]: 1,
};

// Calendly
export const CALENDLY_FIRST_USAGE_OVERLAY_IMAGE =
    'https://images.ctfassets.net/0jsruivlylbp/1vig0nVG3p1M0603KnIa6z/6c0f63869a3d7ae4ab02c6cbaea5ab84/explain_calendly-min.png';
export const CALENDLY_INTRO_LS_KEY = 'calendly-intro';
export const CALENDLY_URL_REGEX = /(https:\/\/calendly\.com)\/([a-z0-9-_@+]*)\/([a-z0-9]+)/;

// Embed
export const EMBED_INTRO_LS_KEY = 'embed-intro';
export const EMBED_HELP_ARTICLE_LINK =
    'https://intercom.help/perspective-funnels/de/articles/6830440-wie-kann-ich-google-maps-einbinden';

export const NORMALIZE_CSS =
    '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" />';
export const EMBED_SNIPPET_FORM_MAP = {
    kununu: [
        {
            title: 'kununu-embed-code',
            name: 'body',
        },
    ],
    trustpilot: [
        {
            title: 'trustpilot-head-code',
            name: 'head',
        },
        {
            title: 'trustpilot-trustbox-code',
            name: 'body',
        },
    ],
    provenexpert: [
        {
            title: 'provenexpert-embed-code',
            name: 'body',
        },
    ],
    'google-maps': [
        {
            title: 'google-maps-embed-code',
            name: 'body',
        },
    ],
    'custom-html': [
        {
            title: 'custom-html-embed-code',
            name: 'body',
        },
    ],
};

// DatePicker
export const DATE_PICKER_SCROLL_OFFSET = 55; // px

// Transition
export const TRANSITION_DURATION = 600;
export const TRANSITION_DURATION_SLOW = 800;
export const TRANSITION_PREVIEW_DELAY = 50;
export const TRANSITION_DELAY_BETWEEN_BLOCKS = 50;

// Testimonial
export const TESTIMONIAL_CROP_ASPECT_SIZE = 'xLarge';

// Block intros
export const BLOCK_INTROS = {
    [BlockIntro.CALENDLY]: {
        localStorageKey: CALENDLY_INTRO_LS_KEY,
        modal: Modals.CALENDLY_INTRO,
    },
    [BlockIntro.EMBED]: {
        localStorageKey: EMBED_INTRO_LS_KEY,
        modal: Modals.EMBED_INTRO,
    },
};

// Box size options
export const DEFAULT_BOX_SIZE_OPTIONS: BoxSizeOption[] = [
    'none',
    'small',
    'medium',
    'large',
    'xLarge',
];

export const DEFAULT_BOX: BoxProps = {
    backgroundColor: 'transparent',
    top: 'large',
    right: 'large',
    bottom: 'large',
    left: 'large',
};

// TipTap AI tones
// https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes
export const AI_LANGUAGES: AiLanguageOption[] = [
    { label: 'english', value: 'en' as AiLanguageOption['value'] },
    { label: 'german', value: 'de' as AiLanguageOption['value'] },
    { label: 'arabic', value: 'ar' as AiLanguageOption['value'] },
    { label: 'chinese', value: 'zh' as AiLanguageOption['value'] },
    { label: 'czech', value: 'cs' as AiLanguageOption['value'] },
    { label: 'dutch', value: 'nl' as AiLanguageOption['value'] },
    { label: 'finnish', value: 'fi' as AiLanguageOption['value'] },
    { label: 'french', value: 'fr' as AiLanguageOption['value'] },
    { label: 'greek', value: 'gr' as AiLanguageOption['value'] },
    { label: 'italian', value: 'it' as AiLanguageOption['value'] },
    { label: 'japanese', value: 'jp' as AiLanguageOption['value'] },
    { label: 'korean', value: 'ko' as AiLanguageOption['value'] },
    { label: 'norwegian', value: 'nn' as AiLanguageOption['value'] },
    { label: 'polish', value: 'pl' as AiLanguageOption['value'] },
    { label: 'portuguese', value: 'pt' as AiLanguageOption['value'] },
    { label: 'russian', value: 'ru' as AiLanguageOption['value'] },
    { label: 'spanish', value: 'es' as AiLanguageOption['value'] },
    { label: 'swedish', value: 'sv' as AiLanguageOption['value'] },
    { label: 'turkish', value: 'tr' as AiLanguageOption['value'] },
    { label: 'ukrainian', value: 'ua' as AiLanguageOption['value'] },
];

export const AI_TONES: AiToneOption[] = [
    { label: 'academic', value: 'academic' as AiToneOption['value'] },
    { label: 'business', value: 'business' as AiToneOption['value'] },
    { label: 'casual', value: 'casual' as AiToneOption['value'] },
    { label: 'childfriendly', value: 'childfriendly' as AiToneOption['value'] },
    { label: 'conversational', value: 'conversational' as AiToneOption['value'] },
    { label: 'emotional', value: 'emotional' as AiToneOption['value'] },
    { label: 'humorous', value: 'humorous' as AiToneOption['value'] },
    { label: 'informative', value: 'informative' as AiToneOption['value'] },
    { label: 'inspirational', value: 'inspirational' as AiToneOption['value'] },
    { label: 'memeify', value: 'meme' as AiToneOption['value'] },
    { label: 'narrative', value: 'narrative' as AiToneOption['value'] },
    { label: 'objective', value: 'objective' as AiToneOption['value'] },
    { label: 'persuasive', value: 'persuasive' as AiToneOption['value'] },
    { label: 'poetic', value: 'poetic' as AiToneOption['value'] },
];

export const ENDPOINTS = {
    PATCH: {
        connectPaymentAccount: (componentId: string) =>
            `/components/${componentId}/payment/account/connect`,
    },
} as const;

export const MUTATION_KEYS = {
    connectPaymentAccount: (componentId: string) => ['payment/account', 'connect', componentId],
} as const;
