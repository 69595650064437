import {
    NAME,
    PROPERTY_EDIT_BUTTON_ID,
    SORTING_FIELD_MAP,
    STATUS_OPTION_COLORS,
    STATUS_COLOR_MAP,
    SIDEBAR_WIDTH_KEY,
    LAST_CRM_VIEW_KEY,
    LAST_CRM_SIDEBAR_LAST_TAB_KEY,
    LS_CELLS_WRAP_ENABLED_KEY,
    DAY_DURATION_LIMIT,
    HOUR_DURATION_LIMIT,
    MINUTE_DURATION_LIMIT,
    MONTH_DURATION_LIMIT,
    YEAR_DURATION_LIMIT,
    CONTACT_CARD_DEFAULT_HEIGHT,
    CONTACT_CARD_PROPERTY_HEIGHT,
    LAST_CRM_SAVED_FILTER_KEY,
    DEFAULT_SELECT_PROPERTY_OPTIONS,
    PROPERTIES_SIDEBAR_WRAPPER_ID,
    PROPERTIES_TABLE_WRAPPER_ID,
} from '@/app/crm/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import formatDateIntl from 'date-fns/intlFormat';
import intlFormatDistance from 'date-fns/intlFormatDistance';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import random from 'lodash/random';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import Router from 'next/router';
import { i18n } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';

import { track } from '@/core/tracking';
import { getNow } from '@/utils/common';
import { EMPTY_OBJECT } from '@/utils/empty';
import { localeMap } from '@/utils/hooks/useDateFormat';
import { reportError } from '@/utils/sentry';
import { LocalStorageValue } from 'types/generic';

import {
    FieldType,
    PropertyView,
    PropertyOwner,
    PropertySetting,
    SystemFieldName,
    UniqueFieldName,
    ViewType,
} from '../types';

import type {
    ContactResource,
    ContactValueAttributes,
    EmptyStateActionType,
    Pagination,
    SchemaProperty,
    SchemaResource,
    Selection,
    Sorting,
    SidebarTab,
    ContactAttributes,
    SelectOption,
} from '../types';
import type { DateFnsFormatOptions } from 'types/generic';

export const getSortingQuery = (sorting: Sorting) => {
    // #todo: fix sorting by `person` field when BE is available
    const mappedFieldName = SORTING_FIELD_MAP[sorting.sortField] || sorting.sortField;

    return {
        ...sorting,
        sortField: mappedFieldName,
    };
};

export const getContactPropertyAttributes = (
    contact: ContactResource,
    property: string,
    defaultValue = EMPTY_OBJECT,
): ContactValueAttributes =>
    get(contact, `attributes.fields[${property}]`, defaultValue) as ContactValueAttributes;

export const getContactPropertyValue = (
    contact: ContactResource,
    property: string,
    defaultValue?: any,
) => {
    return get(contact, ['attributes', 'fields', property], defaultValue);
};

export const optimisticallyUpdateContact = ({
    contact,
    fieldName,
    newValue,
}: {
    contact: ContactResource;
    fieldName: string;
    newValue: string;
}): ContactResource => {
    const updatedContact = {
        ...contact,
        attributes: {
            ...contact.attributes,
            fields: {
                ...contact.attributes.fields,
                [fieldName]: newValue,
            },
        },
    };

    if (fieldName === UniqueFieldName.name) {
        /**
         * when updated in the sidebar, we set the `name` field
         * since the logic for `person` lives in the BE we need to
         * wait for the call to return till we get the new information
         * since then all other places like the table cell are showing
         * the old `person` value, so we optimistically update this field
         * as well
         */
        set(updatedContact, 'attributes.fields.person', newValue);
    }

    return updatedContact;
};

export const optimisticallyUpdateContactAttributesValue = ({
    contact,
    fieldName,
    newValue,
}: {
    contact: ContactResource;
    fieldName: string;
    newValue: ContactAttributes[keyof ContactAttributes];
}): ContactResource => {
    const updatedContact = {
        ...contact,
        attributes: {
            ...contact.attributes,
            [fieldName]: newValue,
        },
    };

    return updatedContact;
};

const createPropertyOptionsFromType = (
    propertyType: FieldType,
    options: { value: string }[] = [],
) => {
    if (propertyType === FieldType.select || propertyType === FieldType.selectMulti) {
        return !isEmpty(options) ? options : DEFAULT_SELECT_PROPERTY_OPTIONS;
    } else {
        return [];
    }
};

export const getSchemaProperty = (schema: SchemaResource, fieldName: string) => {
    return schema?.attributes?.properties.find((item) => item.fieldName === fieldName);
};

export const getStatusSchema = (schema: SchemaResource) =>
    getSchemaProperty(schema, UniqueFieldName.status);

export const getContactStatus = (contact: ContactResource, defaultValue?: any): string =>
    getContactPropertyValue(contact, UniqueFieldName.status, defaultValue);

export const getContactName = (contact: ContactResource): string =>
    getContactPropertyValue(contact, UniqueFieldName.name, '');

export const getContactFirstName = (contact: ContactResource): string =>
    getContactPropertyValue(contact, UniqueFieldName.firstName, '');

export const getContactEmail = (contact: ContactResource): string =>
    getContactPropertyValue(contact, UniqueFieldName.email, '');

export const getContactPhoneNumber = (contact: ContactResource): string =>
    getContactPropertyValue(contact, UniqueFieldName.phone, '');

export const getContactLastName = (contact: ContactResource): string =>
    getContactPropertyValue(contact, UniqueFieldName.lastName, '');

export const getContactCreatedAt = (contact: ContactResource): string =>
    getContactPropertyValue(contact, SystemFieldName.ps_converted_at, '');

export const getContactPerson = (contact: ContactResource): string =>
    getContactPropertyValue(contact, 'person', '');

const createSchemaPropertyFromType = ({
    propertyType,
    order,
    title,
    options,
}: {
    propertyType: FieldType;
    order: number;
    title?: string;
    options?: { value: string }[];
}): SchemaProperty => ({
    fieldName: uuidv4(),
    fieldType: propertyType,
    title: title || i18n?.t(`${NAME}:property-${propertyType}-label`) || '',
    visible: true,
    owner: PropertyOwner.custom,
    defaultValue: undefined,
    options: createPropertyOptionsFromType(propertyType, options),
    visibility: {
        [PropertyView.table]: true,
        [PropertyView.kanban]: false,
        [PropertyView.sidebar]: true,
    },
    orders: {
        [PropertyView.table]: order,
        [PropertyView.kanban]: order,
        [PropertyView.sidebar]: order,
    },
});

type OptimisticallyAddSchemaProperty = {
    schema: SchemaResource;
    property: SchemaProperty;
};

export const optimisticallyAddSchemaProperty = ({
    schema,
    propertyType,
    title,
    options,
}: {
    schema: SchemaResource;
    propertyType: FieldType;
    title?: string;
    options?: { value: string }[];
}): OptimisticallyAddSchemaProperty => {
    const clonedSchema = cloneDeep(schema);

    const newProperty = createSchemaPropertyFromType({
        propertyType,
        order: schema?.attributes?.properties?.length,
        title,
        options,
    });
    clonedSchema?.attributes?.properties.push(newProperty);

    return {
        schema: clonedSchema,
        property: newProperty,
    };
};

export const optimisticallyUpdateSchemaProperty = ({
    schema,
    fieldName,
    updatedProperty,
}: {
    schema: SchemaResource;
    fieldName: string;
    updatedProperty: SchemaProperty;
}): SchemaResource => {
    const propertyIndex = schema?.attributes?.properties.findIndex(
        (property) => property.fieldName === fieldName,
    );

    const clonedSchema = cloneDeep(schema);

    if (isNumber(propertyIndex)) {
        clonedSchema?.attributes?.properties.splice(propertyIndex, 1, updatedProperty);
    }

    return clonedSchema;
};

export const optimisticallyUpdateSchemaStatus = ({
    schema,
    newStatus,
}: {
    schema: SchemaResource;
    newStatus: SelectOption[];
}): SchemaResource => {
    const statusPropertyIndex = schema?.attributes?.properties.findIndex(
        ({ fieldName }) => fieldName === 'status',
    );

    const clonedSchema = cloneDeep(schema);
    const clonedSchemaStatusProperty = clonedSchema.attributes.properties[statusPropertyIndex];

    if (clonedSchemaStatusProperty) {
        clonedSchemaStatusProperty.options = newStatus;
        clonedSchemaStatusProperty.defaultValue = newStatus[0].value;
    }

    return clonedSchema;
};

export const optimisticallyDeleteSchemaProperty = ({
    schema,
    fieldName,
}: {
    schema: SchemaResource;
    fieldName: string;
}): SchemaResource => {
    const propertyIndex = schema?.attributes?.properties.findIndex(
        (property) => property.fieldName === fieldName,
    );

    const updatedSchemaProperties = [...(schema?.attributes?.properties || [])];
    isNumber(propertyIndex) && updatedSchemaProperties.splice(propertyIndex, 1);

    return {
        ...schema,
        attributes: {
            ...schema.attributes,
            properties: updatedSchemaProperties,
        },
    };
};

export const getPropertySettings = ({
    fieldName,
    fieldType,
    owner,
}: {
    fieldName: string;
    fieldType: FieldType;
    owner: PropertyOwner;
}) => {
    if (owner === PropertyOwner.funnel || fieldName === UniqueFieldName.status) {
        return [PropertySetting.hide];
    } else if (owner === PropertyOwner.custom) {
        const settings = [PropertySetting.rename, PropertySetting.hide, PropertySetting.delete];

        if (fieldType === FieldType.select || fieldType === FieldType.selectMulti) {
            settings.push(PropertySetting.editOptions);
        }

        return settings;
    }
};

export const getPropertyIconLink = (fieldType: FieldType, value: string) => {
    if (!value) {
        return;
    }

    switch (fieldType) {
        case FieldType.email:
            return `mailto:${value}`;
        case FieldType.url:
            return value;
        case FieldType.phone:
            return `tel:${value}`;
        default:
            return;
    }
};

export const getIsContactSelected = (contactId: string, selection: Selection): boolean => {
    const selectedContactIds = selection?.selectedIds;

    if (selection.isAllSelected) {
        return !selectedContactIds.includes(contactId);
    }

    return selectedContactIds.includes(contactId);
};

export const getTotalSelected = (selection: Selection, totalContacts: number) => {
    if (selection.isAllSelected) {
        return totalContacts - selection.selectedIds?.length;
    }

    return selection.selectedIds?.length;
};

export const createPropertyOrderValueAccessor =
    (view: PropertyView) => (schema: SchemaResource, overId: string, isMovingForward: boolean) => {
        const properties = schema?.attributes?.properties || [];
        const sortedProperties = sortBy(properties, (property) => property?.orders?.[view]);

        const overIndex = findIndex(sortedProperties, (property) => property?.fieldName === overId);
        const nextIndex = isMovingForward ? overIndex + 1 : overIndex - 1;

        const overPropertyOrder = sortedProperties[overIndex]?.orders?.[view];
        const nextPropertyOrder = sortedProperties[nextIndex]?.orders?.[view];

        const hasNextProperty = !isNaN(nextPropertyOrder);
        const nextOrderAverage = (nextPropertyOrder + overPropertyOrder) / 2;

        // If column was dragged to the start or end of the Sortable list
        const nextOrderEdge = isMovingForward ? overPropertyOrder + 1 : overPropertyOrder - 1;

        return hasNextProperty ? nextOrderAverage : nextOrderEdge;
    };

export const getNewPropertyTableColumnOrder = createPropertyOrderValueAccessor(PropertyView.table);

export const getNewPropertyKanbanColumnOrder = createPropertyOrderValueAccessor(
    PropertyView.kanban,
);

export const getNewSidebarFieldsOrder = createPropertyOrderValueAccessor(PropertyView.sidebar);

// Kanban
export const calculateKanbanTotalCount = (
    pagination: { [status: string]: Pagination },
    statusList: string[],
) => {
    let total = 0;
    for (const status of statusList) {
        const paginationData = pagination[status];

        if (paginationData) {
            total += paginationData.count;
        }
    }

    return total;
};

export const getColumnContactsCount = ({
    column,
    pagination,
    dragOverColumn,
    draggedContact,
}: {
    column: string;
    dragOverColumn: string;
    pagination: Pagination;
    draggedContact: ContactResource;
}) => {
    const originalCount = pagination?.count || 0;

    const contactStatus = getContactStatus(draggedContact);

    if (contactStatus !== dragOverColumn) {
        if (column === dragOverColumn) {
            // Add 1 if column has inserted dragged contact
            return originalCount + 1;
        } else if (column === contactStatus) {
            // Substract 1 if column's contact has been moved to another column
            return originalCount - 1;
        }
    }

    return originalCount;
};

// Profile
// TODO: Do not access element directly, maybe use refs instead
const getPropertySidebarContainerElement = () => {
    return document.getElementById(PROPERTIES_SIDEBAR_WRAPPER_ID);
};

export const scrollToLastPropertyInSidebarAndOpenMenu = () => {
    const lastChild = last(getPropertySidebarContainerElement()?.children);
    lastChild?.scrollIntoView({ behavior: 'smooth' });

    const menuTriggerSelector = `button#${PROPERTY_EDIT_BUTTON_ID}`;
    const trigger = lastChild?.querySelector(menuTriggerSelector) as HTMLButtonElement;

    trigger?.click();
};

const getPropertyTableContainerElement = () => {
    return document.getElementById(PROPERTIES_TABLE_WRAPPER_ID);
};

export const scrollToLastPropertyInTable = () => {
    const lastChild = last(getPropertyTableContainerElement()?.children);
    lastChild?.scrollIntoView({ behavior: 'smooth' });
};

export const getStatusColors = (color?: string) => {
    if (!color) {
        return [];
    }

    return [
        STATUS_COLOR_MAP[color]?.background,
        STATUS_COLOR_MAP[color]?.text,
        STATUS_COLOR_MAP[color]?.border,
    ];
};

export const getStatusClassName = (color?: string) => getStatusColors(color).join(' ');

export const getRandomStatusColor = () => {
    return STATUS_OPTION_COLORS[random(0, STATUS_OPTION_COLORS.length - 1)];
};

export const getShortenedDateInterval = (date: Date, locale: string, withSuffix?: boolean) => {
    const now = getNow();
    const dateToNowMs = now.getTime() - date.getTime();

    let unit;
    let hasChangedJustNow;

    if (dateToNowMs < MINUTE_DURATION_LIMIT) {
        hasChangedJustNow = true;
    } else if (dateToNowMs < HOUR_DURATION_LIMIT) {
        unit = 'minute';
    } else if (dateToNowMs < DAY_DURATION_LIMIT) {
        unit = 'hour';
    } else if (dateToNowMs < MONTH_DURATION_LIMIT) {
        unit = 'day';
    } else if (dateToNowMs < YEAR_DURATION_LIMIT) {
        unit = 'month';
    } else {
        unit = 'year';
    }

    const relativeValue = withSuffix
        ? intlFormatDistance(date, now, {
              locale,
              numeric: 'always',
              style: 'narrow',
              unit,
          })
        : formatDistanceToNowStrict(date, {
              locale: localeMap[locale],
              unit,
          });

    return hasChangedJustNow ? i18n?.t(`${NAME}:just-now`) : relativeValue;
};

export const getFullFormattedDate = (
    date: Date,
    locale: string,
    formatOptions: DateFnsFormatOptions,
) => {
    return formatDateIntl(date, formatOptions, {
        locale,
    });
};

// Tracking

export const trackEmptyCrmButtonAction = (campaignId: string, actionType: EmptyStateActionType) => {
    track(TRACKING_EVENTS.crm.empty.action, {
        campaign_id: campaignId,
        empty_state_action_type: actionType,
    });
};

export const getDefaultSidebarWidth = () => {
    const savedWidth = parseInt(localStorage.getItem(SIDEBAR_WIDTH_KEY));

    if (isNaN(savedWidth)) {
        // If no saved width in LS -> set default width to 25% of the screen
        const pageWidth = document.documentElement.clientWidth;
        const sidebarWidth = pageWidth / 4;

        return Math.floor(sidebarWidth);
    }

    return savedWidth;
};

const getLastCRMViewStorageValue = (): Record<string, ViewType> => {
    try {
        const storeValued = localStorage.getItem(LAST_CRM_VIEW_KEY);

        if (storeValued) {
            return JSON.parse(storeValued);
        }

        return {};
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return {};
    }
};

const getLastCRMSavedFilterStorageValue = (): Record<string, string> => {
    try {
        const storeValued = localStorage.getItem(LAST_CRM_SAVED_FILTER_KEY);

        if (storeValued) {
            return JSON.parse(storeValued);
        }

        return {};
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return {};
    }
};

export const getCampaignCRMSavedFilter = (campaignId: string) => {
    const storedValue = getLastCRMSavedFilterStorageValue();

    return storedValue[campaignId] ?? '';
};

export const setLastCRMSavedFilter = (campaignId: string, savedFilterId: string) => {
    const storedValue = getLastCRMSavedFilterStorageValue();

    storedValue[campaignId] = savedFilterId;

    localStorage.setItem(LAST_CRM_SAVED_FILTER_KEY, JSON.stringify(storedValue));
};

export const getCampaignCRMDefaultView = (campaignId: string) => {
    const storedValue = getLastCRMViewStorageValue();

    return storedValue[campaignId] ?? ViewType.kanban;
};

export const setLastCRMView = (campaignId: string, viewType: ViewType) => {
    const storedValue = getLastCRMViewStorageValue();

    storedValue[campaignId] = viewType;

    localStorage.setItem(LAST_CRM_VIEW_KEY, JSON.stringify(storedValue));
};

const getLastCrmSidebarTabStorageValue = (): Record<string, SidebarTab> => {
    try {
        const storeValued = localStorage.getItem(LAST_CRM_SIDEBAR_LAST_TAB_KEY);

        if (storeValued) {
            return JSON.parse(storeValued);
        }

        return {};
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return {};
    }
};

export const getLastCrmSidebarTab = (campaignId: string) => {
    const storedValue = getLastCrmSidebarTabStorageValue();

    return storedValue[campaignId];
};

export const setLastCrmSidebarTab = (campaignId: string, tab: SidebarTab) => {
    const storedValue = getLastCrmSidebarTabStorageValue();

    storedValue[campaignId] = tab;

    localStorage.setItem(LAST_CRM_SIDEBAR_LAST_TAB_KEY, JSON.stringify(storedValue));
};

export const getCrmUrl = (campaignId: string) => {
    const viewType = getCampaignCRMDefaultView(campaignId);
    const savedFilter = getCampaignCRMSavedFilter(campaignId);

    let path = `/funnel/${campaignId}/crm`;

    if (viewType === ViewType.kanban) {
        path += '/kanban';
    }

    if (savedFilter) {
        path += `/${savedFilter}`;
    }

    return path;
};

export const getCrmView = (): ViewType => {
    if (Router.pathname.includes(ViewType.kanban)) {
        return ViewType.kanban;
    }

    return ViewType.table;
};

export const getIsKanbanView = () => getCrmView() === ViewType.kanban;

export const getIsTableView = () => getCrmView() === ViewType.table;

export const setIsCellWrapEnabledFromLS = (enabled: boolean) => {
    localStorage.setItem(
        LS_CELLS_WRAP_ENABLED_KEY,
        enabled ? LocalStorageValue.TRUE : LocalStorageValue.FALSE,
    );
};

export const getIsCellWrapEnabledFromLS = (): boolean => {
    const isCellWrapEnabled =
        localStorage.getItem(LS_CELLS_WRAP_ENABLED_KEY) ?? LocalStorageValue.FALSE;

    return isCellWrapEnabled === LocalStorageValue.TRUE;
};

export const getContactCardHeight = (propertyCount: number) => {
    if (!propertyCount) {
        return CONTACT_CARD_DEFAULT_HEIGHT;
    }

    const propertiesCombinedHeight = CONTACT_CARD_PROPERTY_HEIGHT * propertyCount;

    return CONTACT_CARD_DEFAULT_HEIGHT + propertiesCombinedHeight;
};
