import { cn } from '@/utils/cn';

import Item from './Item';

import type { RadioSliderOption, RadioSliderSelection } from '@/ui/types';

export interface Props {
    onChange: (selection: RadioSliderSelection) => void;
    items: RadioSliderOption[];
    activeIndex: number;
    className?: string;
}

const RadioSlider = ({ items, activeIndex, onChange, className, ...rest }: Props) => {
    const sliderStyle = {
        width: `${100 / items.length}%`,
        left: activeIndex === 0 ? '2px' : activeIndex === items.length - 1 ? '-2px' : 0,
        transform: `translateX(${100 * activeIndex}%)`,
    };

    const handleChange = (option: RadioSliderSelection) => {
        onChange(option);
    };

    return (
        <div
            className={cn(
                'relative isolate flex h-12 items-center overflow-hidden rounded-lg bg-gray-100',
                className,
            )}
            {...rest}
        >
            <div
                className="absolute h-11 rounded-md border bg-white drop-shadow-sm transition"
                style={sliderStyle}
            />
            <div className="relative z-10 flex w-full items-center">
                {items.map((item, index) => {
                    return (
                        <Item
                            key={item.name}
                            name={item.name}
                            value={item.value}
                            index={index}
                            render={item.render}
                            active={index === activeIndex}
                            onClick={handleChange}
                            tooltip={item.tooltip}
                            preventDefault={item.preventDefault}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default RadioSlider;
