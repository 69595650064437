import { NAME } from '@/app/editor/blocks/constants';
import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import { useTranslation } from 'next-i18next';
import { Field, getFormValues, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import CalendlyForm from '@/app/editor/blocks/components/QuestionForm/CalendlyForm';
import { getIsTipTapData } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { UpdateBlockCommand } from '@/app/editor/commands/commands/updateBlockCommand';
import getHistoryController from '@/app/editor/commands/utils/HistoryControllers';
import Animation from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Animation';
import { ButtonStyle } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ButtonStyle';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { ColorPickerHistoryProvider } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color/ColorPickerHistoryProvider';
import Linking from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking';
import Personalization from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Personalization';
import TextAlign from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextAlign';
import { TextSize } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextSize';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import TrackingIdInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TrackingIdInput';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { usePerspectiveEditorEngine } from '@/app/editor/engine/PerspectiveEditorEngine';
import { getCalculatedTextColor, getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import { SidebarPortalTarget } from '@/ui/components/_BlockEditFields/SidebarPortalTarget';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form = ({
    blockId,
    handleSubmit,
    activeTheme,
    initialValues,
    pageId,
    campaignId,
    parent,
}: Props & InjectedFormProps) => {
    const { actions, isActive: isWithinEditorEngine } = usePerspectiveEditorEngine();
    const { t } = useTranslation(NAME);
    const historyController = getHistoryController();
    const { isInColumn } = useLayoutInfo(blockId);
    const formValues = useAppSelector((state) => getFormValues(blockId)(state) as BlockResource);

    const calculatedTextColor = getCalculatedTextColor({
        block: formValues,
        activeTheme,
        bgThemeColorKey: 'buttonBackgroundColor',
        hasSameColorCheck: false,
    });

    const parentComponentType = parent?.attributes?.componentType;
    const buttonType = initialValues?.attributes?.content?.buttonType;
    const isCalendlySubmitButton = parentComponentType === 'formURL';
    const isTipTap = getIsTipTapData(initialValues?.attributes?.content?.wysiwyg);
    const buttonStyle = formValues?.attributes?.content?.buttonStyle;

    const handleCalendlySubmit = (values: BlockResource) => {
        if (!isWithinEditorEngine) {
            const updateBlockCommand = new UpdateBlockCommand(values);

            historyController.executeCommand(updateBlockCommand);

            return;
        }

        actions.changeBlock.enqueue({
            values,
        });
    };

    return (
        <>
            {isTipTap ? (
                <>
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-size-portal-${blockId}`} />
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-styles-portal-${blockId}`} />
                </>
            ) : (
                <>
                    <TextSize textBlockId={blockId} />
                    <TextStyle textBlockId={blockId} hideLink />
                </>
            )}

            <div className="grid grid-cols-3 gap-x-2">
                <Field
                    name="attributes.content.align"
                    component={TextAlign}
                    submit={handleSubmit}
                />

                {isTipTap ? (
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-color-portal-${blockId}`} />
                ) : (
                    <Field
                        name="attributes.content.color"
                        component={Color}
                        expand="center"
                        submit={handleSubmit}
                        themeColor={calculatedTextColor}
                        tooltip={t('font-color')}
                    />
                )}

                <ColorPickerHistoryProvider>
                    <Field
                        name="attributes.content.backgroundColor"
                        component={Color}
                        expand="left"
                        submit={handleSubmit}
                        themeColor={getThemeColor(activeTheme, 'buttonBackgroundColor')}
                        tooltip={buttonStyle === 'outline' ? t('border-color') : t('button-color')}
                        themeKey="buttonBackgroundColor"
                        hasGradient
                    />
                </ColorPickerHistoryProvider>
            </div>

            <Subheading text={t('linking')} helpTooltip={HELP_TOOLTIP_CONTENT.EDIT_FORM_LINKING} />

            <div className="mt-4">
                <Field
                    name="attributes.content.linking"
                    component={Linking}
                    pageId={pageId}
                    blockId={blockId}
                    campaignId={campaignId}
                    submit={handleSubmit}
                />
            </div>

            <div className="mt-2">
                <Field
                    name="attributes.content.clickId"
                    component={TrackingIdInput}
                    submit={handleSubmit}
                />
            </div>

            <Subheading text={t('advanced-options')} />

            <div className="mt-4">
                <Field
                    name="attributes.content.buttonStyle"
                    component={ButtonStyle}
                    onSubmit={handleSubmit}
                />

                <div className="grid grid-cols-3 gap-x-2">
                    {isTipTap ? (
                        <SidebarPortalTarget
                            id={`tip-tap-sidebar-personalization-portal-${blockId}`}
                        />
                    ) : (
                        <Personalization blockId={blockId} />
                    )}

                    <Field
                        name="attributes.content.animation"
                        component={Animation}
                        submit={handleSubmit}
                        animationName="jittery"
                    />
                </div>
            </div>

            {isCalendlySubmitButton && !!parent && (
                <CalendlyForm
                    form={parent.id}
                    initialValues={parent}
                    onSubmit={handleCalendlySubmit}
                    className="mt-0"
                />
            )}

            {buttonType === 'button' && (
                <BoxSettings
                    blockId={blockId}
                    activeTheme={activeTheme}
                    handleSubmit={handleSubmit}
                    headingClass="mt-6"
                    hiddenColor={isInColumn}
                />
            )}
        </>
    );
};

Form.displayName = 'ButtonEditForm';

export default reduxForm({})(Form);
