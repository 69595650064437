import { combineReducers } from 'redux';

import activitiesReducer from './activities';
import contactsReducer from './contacts';
import fileUploadsReducer from './fileUploads';
import filtersReducer from './filters';
import kanbanReducer from './kanban';
import kanbanDndReducer from './kanbanDnd';
import schemaReducer from './schema';
import sidebarReducer from './sidebar';
import tableReducer from './table';
import { workspaceStatusReducer } from './workspaceStatus';
import { workspaceStatusTemplatesReducer } from './workspaceStatusTemplates';
import workspaceUsersReducer from './workspaceUsers';

export default combineReducers({
    activitiesReducer,
    contactsReducer,
    kanbanReducer,
    kanbanDndReducer,
    filtersReducer,
    sidebarReducer,
    fileUploadsReducer,
    schemaReducer,
    tableReducer,
    workspaceUsersReducer,
    workspaceStatusTemplatesReducer,
    workspaceStatusReducer,
});
