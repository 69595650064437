import type { Language } from 'types/generic';

export type AnalyticsKey = 'pages' | 'kpis' | 'responses' | 'completion-rate' | 'variants';

export interface ChartPageAttributes {
    label: string;
    value: number;
}

export interface ChartPageWithSummary extends ChartPageAttributes {
    summary?: ChartPageAttributes[];
}

export interface Column {
    name?: string;
    text?: string;
    path?: string;
    translation?: string;
    language?: Language;
    clickable?: boolean;
    sortable?: boolean;
}

export type DateRangePickerStaticRangeKey =
    | 'custom'
    | 'sinceCreation'
    | 'sinceSplitTest'
    | 'today'
    | 'yesterday'
    | 'lastWeek'
    | 'lastMonth'
    | 'lastQuarter'
    | 'lastHalf'
    | 'lastYear';

type KpiIdentifier = 'visitors' | 'leads' | 'conversionRate' | 'completionRate';

export interface Kpi {
    identifier: KpiIdentifier;
    value: string | number | undefined;
    onClick?: () => void;
}
export interface QuestionResponse {
    visualType?: 'image' | 'icon' | 'emoji';
    visual?: string;
    value: string;
    count: number;
    percentage?: number;
}

export interface QuestionAttributes {
    title: string;
    responseCount?: number;
    count?: number;
    answers?: QuestionResponse[];
}

export interface Answers {
    ps_result: string;
    ps_cookies: string;
    firstName: string;
    email: string;
    [key: string]: string;
}

export interface SessionProfile {
    fieldName: string;
    title: string;
    value: string;
    counter?: number;
    timestamp?: string;
}

export interface SessionAttributes {
    completed: boolean;
    completedAt?: string;
    completedPageName?: string;
    completedResult?: string;
    conversionActivityId?: string;
    convertedAt?: string;
    createdAt: string;
    lastSeenAt: string;
    status?: 'lead' | 'visitor';
    profile: SessionProfile[];
}

export interface Session {
    id: string;
    attributes: SessionAttributes;
    answers?: Answers;
    avatarInitials?: string;
}

export interface SessionsPaginationAttributes {
    count?: number;
    first?: string;
    last?: string;
    next?: string;
    pageCount?: number;
    previous?: string;
    self?: string;
    totalCount?: number;
}

export interface SessionsSortingAttributes {
    orderField: string;
    orderValue: number;
}

export interface SchemaItem {
    fieldName: string;
    title: string;
}

export type TabLabel = 'metrics' | 'ab-test' | 'dashboard' | 'insights' | 'contacts' | 'sessions';

export type ExportFormat = 'csv' | 'zip';

export const enum ReportType {
    all = 'all',
    leads_only = 'leads_only',
}

export interface TriggerExportProps {
    email: string;
    campaignId: string;
    exportType: ExportFormat;
    reportType?: ReportType;
}

export interface Variant {
    id: string;
    type: string;
    attributes: {
        name: string;
    };
}

export interface SplitTestPageAttributes {
    id: string;
    name?: string;
    isWinner: boolean;
    report: {
        completion?: number | string;
        conversion?: number | string;
        leads?: number | string;
        sessions?: number | string;
    };
}

export interface SplitTestResults {
    lift?: number;
    relevance?: 'low' | 'medium' | 'high';
    significance?: number;
    winner?: {
        id: string;
        name: string;
    };
    pages: SplitTestPageAttributes[];
}

export interface CampaignHasVariantsRes {
    hasVariants: boolean;
    startedAt?: string;
}
