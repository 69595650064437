import { NAME, TERM_OPTIONS } from '@/app/billing/constants';

import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getSubscription } from '@/app/billing/models/subscription';
import { apiPost, handleRuntimeError } from '@/core/api';
import { EMPTY_OBJECT } from '@/utils/empty';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

type TermOption = (typeof TERM_OPTIONS)[0];

interface State {
    selectedTermOption: TermOption;
    addons: { [key: string]: number };
    fetchingEstimate: boolean;
    estimatedPrice?: number;
    estimatedDiscount?: number;
}

const initialState: State = {
    selectedTermOption: TERM_OPTIONS[2], // highest
    addons: EMPTY_OBJECT,
    fetchingEstimate: false,
    estimatedPrice: undefined,
    estimatedDiscount: undefined,
};

export const individualSlice = createSlice({
    name: `${NAME}/individual`,
    initialState,
    reducers: {
        setSelectedTermOption(state, action: PayloadAction<TermOption>) {
            state.selectedTermOption = action.payload;
        },
        increaseAddon(state, action: PayloadAction<string>) {
            if (!state.addons[action.payload]) {
                state.addons[action.payload] = 1;
            } else {
                state.addons[action.payload] += 1;
            }
        },
        decreaseAddon(state, action: PayloadAction<string>) {
            state.addons[action.payload] -= 1;
        },
        setEstimatedPrice(state, action: PayloadAction<number>) {
            state.estimatedPrice = action.payload;
        },
        setEstimatedDiscount(state, action: PayloadAction<number>) {
            state.estimatedDiscount = action.payload;
        },
        setFetchingEstimate(state, action: PayloadAction<boolean>) {
            state.fetchingEstimate = action.payload;
        },
        resetAddons(state) {
            state.addons = {};
        },
        reset(state) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

// === Actions ======

export const {
    reset,
    setSelectedTermOption,
    increaseAddon,
    decreaseAddon,
    setFetchingEstimate,
    setEstimatedPrice,
    setEstimatedDiscount,
} = individualSlice.actions;

// === Selectors ======

export const getSelectedTermOption = (state: AppState) =>
    state[NAME]?.individual?.selectedTermOption;

export const getFetchingEstimate = (state: AppState) => state[NAME]?.individual?.fetchingEstimate;

export const getAddons = (state: AppState) => state[NAME]?.individual?.addons;

export const getEstimatedPrice = (state: AppState) => state[NAME]?.individual?.estimatedPrice;

export const getEstimatedDiscount = (state: AppState) => state[NAME]?.individual?.estimatedDiscount;

// === Thunks ======

export const fetchPriceEstimate =
    (plan: string, addons: { id: string; quantity: number }[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setFetchingEstimate(true));

        const state = getState();
        const subscription = getSubscription(state);

        try {
            const response = await apiPost(`/subscriptions/${subscription.id}/plan/estimate`, {
                data: {
                    plan,
                    addons,
                },
            });

            const discount =
                get(response.data, 'data.attributes.invoiceEstimate.discounts[0].amount') ||
                get(response.data, 'data.attributes.nextInvoiceEstimate.discounts[0].amount', 0);

            const price =
                get(response.data, 'data.attributes.invoiceEstimate.subTotal') ||
                get(response.data, 'data.attributes.nextInvoiceEstimate.subTotal', 0);

            dispatch(setEstimatedPrice(price));
            dispatch(setEstimatedDiscount(discount));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching estimate failed:' });
        } finally {
            dispatch(setFetchingEstimate(false));
        }
    };

export default individualSlice.reducer;
