import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Children } from 'react';

import SortableList from '@/app/editor/blocks/components/_wrapper/SortableList';
import NewComponent from '@/app/editor/blocks/components/Form/NewComponent';
import { BlockComponentType } from '@/app/editor/blocks/types';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { WarningPing } from '@/ui/components/WarningPing';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    url: string | undefined;
}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ children, blockId, nestedLevel, isPreview, url, componentType }) => {
        const { t } = useTranslation(NAME);

        const childBlocks = Children.toArray(children);

        // childBlocks become the form inputs here, since we pop away the submit button
        const submitButton = childBlocks.pop();

        const showWarningPing = !url && !isPreview && componentType === BlockComponentType.FORM_URL;

        return (
            <div className="mt-6">
                <div>
                    {isPreview ? (
                        // No sorting required for preview
                        <div className="flex flex-col space-y-4">{childBlocks}</div>
                    ) : (
                        <SortableList
                            parentBlockId={blockId}
                            sortingStrategy="vertical"
                            nestedLevel={nestedLevel}
                            className="space-y-4"
                        >
                            {childBlocks}
                        </SortableList>
                    )}
                </div>

                <div className="mt-4">{submitButton}</div>

                {/* Connect warning */}
                {showWarningPing && <WarningPing tooltip={t('calendly-missing-url-warning')} />}
            </div>
        );
    },
    NewComponent,
);

Component.displayName = 'Form';

export default Component;
