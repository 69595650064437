import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getBlockById, getBlockParent } from '@/app/editor/blocks/models/blocks';
import { apiPatch, handleRuntimeError } from '@/core/api';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { getActivePageId } from '../../pages/models/pages';
import { NAME } from '../constants';

import type { PageResource } from '@/app/editor/pages/types';
import type { RelationshipObject } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    orderByPage: { [pageId: string]: string[] };
}

const initialState: State = {
    orderByPage: EMPTY_OBJECT,
};

export const blockOrderSlice = createSlice({
    name: `editor/${NAME}/blockOrder`,
    initialState,
    reducers: {
        setOrderByPage(state, action: PayloadAction<{ pageId: string; blockIds: string[] }>) {
            return {
                ...state,
                orderByPage: {
                    ...state.orderByPage,
                    [action.payload.pageId]: action.payload.blockIds,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setOrderByPage, reset } = blockOrderSlice.actions;

// === Selectors ======

export const getPageBlockIds = (state: AppState, pageId: string): string[] =>
    state[NAME]?.blockOrderReducer?.orderByPage[pageId] || EMPTY_ARRAY;

export const getActivePageBlockIds = (state: AppState): string[] => {
    const activePageId = getActivePageId(state);

    return getPageBlockIds(state, activePageId);
};

export const getBlockIndexOnPage = (state: AppState, blockId: string): number => {
    const block = getBlockById(state, blockId);
    const pageId = block?.relationships?.page?.data?.id;

    const pageBlockIds = getPageBlockIds(state, pageId);

    return pageBlockIds.indexOf(blockId);
};

export const getBlockIndexInParent = (state: AppState, blockId: string): number => {
    const parent = getBlockParent(state, blockId);

    const childBlocks = get(
        parent,
        'relationships.components.data',
        EMPTY_ARRAY,
    ) as RelationshipObject[];

    return childBlocks.findIndex((childBlock) => childBlock.id === blockId);
};

// === Helper ======

const optimisticUpdateActivePageBlockOrder =
    (blockIds: string[]): AppThunk =>
    (dispatch, getState) => {
        const activePageId = getActivePageId(getState());

        dispatch(setOrderByPage({ pageId: activePageId, blockIds }));
    };

// === Thunks ======

// Reorder page components
export const updateActivePageBlockOrder =
    (blockIds: string[]): AppThunk =>
    async (dispatch, getState) => {
        const activePageId = getActivePageId(getState());

        // optimistic update
        dispatch(optimisticUpdateActivePageBlockOrder(blockIds));

        // Update in DB
        try {
            await apiPatch(`/pages/${activePageId}/order`, {
                data: { components: blockIds },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating active block failed:' });
        }
    };

// Set Block order from page resource
export const setPageBlockOrder =
    (page: PageResource): AppThunk =>
    (dispatch) => {
        const pageBlocks: RelationshipObject[] = get(
            page,
            'relationships.components.data',
            EMPTY_ARRAY,
        );
        const blockIds = pageBlocks.map((block) => block.id);

        dispatch(setOrderByPage({ pageId: page.id, blockIds }));
    };

export default blockOrderSlice.reducer;
