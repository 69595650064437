import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react';
import { useMemo } from 'react';

import { EditorEngineDropPosition } from '@/app/editor/engine/core/types';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import { cn } from '@/utils/cn';

import type {
    EditorEngineDragMessage,
    EditorEngineDropPreviewOptions,
} from '@/app/editor/engine/core/types';
import type { ClassValue } from 'clsx';

interface Props {
    /**
     * The orientation of the drop preview.
     */
    orientation: EditorEngineOrientation;
    /**
     * The position of the drop preview.
     */
    position: EditorEngineDropPosition;
    /**
     * Whether the drop preview is visible.
     */
    visible: boolean;
    /**
     * The message to show while dragging a node.
     */
    message: false | EditorEngineDragMessage;
    /**
     * The options for the drop preview.
     */
    options: EditorEngineDropPreviewOptions;
}

/**
 * A drop preview that shows where a dragged node will be dropped.
 */
export const EditorEngineDropPreview = ({
    orientation,
    position,
    visible,
    message,
    options,
}: Props) => {
    const { x, y, strategy, refs } = useFloating({
        middleware: [
            shift({
                crossAxis: true,
                padding: {
                    top: 48,
                },
            }),
            offset(({ rects }) => -rects.reference.height / 2 - rects.floating.height / 2),
        ],
        whileElementsMounted: autoUpdate,
    });

    const gapClassNames = useMemo(() => {
        if (!options?.gapWidth) {
            return [];
        }

        return [options.gapWidth];
    }, [options?.gapWidth]) satisfies ClassValue[];

    if (position === EditorEngineDropPosition.Within) {
        // Must be rendered by the component itself
        return null;
    }

    return (
        <div
            className={cn(
                'pointer-events-none absolute z-10 flex items-center justify-center opacity-0',
                {
                    'left-0 top-0': [
                        EditorEngineDropPosition.Left,
                        EditorEngineDropPosition.Top,
                    ].includes(position),
                    'bottom-0 right-0': [
                        EditorEngineDropPosition.Right,
                        EditorEngineDropPosition.Bottom,
                    ].includes(position),
                    'opacity-100': visible,
                },
                ...gapClassNames,
                {
                    'h-full': orientation === EditorEngineOrientation.Horizontal,
                    'w-full': orientation === EditorEngineOrientation.Vertical,
                    '-translate-x-full': position === EditorEngineDropPosition.Left,
                    '-translate-y-full': position === EditorEngineDropPosition.Top,
                    'translate-x-full': position === EditorEngineDropPosition.Right,
                    'translate-y-full': position === EditorEngineDropPosition.Bottom,
                },
            )}
        >
            <div
                className={cn(
                    'relative flex items-center justify-center rounded-full bg-blue-500',
                    {
                        'h-full w-0.5': orientation === EditorEngineOrientation.Horizontal,
                        'h-0.5 w-full': orientation === EditorEngineOrientation.Vertical,
                    },
                )}
            >
                <div
                    className={cn(
                        'absolute size-4 rounded-full border-2 border-blue-500 bg-white',
                        {
                            '-top-2': orientation === EditorEngineOrientation.Horizontal,
                            '-left-2': orientation === EditorEngineOrientation.Vertical,
                        },
                    )}
                />
                {message && (
                    <>
                        <div ref={refs.setReference} />
                        <div
                            className="flex flex-col text-nowrap rounded-lg bg-gray-900 px-2 py-1 text-center font-sans text-sm font-medium shadow"
                            ref={refs.setFloating}
                            style={{
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0,
                            }}
                        >
                            <span className="text-white">{message.mainLabel}</span>
                            {message.subLabel && (
                                <span className="text-white/70">{message.subLabel}</span>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
