import { MODAL_OPTIONS } from '@/app/modals/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import findLast from 'lodash/findLast';
import get from 'lodash/get';

import { apiGet, apiPost, handleRuntimeError } from '@/core/api';

import { showModal } from '../../modals/models/modals';
import { Modals } from '../../modals/types';
import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Entry } from 'contentful';
import type { ICourseFields } from 'types/contentful';

interface CourseStatus {
    isComingSoon: boolean;
    isFirstCourse: boolean;
    isLocked: boolean;
    isCompleted: boolean;
    isWatched: boolean;
    isPreviousCourseCompleted: boolean;
}

interface State {
    courses: Entry<ICourseFields>[];
    areCoursesLoaded: boolean;
    currentCourse: Entry<ICourseFields> | null;
    isCurrentCourseLoaded: boolean;
    watchedLessons: { [key: string]: string[] | null } | null;
    areWatchedLessonsLoaded: boolean;
    kpis: {
        sessions: number;
        leads: number;
    };
    areKPIsFetched: boolean;
}

const initialState: State = {
    courses: [],
    areCoursesLoaded: false,
    currentCourse: null,
    isCurrentCourseLoaded: false,
    watchedLessons: null,
    areWatchedLessonsLoaded: false,
    kpis: {
        sessions: 0,
        leads: 0,
    },
    areKPIsFetched: false,
};

export const pathToSuccessSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setCurrentCourse(state, action: PayloadAction<Entry<ICourseFields>>) {
            state.currentCourse = action.payload;
            state.isCurrentCourseLoaded = true;
        },
        setCourses(state, action: PayloadAction<Entry<ICourseFields>[]>) {
            state.courses = action.payload;
            state.areCoursesLoaded = true;
        },
        setWatchedLessons(state, action) {
            state.watchedLessons = action.payload;
            state.areWatchedLessonsLoaded = true;
        },
        resetCurrentCourse(state) {
            state.currentCourse = initialState.currentCourse;
        },
        setKPIs(state, action) {
            state.kpis = action.payload;
            state.areKPIsFetched = true;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setCurrentCourse, setCourses, setWatchedLessons, setKPIs, reset } =
    pathToSuccessSlice.actions;

// === Selectors ======

const getState = (state: AppState) => state;

export const getAreCoursesLoaded = (state: AppState) =>
    state[NAME].pathToSuccessReducer.areCoursesLoaded;

export const getIsCurrentCourseLoaded = (state: AppState) =>
    state[NAME].pathToSuccessReducer.isCurrentCourseLoaded;

export const getAllCourses = (state: AppState) => state[NAME].pathToSuccessReducer.courses;

export const getCurrentCourse = (state: AppState) => state[NAME].pathToSuccessReducer.currentCourse;

export const getWatchedLessons = (state: AppState) =>
    state[NAME].pathToSuccessReducer.watchedLessons;

export const getAreWatchedLessonsLoaded = (state: AppState) =>
    state[NAME].pathToSuccessReducer.areWatchedLessonsLoaded;

export const getCurrentLeadAmount = (state: AppState) =>
    state[NAME].pathToSuccessReducer.kpis.leads;

export const getIsCourseActive = (course: Entry<ICourseFields>) =>
    createSelector(getCurrentCourse, (currentCourse) => {
        return currentCourse?.sys.id === course?.sys.id;
    });

export const getMissingLessonsByCourse = (course: Entry<ICourseFields>) =>
    createSelector(getWatchedLessons, (watchedLessons) => {
        const courseId = get(course, 'sys.id');
        const watchedLessonIds = get<Record<string, string[] | null> | null, string, string[]>(
            watchedLessons,
            courseId,
            [],
        );

        return get(course, 'fields.courseLessons', []).filter(
            (lesson) => !watchedLessonIds.includes(get(lesson, 'sys.id')),
        );
    });

export const getIsCourseWatched = (course: Entry<ICourseFields>) =>
    createSelector(getMissingLessonsByCourse(course), (missingLessons) => {
        return missingLessons.length === 0;
    });

export const getIsCourseLeadGoalReached = (course: Entry<ICourseFields>) =>
    createSelector(getCurrentLeadAmount, (leadAmount) => {
        const leadGoal = course?.fields.minimumLeadAmount;

        return !!leadGoal && leadAmount >= leadGoal;
    });

export const getIsCourseCompleted = (course: Entry<ICourseFields>) =>
    createSelector(
        getIsCourseWatched(course),
        getIsCourseLeadGoalReached(course),
        (isWatched, isLeadGoalReached) => {
            if (course?.fields.minimumLeadAmount && course.fields.minimumLeadAmount > 0) {
                return isWatched && isLeadGoalReached;
            }

            return isWatched;
        },
    );

export const getPreviousCourse = (course: Entry<ICourseFields>) =>
    createSelector(getAllCourses, getState, (courses) => {
        if (course?.fields.order === 1) {
            return undefined;
        }

        const courseIndex = courses.findIndex(
            (courseFromList) => courseFromList?.sys?.id === course?.sys?.id,
        );
        const previousCourseIndex = courseIndex - 1;

        return courses[previousCourseIndex];
    });

export const getIsPreviousCourseCompleted = (course: Entry<ICourseFields>) =>
    createSelector(getPreviousCourse(course), getState, (previousCourse, state) => {
        if (course?.fields.order === 1) {
            return true;
        }

        if (!previousCourse) {
            return false;
        }

        return getIsCourseCompleted(previousCourse)(state);
    });

export const getMissingLeadsByCourse = (course: Entry<ICourseFields>) =>
    createSelector(getCurrentLeadAmount, (leadAmount) => {
        if (!course?.fields.minimumLeadAmount) {
            return 0;
        }

        const missingLeads = course.fields.minimumLeadAmount - leadAmount;

        if (missingLeads < 0) {
            return 0;
        }

        return missingLeads;
    });

export const getCourseStatus = (course: Entry<ICourseFields> | null) =>
    course
        ? createSelector(
              getCurrentCourse,
              getIsCourseWatched(course),
              getIsCourseCompleted(course),
              getIsPreviousCourseCompleted(course),
              (currentCourse, isWatched, isCompleted, isPreviousCourseCompleted) => {
                  const targetCourse = course || currentCourse;

                  const isComingSoon = !!targetCourse?.fields.comingSoon;
                  const isFirstCourse = targetCourse?.fields.order === 1;
                  const enforceUnlockedState = targetCourse?.fields.enforceUnlockedState ?? false;

                  const status: CourseStatus = {
                      isComingSoon,
                      isFirstCourse: isFirstCourse,
                      isWatched: isWatched,
                      isCompleted: isCompleted,
                      isPreviousCourseCompleted: isPreviousCourseCompleted,
                      isLocked: enforceUnlockedState
                          ? false
                          : isComingSoon ||
                            (!isFirstCourse && !isCompleted && !isPreviousCourseCompleted),
                  };

                  return status;
              },
          )
        : () => ({
              isComingSoon: false,
              isFirstCourse: false,
              isWatched: false,
              isCompleted: false,
              isPreviousCourseCompleted: false,
              isLocked: false,
          });

export const getLatestCourse = createSelector(getAllCourses, getState, (courses, state) => {
    return findLast(courses, (course) => {
        const { isLocked } = getCourseStatus(course)(state);

        return !isLocked;
    });
});

export const getIsLessonWatched = (lessonId: string) =>
    createSelector(
        getAreWatchedLessonsLoaded,
        getWatchedLessons,
        (areWatchedLessonsLoaded, watchedLessons) => {
            if (!areWatchedLessonsLoaded || !watchedLessons) {
                return false;
            }

            return Object.keys(watchedLessons).some((courseId) => {
                return watchedLessons[courseId] && watchedLessons[courseId].includes(lessonId);
            });
        },
    );

export const getLockedCourses = (courses: Entry<ICourseFields>[]) => {
    return createSelector(getState, (state) => {
        const statuses = courses.map((course) => getCourseStatus(course)(state));

        return courses.filter((_, index) => statuses[index]?.isLocked);
    });
};

// === Thunks ======

export const fetchKPIs = (): AppThunk => async (dispatch) => {
    try {
        const kpiResponse = await apiGet('/analytics/dashboard/kpis');

        await dispatch(setKPIs(get(kpiResponse, 'data.data.attributes.all.current')));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching kpis failed:' });
    }
};

export const loadWatchedLessons = (): AppThunk => async (dispatch) => {
    const academyResponse = await apiGet('/academy');
    await dispatch(fetchKPIs());

    return dispatch(setWatchedLessons(get(academyResponse, 'data.data.attributes.chapters')));
};

export const markVideoAsWatched =
    (videoId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const currentCourse = getCurrentCourse(state);
        const currentCourseId = currentCourse?.sys?.id;

        try {
            await apiPost(`/academy/${currentCourseId}`, {
                data: { video: videoId },
            });
            await dispatch(loadWatchedLessons());
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'marking video as watched failed:' });
        }
    };

export const checkCourseCompletion =
    (course?: Entry<ICourseFields>): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        const currentCourse = course ?? getCurrentCourse(state);
        const isCompleted = currentCourse && getCourseStatus(currentCourse)(state).isCompleted;
        const serializedSeenOverlays = localStorage.getItem('p2s-overlays') ?? '[]';
        const seenOverlays = JSON.parse(serializedSeenOverlays);
        const isCompletionOverlaySeen = seenOverlays.includes(currentCourse?.sys.id);

        if (isCompleted && !isCompletionOverlaySeen && currentCourse) {
            dispatch(
                showModal(
                    Modals.P2S_COURSE_COMPLETED,
                    { course: currentCourse },
                    MODAL_OPTIONS.tall,
                ),
            );
        }
    };

export const checkAllCoursesForCompletion = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const courses = getAllCourses(state);

    [...courses].reverse().some((course) => {
        dispatch(checkCourseCompletion(course));
    });
};

export default pathToSuccessSlice.reducer;
