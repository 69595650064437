import { StatusCodes } from '@/core/api/types';

/**
 * expected by `heroku`
 * @docs https://devcenter.heroku.com/articles/http-request-id#how-it-works
 */
export const CORRELATION_ID_HEADER = 'X-Request-ID';
export const MAX_API_RETRY_COUNT = 3;

export const NETWORK_ERROR_CODES_WITH_DEDICATED_MESSAGES = [
    StatusCodes.BAD_REQUEST,
    StatusCodes.UNAUTHORIZED,
    StatusCodes.FORBIDDEN,
    StatusCodes.NOT_FOUND,
    StatusCodes.INTERNAL_SERVER_ERROR,
] as const;

export const EXPECTED_NETWORK_ERROR_CODES = [
    StatusCodes.PAYMENT_REQUIRED,
    StatusCodes.UPGRADE_REQUIRED,
] as const;
