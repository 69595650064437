export enum LOLI_FEATURE_FLAG_NAME {
    reportNetworkErrors = 'all-report-network-errors',
    editorEngine = 'fun-editor-engine',
    metricsPage = 'con-metrics-page',
    metricsABTest = 'con-metrics-ab-test',
    paymentBlock = 'fun-payment-block',
    customHtmlEmbedBlockNativeEmbed = 'fun-custom-html-embed-block-native-embed',
    funnelEmbedAlpha = 'fun-funnel-embed-alpha',
    integrationsAdvanced = 'con-integrations-advanced',
    draftIntegrationsAdvanced = 'con-draft-integrations-advanced',
    preview = 'wks-preview',
    comments = 'wks-comments',
}

export const OVERWRITES_STORAGE_KEY_PREFIX = 'loli_feature_flag_overwrite__';

export const LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY =
    'loli_feature_flags_last_intercom_event_values';
