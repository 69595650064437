import Image from 'next/image';
import { useState } from 'react';

import { cn } from '@/utils/cn';

import type { Size, Alignment, DefaultBlockComponentProps } from '../../types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    alignment: Alignment;
    size: Size;
    src: string;
    showIcon: boolean;
}

const sizeMap = {
    small: 'h-8',
    medium: 'h-10',
    large: 'h-12',
    xLarge: 'h-16',
};

const alignMap = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
};

const Component: FC<Props> = ({ src, size, alignment, box, showIcon }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div
            className={cn('flex items-center rounded-t-lg p-2', alignMap[alignment])}
            style={{ backgroundColor: box?.backgroundColor }}
        >
            <Image
                width={0}
                height={0}
                src={src}
                alt="header logo"
                className={cn(
                    'w-auto opacity-0 transition-all duration-300 ease-out',
                    sizeMap[size],
                    {
                        invisible: !showIcon,
                        'opacity-100': isLoaded,
                    },
                )}
                sizes="100vw"
                onLoad={handleLoad}
            />
        </div>
    );
};

Component.displayName = 'Header';

export default Component;
