import { ARTBOARD_VIEW_ID } from '@/app/editor/editor/constants';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import DatePickerPreview from '@/ui/components/DatePickerPreview';
import { cn } from '@/utils/cn';

import TextInput from './TextInput';
import { DATE_PICKER_SCROLL_OFFSET } from '../../../constants';

import type { CustomInputBlockOptions } from '../../../types';

export interface Props {
    showIcon: boolean;
    placeholder: string;
    icon: string;
    blockId: string;
    customOptions: CustomInputBlockOptions;
    isActiveBlock: boolean;
}

const DatePicker = ({
    icon,
    showIcon,
    placeholder,
    blockId,
    customOptions,
    isActiveBlock,
}: Props) => {
    const dropdownRef = useRef<HTMLDivElement>();
    const dropdownBorderRadiusClass = useBorderRadius('dropdown');
    const { language, pastDatesDisabled, weekendsDisabled } = customOptions || {};

    useEffect(() => {
        // Scroll dropdown into view if needed
        if (isActiveBlock && dropdownRef.current && window) {
            setTimeout(() => {
                const bottomBound = dropdownRef.current.getBoundingClientRect().bottom;
                const innerHeight = window?.innerHeight;

                // Check whether dropdown (+ offset) is outside of viewport
                if (bottomBound - innerHeight - DATE_PICKER_SCROLL_OFFSET > 0) {
                    scroll.scrollMore(bottomBound - innerHeight - DATE_PICKER_SCROLL_OFFSET, {
                        containerId: ARTBOARD_VIEW_ID,
                        smooth: true,
                        duration: 300,
                    });
                }
            }, 300);
        }
    }, [isActiveBlock]);

    const dropdownWrapperStyle = cn(
        'ease pointer-events-none absolute left-0 right-0 top-full z-[1000] h-0 origin-top transform transition-all duration-[200ms]',
        {
            'scale-y-0 opacity-0': !isActiveBlock,
            'scale-y-100 pb-[513px] opacity-100': isActiveBlock,
        },
    );

    const dropdownStyle = cn(
        dropdownBorderRadiusClass,
        'pointer-events-auto ml-auto mt-2 flex h-fit w-[329px] max-w-full cursor-default border bg-white p-4 shadow-md',
    );

    const chevronStyle = cn(
        'size-6 shrink-0 transform-gpu cursor-pointer text-gray-500 transition-transform duration-[400ms] ease-[cubic-bezier(0.16,1,0.3,1)] ease-out',
        {
            'rotate-0': !isActiveBlock,
            'rotate-180': isActiveBlock,
        },
    );

    return (
        <div className="relative">
            <div className="relative">
                <TextInput
                    icon={icon}
                    showIcon={showIcon}
                    placeholder={placeholder}
                    blockId={blockId}
                    isActiveBlock={isActiveBlock}
                    inputClassName="pr-14"
                />
                <div className="absolute inset-y-0 right-0 flex size-14 items-center justify-center">
                    <ChevronDownIcon className={chevronStyle} />
                </div>
            </div>
            <div className={dropdownWrapperStyle} ref={dropdownRef}>
                <div className={dropdownStyle}>
                    <DatePickerPreview
                        language={language}
                        pastDatesDisabled={pastDatesDisabled}
                        weekendsDisabled={weekendsDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default DatePicker;
