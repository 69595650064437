import { ROUTES } from '@/app/campaigns/constants';

import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { apiPost, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loadingEdit: boolean;
    loadingHome: boolean;
}

const initialState: State = {
    loadingEdit: false,
    loadingHome: false,
};

export const Slice = createSlice({
    name: `${NAME}/duplicate`,
    initialState,
    reducers: {
        setLoadingEdit(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loadingEdit: action.payload,
            };
        },
        setLoadingHome(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loadingHome: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoadingEdit, setLoadingHome, reset } = Slice.actions;

// === Selectors ======

export const getLoadingEdit = (state: AppState) => state[NAME]?.duplicateReducer?.loadingEdit;
export const getLoadingHome = (state: AppState) => state[NAME]?.duplicateReducer?.loadingHome;

// === Thunks ======

// Helpers function
const setLoading =
    (loading: boolean, redirectToHome: boolean): AppThunk =>
    (dispatch) => {
        if (redirectToHome) {
            dispatch(setLoadingHome(loading));
        } else {
            dispatch(setLoadingEdit(loading));
        }
    };

export const duplicateSharedCampaign = (
    campaignId: string,
    name: string,
    redirectToHome: boolean = false,
): AppThunk => {
    return async (dispatch) => {
        dispatch(setLoading(true, redirectToHome));

        try {
            const response = await apiPost<ResponseData<CampaignResource>>(
                `/campaigns/${campaignId}/shared/duplicate`,
                {
                    data: { name },
                },
            );

            const newCampaign = response?.data?.data;

            if (redirectToHome) {
                Router.push('/');
            } else {
                Router.push(ROUTES.editor(newCampaign?.id));
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'duplicating shared funnel failed:' });
        } finally {
            dispatch(setLoading(false, redirectToHome));
        }
    };
};

export default Slice.reducer;
